/*var baseUrl = null;

if(window.location.hostname == "localhost"){

    baseUrl = window.location.protocol + '//'+ window.location.hostname+ "/azprogramiram/public";
}else{

    baseUrl = window.location.protocol + '//'+ window.location.hostname;
}*/

if(typeof mobile == 'undefined') {
	var mobile;
}

 if($(window).width() < 780)
 {
     mobile = true;
 }
 else{
     mobile = false;
 }

// mobile = mobile || WURFL.form_factor == 'Tablet';
 var step1,step2,step3;

 function helpStepOne(){
    if ($('#helperStep1').is(':visible') || step1 == true) {
             $('#helperStep1').fadeOut(function () {
                $('#helperStep2').fadeIn();
            });
    }
    if ($('#helperStep1Mobile').is(':visible') || step1 == true) {
             $('#helperStep1Mobile').fadeOut(function () {
                $('#helperStep2').fadeIn();
            });
    }
    step1 = false;
}

$(document).ready(function () {
     /** MULTIPLAYER ***/
    $('#inviteFriend').click(function(){
        $('.inviteFriendFromEx').fadeIn();
        $('.multiplayerInfoPanelOverlay').fadeIn();
        console.log("asdsadsad");
        loadFb();
    });
    $('.closeInviteFromEx').click(function(){
        $('.inviteFriendFromEx').fadeOut();
        $('.multiplayerInfoPanelOverlay').fadeOut();
    });
    /* --------- Language Bar ---------- */
    $("#languageSelected").click(function () {
        $(this).hide();
        $("#language").css({display:'inline-block'});
    });

    /* --------- Header Dropdown Menu ---------- */

    $('#user_icons').click(function () {
        if ($(window).width() > 780) {
            var dropMenuWidth = $('.drop_menu').width() + 30,
                    dropMenuHeight = $('.drop_menu').height();

            if ($('.drop_menu').is(':visible')) {
                $('.drop_menu').animate({
                    width: '0',
                    height: '0'
                }, 200, function () {
                    $('.drop_menu').removeAttr('style');
                });
            } else {
                $('.drop_menu').css({
                    width: '0',
                    height: '0',
                    display: 'inline-block'
                });
                $('.drop_menu').animate({
                    width: dropMenuWidth,
                    height: dropMenuHeight
                }, 200);
            }

        }else{
             var dropMenuWidth = $('.drop_menu').width() + 30,
                    dropMenuHeight = $('.drop_menu').height();

            if ($('.drop_menu').is(':visible')) {
                $('.drop_menu').animate({
                    width: '0',
                    height: '0'
                }, 200, function () {
                    $('.drop_menu').removeAttr('style');
                });
            } else {
                $('.drop_menu').css({
                    width: '0',
                    height: '0',
                    display: 'inline-block'
                });
                $('.drop_menu').animate({
                    width: dropMenuWidth,
                    height: dropMenuHeight
                }, 200);
            }
        }
    });
    $('.user_icons_new').click(function () {
        if ($(window).width() > 780) {
            var dropMenuWidth = $('.drop_menu').width() + 30,
                    dropMenuHeight = $('.drop_menu').height();

            if ($('.drop_menu').is(':visible')) {
                $('.drop_menu').animate({
                    width: '0',
                    height: '0'
                }, 200, function () {
                    $('.drop_menu').removeAttr('style');
                });
            } else {
                $('.drop_menu').css({
                    width: '0',
                    height: '0',
                    display: 'inline-block'
                });
                $('.drop_menu').animate({
                    width: dropMenuWidth,
                    height: dropMenuHeight
                }, 200);
            }

        }else{
             var dropMenuWidth = $('.drop_menu').width() + 30,
                    dropMenuHeight = $('.drop_menu').height();

            if ($('.drop_menu').is(':visible')) {
                $('.drop_menu').animate({
                    width: '0',
                    height: '0'
                }, 200, function () {
                    $('.drop_menu').removeAttr('style');
                });
            } else {
                $('.drop_menu').css({
                    width: '0',
                    height: '0',
                    display: 'inline-block'
                });
                $('.drop_menu').animate({
                    width: dropMenuWidth,
                    height: dropMenuHeight
                }, 200);
            }
        }
    });

    $('body').click(function (event) {
        if ($('.drop_menu').length > 0) {
            if ($('.drop_menu').has(event.target).length === 0 &&
                    $('#user_icons').has(event.target).length === 0 &&
                    !$('.drop_menu').is(event.target) &&
                    !$('#user_icons').is(event.target)) {
                $('.drop_menu').animate({
                    width: '0',
                    height: '0'
                }, 200, function () {
                    $('.drop_menu').removeAttr('style');
                });
            }
        }
        if ($('.profilePathMenuDropDown').length > 0) {
            if ($('.profilePathMenuDropDown').has(event.target).length === 0 &&
                    $('.profilePathMenuWrap').has(event.target).length === 0 &&
                    !$('.profilePathMenuDropDown').is(event.target) &&
                    !$('.profilePathMenuWrap').is(event.target)) {
                $('.profilePathMenuDropDown').animate({
                    width: '0',
                    height: '0'
                }, function () {
                    $('.profilePathMenuDropDown').removeAttr('style');
                });
            }
        }

        if ($('#floatMenu').length > 0) {
            if ($('#floatMenu').has(event.target).length === 0 &&
                    $('.showFloatMenu').has(event.target).length === 0 &&
                    !$('#floatMenu').is(event.target) &&
                    !$('.showFloatMenu').is(event.target)) {
                $('#floatMenu').fadeOut();
            }
        }

        if ($('.languageBar').length > 0) {
            if ($('.languageBar').has(event.target).length === 0 &&
                    !$('.languageBar').is(event.target)) {
                $("#language").hide();
                $("#languageSelected").fadeIn();
            }
        }
          if ($('.floatMenuNew').length > 0) {
            if ($('.floatMenuNew').has(event.target).length === 0 &&
                    $('.showFloatMenuNew').has(event.target).length === 0 &&
                    !$('.floatMenuNew').is(event.target) &&
                    !$('.showFloatMenuNew').is(event.target)) {
                  $('.floatMenuNew').hide();



            }
        }
    });

    $(window).bind('scroll', function () {
        if ($('.drop_menu').is(':visible')) {
            $('.drop_menu').animate({
                width: '0',
                height: '0'
            }, 200, function () {
                $('.drop_menu').removeAttr('style');
            });
        }
        if ($('.profilePathMenuDropDown').is(':visible')) {
            $('.profilePathMenuDropDown').animate({
                width: '0',
                height: '0'
            }, function () {
                $('.profilePathMenuDropDown').removeAttr('style');
            });
        }
        if ($('#floatMenu').is(':visible')) {
            $('#floatMenu').fadeOut();
        }
    });

    /* ----- Goals ----- */

    var showGoals = function () {
        var elWidth = $('#goalsPathList').width(),
                el = $('#goalsPathList'),
                margin = $('#goalsPathList').parent().width() * 19 / 100;

        $('#setModulesUp').hide();
        //dailyTaskFunc.getTaskModules(false);
        if ($(window).width() > 780) {

            $('#goalsMain').addClass('vertSeparator');
            $('#goalsMain').animate({
                width: '80%',
                marginLeft: margin
            });

            el.css({
                position: 'absolute',
                width: '0'
            }).animate({
                width: elWidth
            }, function () {
                /* show wizard if is not set goal*/
                /*if(!isGoal){*/
                    el.fadeIn(function () {
                        $('#helperStep1').fadeIn();
                    });
                /*}*/
            });
            $('.graphText').animate({
                left: '45%'
            });
        }
        $('.graphCont').animate({
            width: '100%'
        });
        if ($(window).width() > 300) {/*old 400*/
            $('#showGoalSteps').fadeIn();
        }
        $('#showGoals').hide();
        $('#hideGoals').css({
            display: 'inline-block'
        });
        $('.okGoalsWrap').fadeIn();
        $('#showPathsInGoals').fadeIn();
        $('.expandContainer').slideDown();
        $('#showGoals').closest('.content').animate({
            paddingBottom: '100px'
        });
    };

    var hideGoals = function () {
        $('#helperStep1').hide();
        $('#helperStep2').hide();
        $('#helperStep3').hide();
        $('#helperStep4').hide();
        var el = $('#goalsPathList');

        $('#goalsMain').animate({
            width: '100%',
            marginLeft: '0'
        }, function () {
            $('#goalsMain').removeClass('vertSeparator');
            $('#setModulesUp').fadeIn();
        });

        el.hide().removeAttr('style');

        $('.graphText').animate({
            left: '40%'
        });
        $('.graphCont').animate({
           /* width: '50%'*/
           width: '100%'
        });

        $('#showGoalSteps').fadeOut();
        $('#hideGoals').hide();
        $('#showGoals').show();
        $('.okGoalsWrap').fadeOut();
        $('#showPathsInGoals').fadeOut();
        $('.expandContainer').slideUp();
        $(this).closest('.content').animate({
            paddingBottom: '40px'
        }, function () {
            $('#hideGoals').closest('.content').removeAttr('style');
        });

    };

    $("#showGoals").click(showGoals);
    $("#hideGoals").click(hideGoals);


    /* ----- open Goals in profile from AVATAR drop menu----- */

    var varOpenGoal = window.location.href.search('openGoal');
    if (varOpenGoal > 0) {
        showGoals();
    }

/* ----- Helper Actions in Goals ----- */
    $('.gotIt').click(function(){
        var el = $(this).closest('.floatMenuWrapper').attr('id');
        switch (el) {
            case 'helperStep1':
                step1 = true;
                break;
            case 'helperStep2':
                step2 = true;
                break;
            case 'helperStep3':
                step3 = true;
                break;
        }
        $(this).closest('.floatMenuWrapper').fadeOut();
    });

    $('.helperTrigger1').click(function () {
        helpStepOne();
    });
    /* $('.helperTrigger1').on("change",function() {
        helpStepOne();
    });*/
    $('.helperTrigger2').click(function () {
        if ($('#helperStep2').is(':visible') || step2 == true) {
            $('#helperStep2').fadeOut(function () {
                $('#helperStep3').fadeIn();
            });
        }
        step2 = false;
    });
    $('.helperTrigger3').click(function () {
        if ($('#helperStep3').is(':visible') || step3 == true) {
            $('#helperStep3').fadeOut(function () {
                $('#helperStep4').fadeIn();
            });
        }
        step3 = false;
    });

    $('#showGoalSteps').click(function () {
        if(mobile){
            var top = $('#coursesList').position().top + $('#helperStep1Mobile').height();
            var left = $('#coursesList').position().left;
            console.log(top)
            $('#helperStep1Mobile').css({top:top,left:left}).fadeIn();
        }
        if (!$('#helperStep1').is(':visible')) {
            hideHelpers();
            $('#helperStep1').fadeIn();
        } else {
            $('#helperStep1').hide();
        }


    });

    /* ----- Adding and Clearing Tasks ----- */

    $("#okGoalsButton").click(function () {
        auxiliaryWorkFunctions.createDeilyTask();
        if (typeof exerciseId != 'undefined' && $('.dailyModule').is(":checked")) {
            isGoal = true;
            changeFunctions.changeModules(courseId, 'module', 0, 0, true);
            $("#modules").hide();
        }
    });

    $("#appendModules").click(function () {
        auxiliaryWorkFunctions.appendRolesModules();

        $("#setGoalsOverlay").addClass("inactive");

        dailyTaskFunc.getDeilyPercents(true);
        isGoal = false;
    });

    $("#clearTsks").click(function (event) {
        hideHelpers();
        $("#startTsks").unbind("click");
        dailyTaskFunc.clearDeilyTask(userId);

        /*if (typeof exerciseId != 'undefined') {

            changeFunctions.changeModules(courseId, 'module', 0, 0, true);
        }*/
        $("#cvsc").replaceWith("<canvas id='cvsc' width='200px' height='200px' style='width: 40%;' class='canvasView'>[No canvas support]</canvas>");
        pieRender('cvsc', 0);
        $("#cvsb").replaceWith("<canvas id='cvsb' width='200px' height='200px' style='width: 40%;' class='canvasView'>[No canvas support]</canvas>");
        pieRender('cvsb', 0);
        $("#selectedProfession").text("");
    });


    /* ----- Path listing in goal section ----- */

    $('.professionName').click(function () {
        var el = $(this).closest('.profession_title').find('.coursePathListInGoals');
        if (el.is(':visible')) {
            el.slideUp();
            $(this).find('.fa').removeClass('fa-minus').addClass('fa-plus');
        } else {
            el.slideDown();
            $(this).find('.fa').removeClass('fa-plus').addClass('fa-minus');
        }
    });

    /* -- pie chart load in profile -- */



    /* ----- Float Helper actions ------ */

    $('.showFloatMenu').click(function () {
        var elHeight = $(this).height(),
                topPos = $(this).offset().top + elHeight + 32,
                leftPos = $(this).offset().left,
                rightMargin,
                pointerLeft;



        if ($(window).width() < 1024) {
            rightMargin = 200;
            pointerLeft = "75%";
            if (mobile) {
                rightMargin = 160;
                pointerLeft = "75%";
            }

        } else {
            pointerLeft = "75%";
            rightMargin = 300;
        }


        $('#floatMenu').hide();

        if ($(document).width() - leftPos < rightMargin) {

            $('.squarePointer').css({
                left: pointerLeft
            });

            console.log(leftPos);
            if ($(window).width() < 1024) {
                leftPos -= 100;
                if (mobile) {
                    leftPos -= 60;
                }
            } else {
                leftPos -= 200;
            }

            $('#floatMenu').css({
                position: "absolute",
                top: topPos,
                left: leftPos
            }).fadeIn();

        } else {
            $('.squarePointer').css({
               /* left: '20px'*/
            });
            $('#floatMenu').css({
                position: "absolute",
                top: topPos,
                left: leftPos-20
            }).fadeIn();
        }
    });

    var closeEvent;
    function bindShow(self){
        $(".floatMenuNew").hide();
        $(self).find('.floatMenuNew').css({position:'absolute',zIndex:5}).fadeIn('slow');
    }
    $('.showFloatMenuNew').click(function () {
        bindShow(this);
    });
    $('.closePromptNew').click(function(event){
            closePrompt(event);
        });

        function closePrompt(event){
            closeEvent = event;
             event.stopPropagation();

             $(event.target).closest('.floatMenuNew').toggle();
             /*if(clickStart == 1){
                 $(this).parent().fadeOut();\\\\\
             }*/

            // event.trigger("click");
        }
     $('.closePrompt').click(function(){
        $(this).parent().fadeOut();
    });
    $('.closePromptSubscription').click(function(){
       $('#subscribeWrapper').hide();
        $('#subscribeIcon').fadeIn();
        $('#subscribeIcon-o').fadeOut();
        /*
         $('#footerBreaker').animate({
            height: '36px',//old 60px
            width: '36px',//old 60px
            borderRadius: '80px'
        }, function() {
            $('#subscribeIcon').fadeIn();
            $('#butaforenFooterDiv').show();
        });*/
        if ($(window).width() <= 1024) {
            $('#footerBreaker').animate({
                height: '36px',//old 60px
                width: '36px',//old 60px
                borderRadius: '80px'
            }, function() {
                $('#subscribeIcon').fadeIn();
                $('#butaforenFooterDiv').show();
            });
        }else if($(window).width() <= 1280){
             $('#footerBreaker').animate({
                height: '50px',//old 60px
                width: '50px',//old 60px
                borderRadius: '80px'
            }, function() {
                $('#subscribeIcon').fadeIn();
                $('#butaforenFooterDiv').show();
            });
        }else{
             $('#footerBreaker').animate({
                height: '60px',//old 60px
                width: '60px',//old 60px
                borderRadius: '80px'
            }, function() {
                $('#subscribeIcon').fadeIn();
                $('#butaforenFooterDiv').show();
            });
        }
    });
    /* ----- Send Error Report Logic ----- */

    $('#bugReport').click(function () {

        if (!mobile) {
            if (!$('#bugReportWrap').is(':visible')) {
                $('#teacherAvatarWords').children().hide();
                if (!isJunior) {
                    $('#thisDailyTask').hide();
                    if(!teacherAvatarOpen) {
                        workFuncForExPractise.extendTeacherAvatar(true);
                    }
                } else {
                    workFuncForExPractise.extendJuniorAvatar(true);
                }
                $('#bugReportWrap').fadeIn();

            } else {
                $('#bugReportWrap').hide();
                $('#thisDailyTask').insertAfter('#panelRightHeader');
                console.log("5");
                if (!isJunior) {
                    workFuncForExPractise.extendTeacherAvatar(false);
                } else {

                    workFuncForExPractise.extendJuniorAvatar(false);
                }
            }
        } else {
            if (!$('#bugReportWrap').is(':visible')) {
                $('#teacherAvatarWords').children().hide();
                $('#thisDailyTask').hide();
                $('#bugReportWrap').fadeIn();
               $('#speed').hide();
               if (!isJunior) {


                } else {
                    workFuncForExPractise.extendJuniorAvatar(true);
                }
            } else {
                $('#bugReportWrap').hide();
                $('#thisDailyTask').fadeIn();
               $('#hideSpeed').show();
               if (!isJunior) {

                } else {
                    workFuncForExPractise.extendJuniorAvatar(false);
                }
            }

            /* Hide speed if  visible */
           /*if($("#speed").is(':visible')){
                $('#speed').hide();
            }else{
                 $('#speed').show();
            }*/
        }
    });
    $('#hideSpeed').click(function(){
        if(mobile){


            if($('#bugReportWrap').is(':visible') || $("#progress_wrap").is(':visible') || $("#helpBox_first").is(':visible') ||$("#helpBox_second").is(':visible') || $("#helpBox_third").is(':visible')){
                $('#speed').hide();
            }else{
               if($('#speed').is(':visible')){
                   $('#speed').hide();
               }else{
                   $('#speed').show();
               }
            }
        }
    });
    $('#cancelBug').click(function () {
        if (!mobile) {
            $('#bugReportWrap').hide();
            $('.titleBugReport span').removeClass('fa-angle-up').addClass('fa-angle-down');
            if (!isJunior) {
                workFuncForExPractise.extendTeacherAvatar(false);
            } else {
                workFuncForExPractise.extendJuniorAvatar(false);
            }
        } else {
            $('#bugReportWrap').hide();
            $('#thisDailyTask').fadeIn();
        }
    });


    $('#sendBug').click(function () {
        if(typeof exerciseId !== "undefined"){
             var report_information =
                {
                    url: window.location.href,
                    date: new Date(),
                    message: $('#bug_description').val(),
                    exercise_id: exerciseId,
                    subject: $('#bug_subject').val(),
                };
        }else{
             var report_information =
                {
                    url: "",
                    date: "",
                    message: $('#bug_description').val(),
                    exercise_id: "",
                    subject: $('#bug_subject').val(),
                };
        }


        if (report_information.subject.length > 1 && report_information.message.length > 1) {
            /* CODE:
             * $('#container-report').toggle('slow');
             */
            $.post(baseUrl + "/support/send-bug-report", report_information)
                    .done(function (data) {

                        if (data.status != 200) {
                            $('.ticket_msg').remove();
                            $('#bug_description')
                                    .after("<p class='ticket_msg textColorSelector'>" + trans('langPageEx.thankYouMsg') + " " + userEmail + "</p>");

                            retrunUsertickets();
                        } else {
                            $('.ticket_msg').remove();
                            $('#bug_description').after("<p class='ticket_msg textColorSelector'>" + trans('langPageEx.sendProblem') + "</p>");
                        }
                    });
        } else {
            $('.ticket_msg').remove();
            $('#bug_description').after("<p class='textColorSelector ticket_msg'>" + trans('langPageEx.mandatoryFields') + "</p>");
        }
    });

    /* ----- Color Generator ----- */
    function getRandomColor() {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    /* ----- Mobile screen actions ----- */
    if(locale == 'zh'){
        if(is_user != ""){
            mobMenu();
        }
    }else{
        mobMenu();
    }
    $('.buttonPlaceHolderGame').click(function(){
        console.log('abc');
    });
    var chat_init = false;
    function mobMenu(){
        $('.menuTrigger').click(function () {
            if (!$('.mobMenuWrapper').is(':visible')) {
                $('.mobMenuWrapper').slideDown();
                if(!chat_init){
                    /*chat_init = true;
                    initChat();
                    $zopim(function() {
                        $zopim.livechat.button.hide();
                    });*/

                }
            } else {
                $('.mobMenuWrapper').slideUp();
            }
        });
    }



});
$('.closeDisabledTest').click(function(){
    $('.disabledTestModal').addClass('inactive');
});
/* ----- End of ready function ----- */

/* ==== Start of stand-alone functions ==== */

/* ----- Start Goal Button ----- */
function startGoal(taskCourseId, startExercise) {

    if (taskCourseId != 0) {
        selectionsFromDB.getCourceProfession(taskCourseId);
        /*
         * set goal button
         */
        if (startExercise != "undefined") {
            $('#startTsks').addClass('accColBG').removeClass('inactiveButton');
        }
        $('#startTsks').click(function () {
            if (startExercise != "undefined") {

                window.location.href = baseUrl + '/exercise/view/' + startExercise;
            }
        });
    }
}
$('#startTsks').click(function () {
            if (startExercise != "undefined") {

                window.location.href = baseUrl + '/exercise/view/' + startExercise;
            }
});
/* ----- Warning MSG to continue ----- */

function showMessage(self) {
    $('.msg-con').remove();
    if(isJunior){
      $('#leftSlider').css({width:$('#leftSlider').width()});
    }

    setTimeout(function(){
      $(self).append('<span class="msg-con">' + trans("langPage.messageCon") + '</span>');
    },500);

    console.log("ASdsad");
}
var msgOpen = false;
$('.close-error-junior').click(function(){
    msgOpen = false;
   $('.errorJuniorStructure').remove();
});
function showMessageJunior(self) {
        msgOpen = true;
        if(!$('.errorJuniorStructure').is(":visible") && msgOpen){
            console.log('asd');
            $('.errorJuniorStructure').remove();
            $(self).append('<div class="errorJuniorStructure"><span class="msg-jun">' + trans("langPage.messageCon") + '</span><span class="close-error-junior"><span class="fa fa-times"></span></span></div>');
        }else{
            $('.errorJuniorStructure').remove();
        }
}
function showMessageJuniorLogin(self){
    msgOpen = true;
    if(!$('.errorJuniorStructure').is(":visible") && msgOpen){
        console.log('asd');
        $('.errorJuniorStructure').remove();
        $(self).append('<div class="errorJuniorStructure"><span class="msg-jun">' + trans("langPage.continueLogin") + '</span><span class="close-error-junior"><span class="fa fa-times"></span></span></div>');
    }else{
        $('.errorJuniorStructure').remove();
    }
}
/* ----- Select Course from left panel in Profile Goals ----- */

function changeSelectEl(el) {
    $('#coursesList option[value=' + el + ']').prop('selected', true);
    changeFunctions.changeModules(el, 'module', 0, 0, true);
}

/* -----  okGoalsButton turns activated on Module checkbox in goals ----- */

function activeGoal(self) {
    if ($('.dailyModule').is(":checked")) {

        if ($('#helperStep2').is(':visible') || step2 == true) {
            $('#helperStep2').fadeOut(function () {
                $('#helperStep3').fadeIn();
            });
        }
        step2 = false;

        $('#okGoalsButton').addClass('accColBGHoverSame').removeClass('inactiveButton');
    } else {
        if ($('#helperStep3').is(':visible') || step3 == true) {
            $('#helperStep3').fadeOut(function () {
                $('#helperStep2').fadeIn();
            });
        }
        step3 = false;

        $('#okGoalsButton').removeClass('accColBGHoverSame').addClass('inactiveButton');
    }
}
function hideHelpers() {
    step1 = false;
    step2 = false;
    step3 = false;
    $('#helperStep1').hide();
    $('#helperStep2').hide();
    $('#helperStep3').hide();
    $('#helperStep4').hide();
}
function openTicketsList(self) {

    var id = $(self).attr("id") + "_lessons";
    var el = $("#" + id);
    var all = $('.slideArticles');
    var condition = el.css("display") === "block";

    $('.faqContainer').find('.fa').removeClass('fa-play fa-rotate-90').addClass('fa-question');

    $('.faqContainer').css({
        position: 'relative',
        zIndex: '0',
        boxShadow: '0px 0px 0px rgba(102, 102, 102, 1)'
    }).animate({
        /*
         * marginTop: '0',
         * marginBottom: '0',
         */
    });
    $('.faqIcon').removeAttr('style');

    all.slideUp();

                var prCol = $('.prCol').css('color');

                if (!condition) {
                    $(self).closest('.faqContainer').find('.fa').removeClass('fa-question').addClass('fa-play fa-rotate-90');
                    $(self).closest('.faqContainer').find('.faqIcon').css('line-height', '2.3rem');
                    $(self).closest('.faqContainer').find('.faqIcon').animate({
                        /*backgroundColor: prCol*/
                    });
                    $(self).css({
                        position: 'relative',
                        zIndex: '1',
                        boxShadow: '0px 0px 16px rgba(102, 102, 102, 1)'
                    });
                    el.slideDown();
                }

    }
function showFloatMessage(text, self) {
    var message = "";
    if (text == 'complete') {
        message = trans('langPageEx.goalCompletedTick');
    } else {
        message = trans('langPageEx.goalNotCompletedTick');
    }

    $(self).find('li').append("<div class='floatMessageDiv'>" + message + "</div>");
}
    function loadPie(){
            if (typeof currentGoal !== 'undefined') {
            var targetb = currentGoal;
            var targetc = dayGoal;
            var increment = 2;


        if((currentGoal !== null || (typeof currentGoal != "undefined" && currentGoal != null) ||
                exerciseId == "undefined") && (exerciseLevel != "A" ||
                typeof exerciseId == "undefined" || typeof exerciseLevel == "undefined")){



            pieRender('cvsc', targetc);
            if(typeof exerciseId != "undefined"){
                pieRender('cvse',  goalProfession);
                pieRender('cvsf', targetc);
            }

         }

             if(typeof exerciseId == "undefined"){
                 pieRender('cvsb', currentGoal);
            }




         }
    }

//Wrap Course Title in Course Structure Page*/
/*TOVA KRIE6E ZAGLAVIETO SLED KATP PREMESTIHME SCRIPTOVETE*/
/*$('.courseStructureHeroTitle').hide();*/

/*set highlite code for canvas course*/
$('#start-popup-close').click(function(){
    $('.start-popup-modal').hide();
});
if(typeof isCanvas != "undefined" && isCanvas){
  // SyntaxHighlighter.all();
 //run after
   /* if(mobile){
         setTimeout(function(){
            initRuntime();
         },2000);
    }*/



}
    function retrunUsertickets() {
        $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
        });
        $.post(baseUrl + "/support/tikets/get").done(function (data) {
            $('#profileProblemList').find(".slideTrigger").remove();
            $('#ticketDefaultMessage').addClass("inactive");
            //$('.containerTicket').remove();
            for (var ticket in data.tikets) {
                var t = data.tikets[ticket];
                var status;
                if(t.status == 1){
                    status = trans("langPage.statusOpen");
                }else{
                    status = trans("langPage.statusClose");
                }
                $('#reportsWrapper').append(
                        "<div id=" + t.id + " class='faqContainer prColBGLightHover slideTrigger'>" +
                        "<div class='noMargin prCol'>" +
                        "<span class='faqIcon'><i class='fa fa-question'></i></span>"
                        + t.subject + "#" + t.id +
                        "<span class='right percent'>" +
                        trans("langPage.ticketStatus") + " " +
                        status +
                        "</span>" +
                        "</div>" +
                        "<div id=" + t.id + "_lessons" + " class='slideArticles inactive'>" +
                        t.message
                        + "</div>" +
                        "</div>");
            }
            if(data.tikets.length == 0){
                $('#ticketDefaultMessage').removeClass("inactive");
            }
            $('.slideTrigger').click(function () {
                openTicketsList(this);
            });
        });
    }

    function desktopChangeJuniorModeToCode(change){
        if(change){
            $('#helpPanelBlock').addClass('inactive');
            $('#helpPanelCode').removeClass('inactive');
        }else{
            $('#helpPanelCode').addClass('inacitve');
            $('#helpPanelBlock').removeClass('inactive');
        }
    }

function copyToClipboard(){
    var copyText = document.querySelector('.textCopy');

    copyText.select();
    try{
        var successfull = document.execCommand('copy');
        var msg = successfull ? 'successful' : 'unsuccessful';
        console.log(msg);
    }catch(err){
        console.log('Oops, unable to copy');
    }
}
function clickSwitchExercises() {
    $('.switch_top').toggleClass('switch_position');
    if($("#toEx").hasClass("ex_on")) {
        $('#switchExModal').show();
        $('#switchExModalOverlay').show();
        $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
        });
        $.get(baseUrl + "/multiplayer/switch-ex").done(function (data) {
            data = JSON.parse(data);
            if(data.multiplayer_available == false){

                $('#switchExModal').find('h1').removeClass('inactive');

                $('.switch_top').toggleClass('switch_position');
                $('#exCount').html(data.ex_to_multiplayer);
                $('#frCount').html(1-data.fr_count);
                if(1-data.fr_count == 1){
                    $('.switchFr').removeClass('inactive');
                }
                if(data.ex_to_multiplayer < 3 && data.ex_to_multiplayer != 0){
                    $('.switchEx').removeClass('inactive');
                }

            }else{
                $("#toEx").removeClass("ex_on");
                $('#toMultiplayer').addClass("ex_on");
                $('#switchExModal').find('.frList').removeClass('inactive');
                $('#switchExModal').find('.friend-list').show();

            }
        });
        //window.location.href= baseUrl + "/async/view/"+multiplayer_public_id;
    }else{
        $("#toMultiplayer").removeClass("ex_on");
        $('#toEx').addClass("ex_on");
        window.location.href= baseUrl + "/exercise/view/"+lastActiveEx;
    }
}
function closeSwitchModal(){
    $('#switchExModal').hide();
    $('#switchExModalOverlay').hide();
}