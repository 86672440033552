function trans(key){
    var txt = "";
    try{
         txt = eval(locale+key);
    }catch(err){
        txt = "";
    }	
   return txt;
}

function loadLanguage(){
    var script = "<script type='text/javascript' src='"+baseUrl+'/mix-lang/'+lang+'/lang_mix.js'+"'></script>";
    setTimeout(function(){
       $('body').append(script); 
        setSpeed();
    },1000)
    
}