/*var allContent = $('.materialUl').not(':first').each(function(){
    if($(this).is(":visible")){
        console.log("a");
    }else{
        console.log("b");
    }
});*/
var paymentClosed = false;
var loadAfterChat = false;
var usd = null;
$(document).ready(function () {

	if($('.dashboard.position-fixed').length !== 0) {
		$('.dashboard.position-fixed').attr('style', 'padding-top: ' + ($('#firstTopStripe').outerHeight(true) + 10) + 'px !important;');
		$('.dashboard-content.learn-coding').attr('style', 'padding-top: ' + ($('#firstTopStripe').outerHeight(true) - 50) + 'px !important;');
		$('#uploadAvatar').attr('style', 'margin-bottom: 10px;');
	}

	if(localStorage.getItem("profile-page") != null){
		if(localStorage.getItem("profile-page") == 1){
			localStorage.removeItem("profile-page");
			showHomeProfile();
			$('.nav-menu li').removeClass('activeLi');
			$('#1section').addClass('activeLi');
		}else if(localStorage.getItem("profile-page" )== 2){
			localStorage.removeItem("profile-page");
			showBadgesProfile();
			$('.nav-menu li').removeClass('activeLi');
			$('#2section').addClass('activeLi');
		}else if(localStorage.getItem("profile-page" )== 3){
			localStorage.removeItem("profile-page");
			showProgressProfile();
			$('.nav-menu li').removeClass('activeLi');
			$('#3section').addClass('activeLi');

		}else if(localStorage.getItem("profile-page") == 4){
			localStorage.removeItem("profile-page");
			showFullProfile();
			$('.nav-menu li').removeClass('activeLi');
			$('#4section').addClass('activeLi');
		}else if(localStorage.getItem("profile-page") == 5){
			localStorage.removeItem("profile-page");
			showHomePromotions();
			$('.nav-menu li').removeClass('activeLi');
			$('#5section').addClass('activeLi');
		}
	}
	if(current_page == "path" || current_page == "course"){
		/*navigationDrawer.open();*/
		$('.navigation-courses').css({top:$('.breadCrumb').offset().top+10});
	}

	extendCourses();
	showEnterCodeModal();
	$('.closeEnterRegCode').click(function(){
		$('.inviteOnlyModal').hide();
		$('.inviteOnlyOverlay').hide();
	});
	$('#yes-additional-plan1').click(function(){
		$('.newPrices').removeClass('inactive');
		$('.newPricesOverlay').removeClass('inactive');
		$('#additional_plan').val('bg-feature-plan-1');
		sessionStorage.setItem("additional_plan","bg-feature-plan-1");
	});
	$('#yes-additional-plan2').click(function(){
		$('.newPrices').removeClass('inactive');
		$('.newPricesOverlay').removeClass('inactive');
		$('#additional_plan').val('bg-feature-plan-2');
		$('.add_pack1').addClass('inactive');
		$('.add_pack2').removeClass('inactive');
		sessionStorage.setItem("additional_plan","bg-feature-plan-2");
	});
	$('#no-additional-plan1').click(function(){
		$('.feature1').addClass('inactive');
		$('.feature2').removeClass('inactive');
		$('.special-offer-overlay').css({background:'#333333'});
		closeModalTrial
	});
	$('#no-additional-plan2').click(function(){
		lastStepPay();
		sessionStorage.removeItem("additional_plan");
	});
	$('#activateUsers').click(function(){
		$('.activateUsers').removeClass('inactive');
		$('.activateUsersOverlay').removeClass('inactive');
	});
	$('#activateBankUsers').click(function(){
		$('.activateUsersBank').removeClass('inactive');
		$('.activateUsersOverlay').removeClass('inactive');
	});
	$('#activateEasypayUsers').click(function(){
		$('.activateUsersEasyPay').removeClass('inactive');
		$('.activateUsersOverlay').removeClass('inactive');
	});
	$('.close-active-accounts').click(function(){
		$('.activateUsers').addClass('inactive');
		$('.activateUsersBank').addClass('inactive');
		$('.activateUsersEasyPay').addClass('inactive');
		$('.activateUsersOverlay').addClass('inactive');
	});
	$('.closeConfirmAccount').click(function(){
		$('.confirmAccount').hide();
		$('#confirmEmailOverlay').hide();
	});
	/*$('#confirmEmailOverlay').click(function(){
       $(this).hide();
       $('.confirmAccount').hide();
    });*/
	$('#log_out').click(function(){
		localStorage.removeItem("show_activate_modal_time");
		localStorage.removeItem("proba");
		localStorage.removeItem("profile-page");
		localStorage.removeItem("payment_closed");
		localStorage.removeItem("payment_method");
		localStorage.removeItem("payment_step");
		localStorage.removeItem("step_payment");
		localStorage.removeItem("reminders_denied");
		localStorage.removeItem("next_time");
	});
	$('.playNowFixed').mouseenter(function(){
		/*$('.playNowFixed').css({width:'auto'});*/
		/*$('.playNowFixed .fa').css({left:'0'});*/
		$('.igrai-sega').fadeIn();
	});
	$('.playNowFixed').mouseleave(function(){
		/*$('.playNowFixed').removeAttr('style');*/
		/*$('.playNowFixed .fa').removeAttr('style');*/
		$('.igrai-sega').fadeOut();
	});
	$('#see_plans').click(function(){
		$('html, body').animate({
			scrollTop: $(".payments_plans").offset().top-100
		}, 2000);
		/*$('.parentsPaymentModal').show();
          $('.parentsPaymentOverlay').show();*/
	});
	$('.closeParentsModal').click(function(){
		$('.parentsPaymentModal').hide();
		$('.parentsPaymentOverlay').hide();

	});

	$('.col-btn').click(function(){

	})
	$('#allCourses').click(function(){
		/*$('.col-btn').removeClass('activeFilter');
      $(this).addClass('activeFilter');
        $('.filterView').hide();
        $('#allCoursesFilter').show();*/
		window.location.href=baseUrl + "/courses?courses=all";
	});
	$('#visualCourses').click(function(){
		/*  $('.col-btn').removeClass('activeFilter');
      $(this).addClass('activeFilter');
      $('.filterView').hide();
      $('#visualCoursesFilter').show();*/
		window.location.href=baseUrl + "/courses?courses=visualProgramming";
	});
	$('#theConsoleCourses').click(function(){
		/*  $('.col-btn').removeClass('activeFilter');
      $(this).addClass('activeFilter');
      $('.filterView').hide();
      $('#consoleCoursesFilter').show();*/
		window.location.href=baseUrl + "/courses?courses=console";
	});
	$('#websiteCourses').click(function(){
		window.location.href=baseUrl + "/courses?courses=websites";
		/*$('.col-btn').removeClass('activeFilter');
      $(this).addClass('activeFilter');
      $('.filterView').hide();
      $('#websiteCoursesFilter').show();*/

	});
	$('#frontendCourses').click(function(){
		window.location.href=baseUrl + "/courses?courses=websitesFrontend";
		/*$('.col-btn').removeClass('activeFilter');
      $(this).addClass('activeFilter');
      $('.filterView').hide();
      $('#frontendCoursesFilter').show();*/

	});

	$('#seeMoreBegginner').click(function(){
		if($('#moreBegginer').is(":visible")){
			$('#moreBegginer').addClass('inactive');
			$(this).text("Виж повече");
		}else{
			$('#moreBegginer').removeClass('inactive');
			$(this).text("Виж по-малко");
		}

	});

	$('#seeMoreMiddle').click(function(){
		if($('#moreMiddle').is(":visible")){
			$('#moreMiddle').addClass('inactive');
			$(this).text("Виж повече");
		}else{
			$('#moreMiddle').removeClass('inactive');
			$(this).text("Виж по-малко");
		}

	});

	$('#seeMoreAdvanced').click(function(){
		if($('#moreAdvanced').is(":visible")){
			$('#moreAdvanced').addClass('inactive');
			$(this).text("Виж повече");
		}else{
			$('#moreAdvanced').removeClass('inactive');
			$(this).text("Виж по-малко");
		}

	});

	$('.promotionRight').height($('.promotionAmount').outerHeight());
	$('.seeInfo1').click(function(){
		$('.middleStepFeature').removeClass('inactive');
		$('.middleStepFeature .feature1').removeClass('inactive');
		$('.featureModal').show();
	});

	$('.seeInfo2').click(function(){
		$('.middleStepFeature').removeClass('inactive');
		$('.middleStepFeature .feature2').removeClass('inactive');
		$('.featureModal').show();

	});
	$('.closeFeatures').click(function(){
		$('.middleStepFeature').addClass('inactive');
		$('.middleStepFeature .feature2').addClass('inactive');
		$('.middleStepFeature .feature1').addClass('inactive');
		$('.featureModal').hide();
	})
	$('#takePlan1').click(function(){
		sessionStorage.setItem('goto_pay','feature_plan1')
		window.location.href = baseUrl + "/bill";
	});
	$('#takePlan2').click(function(){
		sessionStorage.setItem('goto_pay','feature_plan2')
		window.location.href = baseUrl + "/bill";
	});
	$('.closeTeacherCourses').click(function(){
		$('.teacherTestAvailableCourses').hide();

	});

	$('.hideTeacherCourses').click(function(){
		$('.teacherCourses').slideUp("slow",function(){
			$('.hideTeacherCourses').find('span').removeClass('fa-angle-up').addClass('fa-angle-down');
			$('.teacherTestAvailableCourses h1').attr('style','margin:0px;padding:0;');
			/*$('.hideTeacherCourses').attr('style','position:absolute;top:-25px; left:50%;-ms-transform:translateX(-50%);-webkit-transform:translateX(-50%);transform:translateX(-50%);');
            $('.hideTeacherCourses span').attr('style','top:60%');*/
			$('.showTeacherCourses').show();
		});

	});
	$('.showTeacherCourses').click(function(){
		$('.teacherCourses').slideDown("slow",function(){
			$('.teacherTestAvailableCourses').removeClass('inactive');

		});
		$('.showTeacherCourses').hide();
		$('.hideTeacherCourses').find('span').removeClass('fa-angle-down').addClass('fa-angle-up');
		$('.teacherTestAvailableCourses h1').removeAttr('style');
		$('.hideTeacherCourses').removeAttr('style');
		$('.hideTeacherCourses span').removeAttr('style');
	});

	$('#dropdown_menu').mouseover(function(){
		$('.navigation-dropdown').slideDown(200);
	});
	$('#dropdown_menu').mouseleave(function(){
		$('.navigation-dropdown').slideUp(200);
	});
	$('#activateScratch').click(function(){
		if($(this).is(":checked")){
			$('#activateScratchRow').removeClass('inactive');
		}else{
			$('#activateScratchRow').addClass('inactive');
		}
	});
	$('.closeScratchAccount').click(function(){
		$('.activeScratchAccount').fadeOut();
		$('.activeScratchAccountOverlay').fadeOut();
	});

	$('#messages').click(function(){
		/*if(current_page != "profile"){

            window.location = baseUrl + "/profile?messages=true";
        }else{
            showFullProfile();
            $("html, body").animate({
                scrollTop: $('#receivedMessages').offset().top-200
            }, 600,function(){

            });
        }*/
		$('#messagesModal').fadeIn();
		$('#messagesOverlay').fadeIn();

		$.ajaxSetup({
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		});
		$.get(baseUrl+"/message/show-user-messages",
			{

			} )
			.done(function( data ) {
				$('.userMessages').html(data);
			});
	});
	if(mobile){
		$('.payment_plan').removeAttr('onclick');
	}
	$('#activateCard').click(function(){
		$('.activeScratchAccount').fadeIn();
		$('.activeScratchAccountOverlay').fadeIn();
	});

	$('#activateCode').click(function(){
		$('.activeScratchCodeAccount').fadeIn();
		$('.activeScratchAccountOverlay').fadeIn();
	});

	$('.closeMsgs').click(function(){
		$('#messagesModal').fadeOut();
		$('#messagesOverlay').fadeOut();
	});

	/*$('.payment_plan').first().before($('.payment_plan').last());  */
	$('#nextPlan').click(function(){
		var item_width = $('.payment_plan.activePlan').outerWidth() + 10;
		var leftIntent = parseInt($('.itemsWrapper').css('left')) - item_width;

		var planToHide = $('.payment_plan.activePlan').first();
		var planToShow = $('.payment_plan.activePlan').last().next();
		if($('.payment_plan.activePlan').last().next().length == 0){
			$('#nextPlan').hide();
			return;
		}

		planToHide.animate({'marginLeft':'-32%'},function(){
			$(this).removeClass('activePlan').addClass('inactivePlan');
			planToShow.removeClass('inactivePlan').addClass('activePlan');
		});

		if($('.payment_plan.activePlan').first().prev().length > 0){
			$('#prevPlan').show();

		}
		/* planToHide.removeClass('activePlan').addClass('inactivePlan');
        $('.itemsWrapper').animate({'left':leftIntent},function(){
            $('.payment_plan.activePlan').last().after($('.payment_plan.activePlan').first());
            planToShow.removeClass('inactivePlan').addClass('activePlan');

        });*/
		/*var planToHide = $('.payment_plan.activePlan').first();
        var planToShow = $('.payment_plan.activePlan').last().next();
        planToHide.removeClass('activePlan').addClass('inactivePlan');
        planToShow.removeClass('inactivePlan').addClass('activePlan');
        var slides = $('.payment_plan').length;
        countNext++;
        if(slides-1 == countNext){

        }*/
	});
	$('#prevPlan').click(function(){
		var planToHide = $('.payment_plan.activePlan').last();
		var planToShow = $('.payment_plan.activePlan').first().prev();
		if($('.payment_plan.activePlan').first().prev().length == 0){
			$('#prevPlan').hide();
			return;
		}

		planToHide.removeClass('activePlan').addClass('inactivePlan');
		planToShow.removeClass('inactivePlan').addClass('activePlan').animate({'marginLeft':'0'},function(){
			/*$(this).removeClass('inactivePlan').addClass('activePlan');*/

		});
		if($('.payment_plan.activePlan').last().next().length > 0){
			$('#nextPlan').show();
			return;
		}
		/*planToHide.animate({'marginRight':'-32%'},function(){
            $(this).removeClass('activePlan').addClass('inactivePlan');
             planToShow.removeClass('inactivePlan').addClass('activePlan')
        });*/

		/* var item_width = $('.payment_plan').outerWidth() + 10;
        var leftIntent = parseInt($('.itemsWrapper').css('left')) + item_width;

        $('.itemsWrapper').animate({'left':leftIntent},function(){
            $('.payment_plan').first().after( $('.payment_plan').last());
            $('.itemsWrapper').css({'left' : '-33%'});
        });*/
	});
	var date = new Date();
	var cooks = document.cookie;
	$('.closeModalSchool').click(function(){
		$('#backToSchoolModal').addClass('inactive');
		$('.backToSchoolOverlay').addClass('inactive');
		$('#backToSchoolModal').fadeOut();
		$('.backToSchoolOverlay').fadeOut();
		/*localStorage.setItem("promotion_data",date.setDate(date.getMinutes() +2 ));*/
		setCookies();
	});
	$('.backToSchoolOverlay').click(function(){
		$('#backToSchoolModal').addClass('inactive');
		$('.backToSchoolOverlay').addClass('inactive');
		$('#backToSchoolModal').fadeOut();
		$('.backToSchoolOverlay').fadeOut();
		/*localStorage.setItem("promotion_data",date.setDate(date.getMinutes() +1 ));*/
		setCookies();
	});

	setTimeout(function(){

		if(getCookie("id") == ""){
			/* $('#backToSchoolModal').fadeIn();
            $('.backToSchoolOverlay').fadeIn();
            $('.closeModalFromBtn').addClass('inactive');
            $('.closeModalSchool').removeClass('inactive'); */
		}else{
			/* localStorage.setItem("promotion_data",date.setDate(date.getDate() +1 ));*/
		}


	},10000);
	function setCookies(){
		var reg_id = localStorage.registrationId;
		var d = new Date();
		d.setTime(d.getTime() + (1*24*60*60*1000));
		var expires = "expires="+ d.toUTCString();
		document.cookie = "id="+reg_id+"; "+expires;
	}
	/*var theDate = new Date(localStorage.getItem("promotion_data") * 1000);
    dateString = theDate.toGMTString();
    console.log(dateString );*/
	/***** SAVING USER PHONE NUMBER *********/
	$('#savePhoneNumber').click(function(){
		$(this).html("Запазване...");
		$('#userPhone').removeClass('wrongInput');
		ajaxPostRequester('user/put/telephone',{phone:$('#userPhone').val()},function(data){
			if(typeof data.error != "undefined"){

				$('#userPhone').addClass('wrongInput');

				$('#savePhoneNumber').html("Запази");


			}else{
				$('#phoneSuccess').html('<span style="color:green">Успешно добавен телефон!</span>');
				setTimeout(function(){
					$('.askingForTelephone').fadeOut();
					$('.askingForTelephoneOverlay').fadeOut();
				},2000);
			}
		});
	});
	/****** END SAVING USER PHONE NUMBER ********/
	if(mobile){
		$('.payment_plan').removeClass('activePlan').addClass('inactivePlan');
		$('.payment_plan').first().removeClass('inactivePlan').addClass('activePlan');
	}
	if(sessionStorage.getItem('goto_pay') == "feature_plan1"){
		toExtraPlan1();
		sessionStorage.removeItem('goto_pay');
	}else if(sessionStorage.getItem('goto_pay') == "feature_plan2"){
		toExtraPlan2();
		sessionStorage.removeItem('goto_pay');
	}
	function lastStepPay(){

		$('#middleStepFeature').addClass('inactive');
		$('#modalStartTrial').removeAttr('style');
		$('.secondStepPremium').addClass('inactive');
		$('.termsPaymentPopup').hide();
		$('#global_steps_pay').hide();
		$('.continuePlan').removeClass('payment_middle').removeClass('payment_checked').show();
		$('.continuePlan ').removeAttr('style');
		$('.closeModalTrial').show();
		localStorage.setItem('payment_step','lastStep');
		choosePaymentMethodAndRegisterUser(continuePaymentsData);
		$('.payment_steps_global .step-2').removeClass('activeStep').addClass('finishedStep').find('.payment_step_number span').addClass('fa fa-check').html('');
		$('.payment_steps_global .step-3').addClass('activeStep');
		$('#global_steps_pay').show();
		$('.newPrices').addClass('inactive');
		$('.newPricesOverlay').addClass('inactive');
		$('.feature1').removeClass('inactive');
		$('.feature2').addClass('inactive');
		$('.add_pack1').removeClass('inactive');
		$('.add_pack2').addClass('inactive');
		document.body.style.overflow = 'visible';
		$('.special-offer-overlay').addClass('inactive');
	}
	$('.continueLastStep').click(function(){
		lastStepPay();
	});


	/****** MULTIPLAYER *********/
	$('#inviteDenied').click(function(){
		$('#multiplayerInvitation').fadeOut();
		$('#multiplayerInvitationOverlay').fadeOut();
	});
	$('#inviteAccepted').click(function(){
		$('#multiplayerInvitation').fadeOut();
		$('#multiplayerInvitationOverlay').fadeOut();
	});
	/*for(var i=0;i<3;i++) {
        $('.multiplayerPlanel').fadeTo('slow', 0.8).fadeTo('slow', 1.0);
    }*/



	/* ===== section ===== PAGE FUNCTIONS ===== section ===== */

	/* ---------- Top Menu glues to Top when scroll --------- */

	/*    var num = $('.secondTopStripe').height();  */

	/* number of pixels before modifying styles */

	/*    if ($(window).scrollTop() > num) {
        $('#menuTopStripe').addClass('fixedMenu');
    } else {
        $('#menuTopStripe').removeClass('fixedMenu');
    }
    $(window).bind('scroll', function () {
        if ($(window).scrollTop() > num) {
            $('#menuTopStripe').addClass('fixedMenu');
        } else {
            $('#menuTopStripe').removeClass('fixedMenu');
        }
    });
*/
	setTimeout(function(){
		/* $('.progressProfile').find('.pathsActivity').css({height:$('.pathsActivitySlider ').height()});*/
	},2000);
	$('.nav-menu li.justifyFlexCenter').click(function(){
		$('.nav-menu li').removeClass('activeLi');
		$(this).addClass('activeLi');
	});
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('#goUp').fadeIn();
		} else {
			$('#goUp').fadeOut();
		}
	});
	if(locale == "zh"){
		if(is_user != ""){
			goUp();
		}
	}else{
		goUp();
	}

	function goUp(){
		$('#goUp').click(function () {
			$("html, body").animate({
				scrollTop: 0
			}, 600);
			return false;
		});
	}
	$('.badges_placeholder .badges').mouseover(function(){

	});
	/* ---------- Call to Action Button Actions ---------- */

	/*  CODE:
    $('#butaforenDivHeroButton').click(function() {
        $(this).hide();
        $('#callToActionText').slideUp();
        $('#callToActionLinkWrapper').slideDown();
    });

    $('body').click(function(event){
        if($('.heroButton').length > 0) {
            if ($('.heroButton').has(event.target).length === 0 &&
                !$('.heroButton').is(event.target)) {
                $('#butaforenDivHeroButton').show();
                $('#callToActionText').slideDown();
                $('#callToActionLinkWrapper').slideUp();
            }
        }
    });
*/
	var scrolled = 0;
	$('#scrollDownArrow').click(function(){
		var element = $('.hideScroll');
		if(element.scrollTop() + element.innerHeight() < element[0].scrollHeight){
			scrolled+=30;
		}
		console.log(scrolled);
		element.scrollTop(scrolled);
	});
	$('#scrollUpArrow').click(function(){
		var element = $('.hideScroll');
		if(element.scrollTop() == 0){
			scrolled = 0;
		}else{
			scrolled-=30;
		}
		console.log(scrolled);
		element.scrollTop(scrolled);

	});



	/* ---------- Home Page - 3 Gear Animation action ---------- */
	if(!mobile){
		$('#homeSectionPicWrap').mouseover(function(){
			$('#threeGearPic').hide();
			$('#hideGearMobile').show();
			$('#threeGearAnim').show();
		});
	}
	if(!mobile){
		$('#homeSectionPicWrap').mouseleave(function(){
			$('#threeGearPic').show();
			$('#threeGearAnim').hide();
			$('#hideGearMobile').hide();
		});
	}

	/* ---------- Get help message form actions ---------- */
	var containerHeight,
		containerOldHeight = $('#messageWrapper').height();
	var capcha_load = false;
	$('#emailInput').click(function(){
		if(!capcha_load){
			//loadCapcha();
			capcha_load = true;
			grecaptcha.render(
				"capcha-group",
				{sitekey:"6LfyGiEUAAAAAGpNv6bk4bLdqGEeDhmLdMchQxWj"}
			);
		}
	});
	$('#butaforenDiv').click(function() {
		if(!capcha_load){
			loadCapcha();
			capcha_load = true;
		}
		$(this).fadeOut();
		$('#messageWrapper').animate({
			height: '300'
		}, function(){
			$('#mailForm').fadeIn();
			$('#sendRequestTitle').show();

			containerHeight = $('#messageWrapper').height();
			$('#messageWrapper').css('height', 'auto');
		});
	});

	function closeRequest () {

		$('#mailForm').hide();
		$('#sendRequest').fadeIn();
		$('#butaforenDiv').fadeIn();
		$('#messageWrapper').css('height', containerHeight);
		$('#messageWrapper').animate({
			height: containerOldHeight
		}, function(){
			$('#messageWrapper').removeAttr('style');
		});
	}
	$('#cancelMessageBtn').click(closeRequest);
	/*$('#sendRequest').click(closeRequest);*/

	/* ----- Support FAQ accordeon  ------ */


	$('.slideTrigger').click(function(){
		console.log("dasda")
		openTicketsList(this);
		/*var id = $(this).attr("id") + "_lessons";
        var el = $("#" + id);
        var all = $('.slideArticles');
        var condition = el.css("display") === "block";

        $('.faqContainer').find('.fa').removeClass('fa-play fa-rotate-90').addClass('fa-question');

        $('.faqContainer').css({
                position: 'relative',
                zIndex: '0',
                boxShadow: '0px 0px 0px rgba(102, 102, 102, 1)'
            }).animate({

            });
        $('.faqIcon').removeAttr('style');

        all.slideUp();

        var prCol = $('.prCol').css('color');

        if (!condition) {
            $(this).closest('.faqContainer').find('.fa').removeClass('fa-question').addClass('fa-play fa-rotate-90');
            $(this).closest('.faqContainer').find('.faqIcon').css('line-height', '2.3rem');
            $(this).closest('.faqContainer').find('.faqIcon').animate({
                backgroundColor: prCol
            });
            $(this).css({
                position: 'relative',
                zIndex: '1',
                boxShadow: '0px 0px 16px rgba(102, 102, 102, 1)'
            });
            el.slideDown();
        }*/
	});

	/* ----- LTI links .. sth.. in courseStructure.blade.php  ------ */

	$('#formLinks input ').click(function () {
		$(".allLinks").toggle(this.checked);
	});

	/* ----- Course View accordeon  ------ */
	$('.slideItems').click(function () {
		var el = $(this).next('ul');
		console.log(el);
		if (!el.is(':visible')){
			$(this).closest('.modBox').animate({
				paddingBottom: '16px'
			});
			$(this).find('.modLi').animate({
				paddingTop: '24px',
				paddingBottom: '24px'
			});
			el.slideDown();
			if($(this).find('.BoxFa').hasClass('fa-plus')){
				$(this).find('.BoxFa').removeClass('fa-plus').addClass('fa-minus');
			}
		} else {
			$(this).closest('.modBox').animate({
				paddingBottom: '0'
			});
			$(this).find('.modLi').animate({
				paddingTop: '12px',
				paddingBottom: '12px'
			});
			el.slideUp();
			$('.modBox').animate({
				paddingBottom: '0'
			});
			$('.modLi').animate({
				paddingTop: '16px',
				paddingBottom: '16px'
			});
			if($(this).find('.BoxFa').hasClass('fa-minus')){
				$(this).find('.BoxFa').removeClass('fa-minus').addClass('fa-plus');
			}
		}
	});


	/* ----- ot PLamen .. sega ne se polzva  ------ */
	$(".showExercises").on("click", function() {
		$('.exercises').hide("slow");
		var el = $($(this).nextAll('.exercises')[0]);
		var condition = el.css("display") === "block";

		!condition?$($(this).nextAll('.exercises')[0]).show("slow"):"";
	});

	/* ----- Course View accordeon - EXPAND Button  ------ */
	$("body").click(function(){
		setTimeout(function(){
			if($('.materialUl:not(":first") li:visible').length == 0){
				$(".expandCollapse").html(trans('langPage.showAcord'));
				$("#expFa").removeClass("fa-angle-up").addClass("fa-angle-down");

			}else{
				$(".expandCollapse").html(trans('langPage.hideAcord'));
				$("#expFa").removeClass("fa-angle-down").addClass("fa-angle-up");
			}
		},700);
	});


	$('#expand').click(function () {

		$(this).hide().fadeIn();

		var all = $('.slideItems').next('ul');

		if ($("#expFa").hasClass("fa-angle-down")){
			/* CODE:
             * all.slideUp();
             */
			$('.modBox').animate({
				paddingBottom: '16px'
			});
			$('.modLi').animate({
				paddingTop: '24px',
				paddingBottom: '24px'
			});
			all.slideDown("slow");


			$(".expandCollapse").html(trans('langPage.hideAcord'));
			$("#expFa").removeClass("fa-angle-down").addClass("fa-angle-up");
			$('.BoxFa').removeClass('fa-plus').addClass('fa-minus');
		} else {
			$('.modBox').animate({
				paddingBottom: '0'
			});
			$('.modLi').animate({
				paddingTop: '16px',
				paddingBottom: '16px'
			});
			$(".expandCollapse").html(trans('langPage.showAcord'));
			all.slideUp("slow");
			$("#expFa").removeClass("fa-angle-up").addClass("fa-angle-down");
			$('.BoxFa').removeClass('fa-minus').addClass('fa-plus');
		}
		if($('#TFa').hasClass("fa-plus")){
			$('#TFa').removeClass('fa-plus').addClass('fa-minus');
		}else{
			$('#TFa').removeClass('fa-minus').addClass('fa-plus');
		}
	});
	$('.promotionBtnFixed').click(function(){
		$('#backToSchoolModal').fadeIn();
		$('.backToSchoolOverlay').fadeIn();
		$('.closeModalSchool').addClass('inactive');
		$('.closeModalFromBtn').removeClass('inactive');
	});
	$('.closeModalFromBtn').click(function(){
		var infoTop = $('.promotionBtnFixed').position().top,
			infoLeft = $('.promotionBtnFixed').offset().left + 15,
			infoRight = $(window).width() - infoLeft,
			infoBottom = $(window).height() - infoTop;
		$('#backToSchoolModal').animate({
			borderRadius: '500px',
			height: '30px',
			width: '30px',
			top: infoTop,
			left: infoLeft,
			opacity: '0'

		}, 500, function () {
			$('.backToSchoolOverlay').fadeOut();
			$('#backToSchoolModal').removeAttr('style');
			for(var i=0;i<3;i++) {
				$('.promotionBtnFixed').fadeTo('slow', 0.5).fadeTo('slow', 1.0);
				/*$('.promotionBtnFixed').animate({'background':'#039CE6'},500,function(){
                    $(this).animate({'background':'#b50027'},500);
                })*/
			}
		});
		/*$('#backToSchoolModal').fadeIn();
        $('.backToSchoolOverlay').fadeIn();*/

	});
	/* ----- Upload Avatar in User Profile ----- */

	$("#uploadAvatar").click(function() {
		if ($('#uploadForm').is(':visible')) {
			$("#uploadForm").slideUp("slow");

			$("#uploadForm").removeAttr('style');
			$("#uploadForm p").remove();
		} else {
			$("#uploadForm").slideDown("slow");
			if(mobile){
				$("#uploadForm").css({width: '266%',background: '#279DA1'});
			}



		}
	});
	$(".customFileUpload").click(function() {
		$("#uploadForm p").remove();
	});
	$("#uploadClose").click(function() {
		$("#uploadForm").slideUp("slow");
	});
	$('#uploadInfo').click(function(){
		if($('.tooltip-upload').is(":visible")){
			$('.tooltip-upload').hide();
		}else{
			$('.tooltip-upload').show();
		}

	});
	/* ----- Profile Picture size adjustments in user profile ----- */

	var pictureHeight = $("#profilePicture").height();
	var pictureWidth = $("#profilePicture").width();
	if (pictureHeight > pictureWidth){
		$("#profilePicture").css({
			width: "80px",
			height: "auto"
		});
	} else if (pictureHeight < pictureWidth) {
		$("#profilePicture").css({
			width: "auto",
			height: "80px"
		});
	} else {
		$("#profilePicture").css({
			width: "80px",
			height: "80px"
		});
	}

	$("#profilePicture").fadeIn();
	/* ----- Scroll function ----- */

	var noScroll = function(elem) {
		if (elem.is(':visible')) {
			$("body").scrollTop(0);
			$("body").addClass("noScroll");
		} else {
			$("body").removeClass("noScroll");
		}
	};

	/* ----- Goals  ----- */




	/* ----- Goal Button materal transition  ----- */
	/*
 * CODE:
    var goalStatusHeight = $('#goal_status').height(),
        goalStatusWidth = $('#goal_status').width();
    $('#goalStatusReplace').css({
        height: goalStatusHeight,
        width: goalStatusWidth
    });


    $("#goalButton").click(function() {
        $("#setGoalsOverlay").fadeIn();
        $('#goal_status').css({
           position: 'fixed',
           width: goalStatusWidth,
           zIndex: '1'
        });
        $('#goalStatusReplace').show().css({
            position: 'relative'
        });
    });
*/

	/* ----- Goal Button Shows Pop up ----- */

	/* CODE:
    $("#goalButton").click(function() {

    $("#setGoalsOverlay").fadeIn();
    noScroll($("#setGoalsOverlay"));

    dailyTaskFunc.getTaskModules(false);
    });
*/


	var closeGoals = function() {
		if(backToExercise != 0) {

			window.location= baseUrl + "/exercise/view/" + backToExercise;
		}
		else {
			$("#setGoalsOverlay").fadeOut();
			noScroll($("#setGoalsOverlay"));
		}
		$('body').removeClass('noScroll');
	};

	$("#closeGoals").click(closeGoals);
	$("#cancelTsks").click(closeGoals);
	/* ---- Profile - percent from Course and from path ---- */



	/* ----- Badge actions in page ----- */
	$('.boxTab').click(function() {
		var el = $(this).attr('id'),
			sufix = el.replace('tab', '');
		$('.boxTab').removeClass('activeBoxTab');
		$(this).addClass('activeBoxTab');

		$('.boxContent').hide();
		$('#box' + sufix).show();
	});


	/* ----- new Design Badge Actions ----- */
	$('.profile_badges').click(function(){
		$('.badge_screen').removeClass('inactive');
		$('body').addClass('noScroll');
	});

	/*$('.great_button').click(function(){
        $('#badge_overlay').addClass('inactive');
        $('#badge_overlay_lti').addClass('inactive');
    });*/

	$('.x_close_badge').click(function(){
		$('.badge_screen').addClass('inactive');
		$('body').removeClass('noScroll');
	});

	$('.badge_ok_inner').click(function(){
		$('.badge_screen').addClass('inactive');
		$('body').removeClass('noScroll');
	});

	$('.badge_out').click(function(){
		$('.badge_screen').removeClass('inactive');
		$('body').addClass('noScroll');
	});

	/* ----- User Settings in profile page ------ */
	$("#settingsTitle").parent().click(function() {
		console.log("A");
		if (!$("#settingsSlide").is(":visible")) {
			$("#settingsSlide").slideDown();
			$("#settingsAngle span").removeClass("fa-angle-down").addClass("fa-angle-up");
		} else {
			$("#settingsSlide").slideUp();
			$("#settingsAngle span").removeClass("fa-angle-up").addClass("fa-angle-down");
		}
	});
	$('#reportsTitle').parent().click(function(){
		if(!$('#reportsWrapper').is(":visible")){
			if($('.ticket').children().length == 0){
				getTicket();
			}

			$('#reportsWrapper').slideDown();
			$('#reportsAngle span').removeClass("fa-angle-down").addClass("fa-angle-up");
		}else {
			$('#reportsWrapper').slideUp();
			$('#reportsAngle span').removeClass("fa-angle-up").addClass("fa-angle-down");
		}
	});
	$('#subscribeTitle').parent().parent().click(function(){
		if(!$('#subscribtionWrapper').is(":visible")){
			$('#subscribtionWrapper').slideDown();
			$('#subscribeAngle span').removeClass("fa-angle-down").addClass("fa-angle-up");

		}else{
			$('#subscribtionWrapper').slideUp();
			$('#subscribeAngle span').removeClass("fa-angle-up").addClass("fa-angle-down");
		}
	});
	if(locale == "zh"){
		if(is_user != "" && current_page == 'profile'){
			clickPaths()
		}


	}else{
		clickPaths()
	}
	$('.editUserInfoButton').click(function() {
		var el = $('.user_settings_box').find('.login_input').not('#passChangeWrapper .login_input');

		if (el.prop('disabled') === true) {
			el.prop('disabled', false).addClass('activeInput');
			/*$('.settings_save_button').prop('disabled', false).removeClass('inactiveButton').addClass('button');*/
			$('.save_usernames_button').prop('disabled', false).removeClass('inactiveButton').addClass('button');
		} else {
			el.prop('disabled', true).removeClass('activeInput');
			/*$('.settings_save_button').prop('disabled', true).removeClass('button').addClass('inactiveButton');*/
			$('.save_usernames_button').prop('disabled', true).removeClass('button').addClass('inactiveButton');
		}

	});
	$('#changePasswordButton').click(function(){
		if (!$('#passChangeWrapper').is(':visible')) {
			$('#passChangeWrapper').slideDown();
			$('#passChangeWrapper').find('.login_input').prop('disabled', false).addClass('activeInput');
			$('#changePasswordButton').html(trans('langPage.messageNoChange'));
			/*$('.settings_save_button').prop('disabled', false).removeClass('inactiveButton').addClass('button');*/
			$('.save_password_button').prop('disabled', false).removeClass('inactiveButton').addClass('button');
		} else {
			$('#passChangeWrapper').slideUp();
			$('#passChangeWrapper').find('.login_input').prop('disabled', true);
			$('.user_settings_box').find('.login_input').prop('disabled', true).removeClass('activeInput');
			$('#changePasswordButton').html(trans('langPage.messageChange'));
			/*$('.settings_save_button').prop('disabled', true).removeClass('button').addClass('inactiveButton');*/
			$('.save_password_button').prop('disabled', true).removeClass('button').addClass('inactiveButton');
		}
	});
	/* Open school accounts overlay */
	$('#seeSchoolAccounts').click(function(){
		console.log("hellow");
		$('.schoolAccountsModal').fadeIn();
		$('.schoolAccountsOverlay').fadeIn();
	});
	$('.closeSchoolAccounts').click(function(){
		$('.schoolAccountsModal').fadeOut();
		$('.schoolAccountsOverlay').fadeOut();
	});
	/* ----- Prices carousel ----- */
	$('.carArrow').click(function() {
		if ($(this).closest('.payPrice').find('.monthly').is(":visible")) {
			$(this).closest('.payPrice').find('.monthly').hide();
			$(this).closest('.payPrice').find('.yearly').show();
		} else {
			$(this).closest('.payPrice').find('.yearly').hide();
			$(this).closest('.payPrice').find('.monthly').show();
		}
	});

	/* ----- Pop-Up Terms SHOW/HIDE ------ */

	$('#a_term').click(function(){
		$('.popup_terms').removeClass('inactive');
	});
	$('.ok_button').click(function(){
		$('.popup_terms').addClass('inactive');
	});
	$('.x_close').click(function(){
		$('.popup_terms').addClass('inactive');
	});

	/* ----- Footer Subscribe Button ------  */

	$('#butaforenFooterDiv').click(function() {
		$(this).hide();
		$('#subscribeIcon').fadeOut();
		/* $('#subscribeIcon-o').fadeIn(); */
		$('#subscribeIcon-o').css({
			cursor: 'pointer'
		});
		/*      CODE:
 *      $('#subscribeIcon').animate({
                color: '#78D2FF'
        });
*/

		var heightOfBreaker = 160;
		if ($(window).width() < 400) {
			heightOfBreaker = 200;
		}
		var widthOfBreaker = 80;
		if($(window).width() > 1024){
			widthOfBreaker = 70;
		}
		$('#footerBreaker').css({
			zIndex: 5
		}).animate({
			height: heightOfBreaker,
			width: widthOfBreaker + '%',
			borderRadius: '0'
		}, function() {
			$('#subscribeWrapper').fadeIn();
		});
	});

	$('#subscribeIcon-o').click(function(){
		var oldHeight = 36;//old 60px
		var oldWidth = 36;//old 60px

		if ($(window).width() < 1024) {
			oldHeight = 36;
			oldWidth = 36;
		}

		$('#subscribeWrapper').hide();
		$('#subscribeIcon').fadeIn();
		$('#subscribeIcon-o').fadeOut();


		$('#footerBreaker').css({
			zIndex: 1
		}).animate({
			height: oldHeight,
			width: oldWidth,
			borderRadius: '80px'
		}, function() {
			$('#subscribeIcon').fadeIn();
			$('#butaforenFooterDiv').show();
		});
	});
	if(!mobile){
		/*$('.courseTitleInStructure').css({marginRight:$('.startCourse').outerWidth()});*/
	}

	$('body').click(function(event){
		if($('#footerBreaker').length > 0) {
			if ($('#footerBreaker').has(event.target).length === 0 &&
				!$('#footerBreaker').is(event.target)) {
				$('#subscribeWrapper').hide();
				$('#subscribeIcon').fadeIn();
				$('#subscribeIcon-o').fadeOut();
				/*                   CODE:
*                    $('#subscribeIcon').animate({
*                        color: '#039be6'
*                    });
*/                  if ($(window).width() <= 1024) {
					$('#footerBreaker').animate({
						height: '36px',//old 60px
						width: '36px',//old 60px
						borderRadius: '80px'
					}, function() {
						$('#subscribeIcon').fadeIn();
						$('#butaforenFooterDiv').show();
					});
					console.log('1024');
				}else if($(window).width()<=1280){
					$('#footerBreaker').animate({
						height: '50px',//old 60px
						width: '50px',//old 60px
						borderRadius: '80px'
					}, function() {
						$('#subscribeIcon').fadeIn();
						$('#butaforenFooterDiv').show();
					});
				}else{
					$('#footerBreaker').animate({
						height: '60px',//old 60px
						width: '60px',//old 60px
						borderRadius: '80px'
					}, function() {
						$('#subscribeIcon').fadeIn();
						$('#butaforenFooterDiv').show();
					});
				}
			}
		}
	});
	/* ----- DropDown in plofile page - Path Tiles ------  */
	$('.profilePathMenu').click(function() {
		var el = $(this).closest('.profilePathMenuWrap').find('.profilePathMenuDropDown');
		var elHeight = el.height();
		var elWidth = 80;

		if (!el.is(':visible')) {
			el.css({
				width: '0',
				height: '0',
				display: 'inline-block'
			});
			el.animate({
				width: elWidth,
				height: elHeight
			});
		} else {
			el.animate({
				height: '0',
				width: '0'
			}, function() {
				el.removeAttr('style');
			});
		}
	});
	/* ----- Hide a Single Started Path from Profile ------ */
	$('.hidePathTile').click(function() {
		$(this).closest('.profilePathWrapper').find('.profilePathTile').slideUp();
		$(this).hide();
		$(this).parent().find('.showPathTile').show();
		$('.profilePathMenuDropDown').hide();
	});
	$('.showPathTile').click(function() {
		$(this).closest('.profilePathWrapper').find('.profilePathTile').slideDown();
		$(this).hide();
		$(this).parent().find('.hidePathTile').show();
		$('.profilePathMenuDropDown').hide();
	});
	$('.hidePathsActivity').click(function(){
		if($(this).closest('.pathsActivity').find('.coursesPaths').is(":visible")){
			$(this).closest('.pathsActivity').find('.coursesPaths').slideUp();
			$(this).find('.fa').removeClass('fa-angle-up').addClass('fa-angle-down').css({top:'50%'});
			$('.col-dashboard-2').css({height:'100%'});
		}else{
			$(this).closest('.pathsActivity').find('.coursesPaths').slideDown();
			$('.col-dashboard-2').css({height:'auto'});
			$(this).find('.fa').addClass('fa-angle-up').removeClass('fa-angle-down').css({top:'47%'});
		}
	});
	$('.showPathsActivity').click(function(){
		console.log('b');
		/*$(this).unbind('click');*/

	});
	/* ----- Show All Paths Button ------ */
	$('#showAllPaths').click(function() {
		$(this).hide();
		$('#hideAllPaths').show();
		$('.showAllContainer').slideDown();
	});
	$('#hideAllPaths').click(function() {
		$(this).hide();
		$('#showAllPaths').show();
		$('.showAllContainer').slideUp();
	});

	/* ----- Change LogIn courseBGPic ------ */
	/*   var BGPic = 'url("http://localhost/azprogramiram/public/images/coursePageBackground/HTML-BUTAF_courseBGPic.gif")';
    $('.courseBackground').css({backgroundImage: BGPic});
*/

	/* ----- Course Info (courseStructure.blade.php) Actions ------ */
	if(localStorage.getItem('free_reg') != null){
		$('#free_reg_title').show();
		localStorage.removeItem('free_reg');
	}
	if(locale == "zh"){
		if(is_user != ""){
			courseStructureAccordion();
		}
	}else{
		courseStructureAccordion();
	}
	function courseStructureAccordion(){
		$('.infoRow').click(function() {
			if ($(this).find('.infoRowBody').is(':visible')) {
				$(this).find('.infoRowBody').slideUp();
			} else {
				if(!isJunior){
					$('.infoRowBody').slideUp();
				}
				/* To disable "only one Item open" feature, comment this line */
				$(this).find('.infoRowBody').slideDown();
			}
		});
	}
	/* ----- Quotes Actions ------ */

	$('.manBox').click(function() {
		switch ($(this).attr('id')) {
			case 'quote1':
				$('.manWordsWrap').hide();
				$('#wrap1').show();
				break;
			case 'quote2':
				$('.manWordsWrap').hide();
				$('#wrap2').show();
				break;
			case 'quote3':
				$('.manWordsWrap').hide();
				$('#wrap3').show();
				break;
		};
	});

	/* ----- Mobile - Expand News Section in home page ------ */
	if (mobile) {
		$('.firstExpandArrow').click(function() {
			if (!$('#firstNewsChunk').is(':visible')) {
				$('#firstNewsChunk').slideDown();
				$('.firstExpandArrow span').removeClass('fa-angle-down').addClass('fa-angle-up');
			} else {
				$('#firstNewsChunk').slideUp(function() {
					$('#firstNewsChunk').removeAttr('style');
					$('.firstExpandArrow span').removeClass('fa-angle-up').addClass('fa-angle-down');
				});

			}
		});

		$('.secondExpandArrow').click(function() {
			if (!$('#secondNewsChunk').is(':visible')) {
				$('#secondNewsChunk').slideDown();
				$('.secondExpandArrow span').removeClass('fa-angle-down').addClass('fa-angle-up');
			} else {
				$('#secondNewsChunk').slideUp(function() {
					$('#secondNewsChunk').removeAttr('style');
					$('.secondExpandArrow span').removeClass('fa-angle-up').addClass('fa-angle-down');
				});
			}
		});

		$('.thirdExpandArrow').click(function() {
			if (!$('#pathsWrapper').is(':visible')) {
				$('#pathsWrapper').slideDown();
				$('.thirdExpandArrow span').removeClass('fa-angle-down').addClass('fa-angle-up');
			} else {
				$('#pathsWrapper').slideUp(function() {
					$('#pathsWrapper').removeAttr('style');
					$('.thirdExpandArrow span').removeClass('fa-angle-up').addClass('fa-angle-down');
				});
			}
		});

		$('.fourthExpandArrow').click(function() {
			if (!$('#coursesWrapper').is(':visible')) {
				$('#coursesWrapper').slideDown();
				$('.fourthExpandArrow span').removeClass('fa-angle-down').addClass('fa-angle-up');
			} else {
				$('#coursesWrapper').slideUp(function() {
					$('#coursesWrapper').removeAttr('style');
					$('.fourthExpandArrow span').removeClass('fa-angle-up').addClass('fa-angle-down');
				});
			}
		});

	}
	$('#agree_term').click(function (){
		$('.popup_terms').removeClass('inactive');
	});
	$('#subsctibeBtn').click(function (){
		var email = $('#subsctibeEmail').val().trim();
		if(validateEmail(email)){
			if(email.length > 0 ){
				$.ajaxSetup({
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
					}
				});
				$.post( baseUrl+ '/subscribe', {email: email })
					.done(function() {
						$('#subsctibeEmail').val("");
						$('#emailMsg').remove();
						$('#subscribeForm').append('<div id="emailMsg" style="color:green">' + trans('langPage.successfulSubscribe') + '</div>');
						$('#emailMsg').slideDown();
					});
			}
		}else{
			$('#emailMsg').remove();
			$('#subscribeForm').append('<div id="emailMsg" style="color:red">' + trans('langPage.invalidMail') + '</div>');
			$('#emailMsg').slideDown();
		}
	});



	if($('.econtDataPayment').is(":visible")){
		/*$('.continuePlan').css({float:'right'});*/
	}
	if(current_page == "courses" || current_page == "parents"){
		if(localStorage.getItem("level1") != null){
			localStorage.removeItem("level1");
		}else if(localStorage.getItem("level2") != null){

			$('.kid_paths').addClass('inactive');
			$('.console_path').removeClass('inactive');
			$('.web_apps_db_path').addClass('inactive');
			$('.builder_path').addClass('inactive');
			$('.singleCourseLevel').removeClass('activeCourseLevel');
			$("#console_path").addClass('activeCourseLevel');
			localStorage.removeItem("level2");

		}else if(localStorage.getItem("level3") != null){

			$('.kid_paths').addClass('inactive');
			$('.console_path').addClass('inactive');
			$('.web_apps_db_path').addClass('inactive');
			$('.builder_path').removeClass('inactive');
			$('.singleCourseLevel').removeClass('activeCourseLevel');
			$("#builder_path").addClass('activeCourseLevel');
			localStorage.removeItem("level3");

		}else if(localStorage.getItem("level4") != null){

			$('.kid_paths').addClass('inactive');
			$('.console_path').addClass('inactive');
			$('.web_apps_db_path').removeClass('inactive');
			$('.builder_path').addClass('inactive');
			$('.singleCourseLevel').removeClass('activeCourseLevel');
			$("#web_apps_db_path").addClass('activeCourseLevel');
			localStorage.removeItem("level4");

		}
	}
});
/*$(window).resize(function(){
    var leftMenuWidth = $('.profilePathHeader').offset().left;
    if($(window).width()>=1440){
        $('.subPathsNavWrapper').css({width:leftMenuWidth-15,left:'15px',background:'transparent'});
    }else{
        $('.subPathsNavWrapper').css({width:'200px',left:'-40%',backgorund:'rgba(204, 204, 204, 0.39)'});
    }

});*/

function validateEmail(email) {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

function selectItemFromMenu(thisId) {
	$(thisId).css({
		borderBottom: '3px solid #039be6',
		color: '#039be6'
	});
};
/*$(document).ready(function (){
    $('#subsctibeBtn').click(function (){
    var email = $('#subsctibeEmail').val().trim();
    if(validateEmail(email)){
        if(email.length > 0 ){
            $.post( baseUrl+ '/action', { type: "actionPostSubscribe", email: email })
                .done(function() {
                    $('#subsctibeEmail').val("");
                    $('#emailMsg').remove();
                    $('#subscribeForm').append('<div id="emailMsg" style="color:green">' + trans('langPage.successfulSubscribe') + '</div>');
                    $('#emailMsg').slideDown();
                });
        }
    }else{
       $('#emailMsg').remove();
       $('#subscribeForm').append('<div id="emailMsg" style="color:red">' + trans('langPage.invalidMail') + '</div>');
       $('#emailMsg').slideDown();
    }
});
});*/
/*TOVA KRIE6E ZAGLAVIETO SLED KATP PREMESTIHME SCRIPTOVETE*/

/*$('.courseStructureHeroTitle').hide();*/


/* SCROLL ARROWS COURSE IN FOOTER*/
var tiket_render = false;
function getTicket(){
	if(!tiket_render){
		retrunUsertickets();
		tiket_render = true;
	}
	/*$.ajax({
        url: baseUrl+"/support/tikets/get",
        method:"post",
        data: {user_id: userId}
      }).done(function(data) {
        listTickets(JSON.parse(data));
      });*/
}

function listTickets(tickets){
	if(tickets.length > 0){
		for(var i = 0;i<tickets.length;i++){
			console.log(i);
			var ticket = $('.tikcet_tmp').clone()
				.appendTo('.ticket').removeClass('inactive').removeClass('tikcet_tmp')
				.attr("id", tickets[i].display_id)
				.find('.subj').html(tickets[i].subject+"#"+tickets[i].display_id)
				.parent().parent().find('.desc').html(tickets[i].description).attr("id",tickets[i].display_id+"_lessons");

		}
	}else{
		$('#ticketDefaultMessage').removeClass('inactive');
	}

}
function openTicket(self){
	openTicketsList(self);
}
$('.close-msg').click(function(){
	$('#restriction_modal').hide();
	$('.overlayDark').hide();
});
$('.play_now').click(function(){
	playNow();
	sessionStorage.removeItem('additional_plan');
	sessionStorage.removeItem('select-half-plan');
});

function playNow(){
	$('#modalStartTrial').removeClass('inactive');
	$('.overlayStartTrial').removeClass('inactive');
	if(is_user && is_user!=""){
		$('.continuePlan').addClass('premium_plan');
	}
}
$('#closeExtra').click(function(){
	$('#cannotExtra').hide();
	$('#cannotExtraOverlay').hide();
});
function dontHavePermisionForExtra(){
	$('#cannotExtra').show();
	$('#cannotExtraOverlay').show();
}

function toExtraPlan1(){
	if(user_is_paid){
		playNowTarrif();
		sessionStorage.setItem('select-feature-plan','select-feature-plan-1');
	}else{
		dontHavePermisionForExtra();
	}

}
function toExtraPlan2(){
	if(user_is_paid){
		playNowTarrif();
		sessionStorage.setItem('select-feature-plan','select-feature-plan-2');
	}else{
		dontHavePermisionForExtra();
	}
}
function playNowTarrif(){
	$('body').append('<script src="https://checkout.stripe.com/checkout.js"></script>');
	playNow();
	choosepaymentPlan();
	$('.continuePlan').removeClass('premium_plan');
	sessionStorage.removeItem('select-feature-plan');
	sessionStorage.removeItem('select-half-plan');
}
$('.getFree').click(function(){
	$(this).removeClass('inactiveButton');
	$(this).addClass('planBG');
	$('.getPremium').removeClass('planBG').addClass('inactiveButton');
	$('.continuePlan').removeClass('premium_plan');
	$('.continuePlan').addClass('free_plan');
	$('.continuePlan').removeAttr('style');
	localStorage.setItem('payment_step','firstStepFree');
	$('.continuePlan').find('.fa').remove();
});
$('.getPremium').click(function(){
	$(this).removeClass('inactiveButton');
	$(this).addClass('planBG');
	$('.getFree').removeClass('planBG').addClass('inactiveButton');
	$('.continuePlan').removeClass('free_plan');
	$('.continuePlan').addClass('premium_plan');
	$('.continuePlan').removeAttr('style');
	localStorage.setItem('payment_step','firstStepPremium');
	$('.continuePlan').append('<span class="fa fa-shopping-cart"></span>');
});
function choosepaymentPlan(self){
	$('.choosePlan').addClass('inactive');
	if(!mobile){
		$('#modalStartTrial .secondStepPremium').removeClass('inactive');
	}else{
		$('#modalStartTrial .secondStepPremium').removeClass('inactive').find('.emailPopup').hide();
	}

	$('.getPremium').addClass('inactive');
	$('.getFree').addClass('inactive');
	$('.continuePlan').removeClass('free_plan');

	$('.continuePlan').removeClass('premium_plan');
	$('.firstStep').addClass('inactive');
	$('.choosePlan').addClass('inactive');
	$('.termsPaymentPopup').removeClass('inactive');
	$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
	localStorage.setItem('payment_step','secondStep');
}
var typePay = 'EGN';

var pass_mail = false;
var pass_names = false;
var pass_address = false;
var pass_egn = false;
var pass_eik = false;
var pass_cname = false;
var pass_lnc = false;
var pass_bulstat = false;
var pass_password = false;
$("#emailPayFromHome").bind("change paste keyup", function(){
	if($('#emailPayFromHome').val().length >= 10 && validateEmail($('#emailPayFromHome').val())){
		pass_mail = true;
	}else{
		pass_mail = false;
	}
	checkValidation();

});
$("#passwordPayFromHome").bind("change paste keyup", function(){
	if($('#emailPayFromHome').val().length >= 10 && validateEmail($('#passwordPayFromHome').val())){
		pass_password = true;
	}else{
		pass_password = false;
	}
	checkValidation();

});
$("#emailLoginPayFromHome").bind("change paste keyup", function(){
	if($('#emailLoginPayFromHome').val().length >= 10 && validateEmail($('#emailLoginPayFromHome').val())){
		pass_mail = true;
	}else{
		pass_mail = false;
	}
	checkValidation();

});
$("#easy-pay-fullname").bind("change paste keyup", function(){
	if($('#easy-pay-fullname').val().length >= 1){
		pass_names = true;
	}else{
		pass_names = false;
	}
	checkValidation();

});
$("#address").bind("change paste keyup", function(){
	if($('#address').val().length >= 1){
		pass_address = true;
	}else{
		pass_address = false;
	}
	checkValidation();

});
$("#EGN").bind("change paste keyup", function(){
	if($('#EGN').val().length >= 1){
		pass_egn = true;
	}else{
		pass_egn = false;
	}
	checkValidation();

});
$("#company_name").bind("change paste keyup", function(){
	if($('#company_name').val().length >= 1){
		pass_cname = true;
	}else{
		pass_cname = false;
	}
	checkValidation();

});
$("#BULSTAT").bind("change paste keyup", function(){
	if($('#BULSTAT').val().length >= 1){
		pass_bulstat = true;
	}else{
		pass_bulstat = false;
	}
	checkValidation();

});
$("#eik_dds").bind("change paste keyup", function(){
	if($('#eik_dds').val().length >= 1){
		pass_eik = true;
	}else{
		pass_eik = false;
	}
	checkValidation();

});
$("#LNC").bind("change paste keyup", function(){
	if($('#LNC').val().length >= 1){
		pass_lnc = true;
	}else{
		pass_lnc = false;
	}
	checkValidation();

});
var pass_egn_econt = false;
var pass_bulstat_econt = false;
var pass_vat_econt = false;
var pass_lnc_econt = false;
var typePayEcont = 'EGN';
var typePayBank = 'EGN';

var pass_egn_bank = false;
var pass_bulstat_bank = false;
var pass_vat_bank = false;
var pass_lnc_bank = false;
function checkFillment(){
	if(typePayEcont == 'EGN'){
		if($('#econt_fullname').val().length >= 1 && ($('#address_person').val().length >= 1 ||  $('.econt-radio').is(":checked"))  && $('#phone').val().length >= 1){
			pass_egn_econt = true;
		}
	}
	if(typePayEcont == 'BULSTAT'){
		if($('#econt_company_name').val().length >= 1 && $('#address_person').val().length >= 1 && $('#econt_BULSTAT').val().length >= 1 && $('#econt_company_address').val().length >= 1 && $('#econt_company_fullname').val().length >= 1 && $('#phone').val().length >= 1 &&$('#eik_dds_econt').val().length >= 1 ){
			pass_bulstat_econt = true;
		}
	}
	if(typePayEcont == 'VAT'){
		if($('#econt_company_name').val().length >= 1 && $('#address_person').val().length >= 1 && $('#econt_BULSTAT').val().length >= 1 && $('#econt_company_address').val().length >= 1 && $('#econt_company_fullname').val().length >= 1 && $('#phone').val().length >= 1){
			pass_vat_econt = true;
		}
	}
	if(typePayEcont == 'LNC'){
		if($('#econt_fullname').val().length >= 1 && $('#address_person').val().length >= 1 && $('#econt_lnc').val().length >= 1 && $('#phone').val().length >= 1){
			pass_lnc_econt = true;
		}
	}
	checkValidation();
}
function checkFillmentBank(){
	console.log('asd');
	if(typePayBank == 'EGN'){
		if($('#fullname_bank').val().length >= 1 && $('#address_bank').val().length >= 1 ){
			pass_egn_bank = true;
		}
	}
	if(typePayBank == 'BULSTAT'){
		if($('#company_name_bank').val().length >= 1 && $('#BULSTAT_bank').val().length >= 1 && $('#address_company_bank').val().length >= 1 && $('#fullname_company_bank').val().length >= 1  ){
			pass_bulstat_bank = true;
		}
	}
	if(typePayBank == 'VAT'){
		if($('#company_name_bank').val().length >= 1 && $('#BULSTAT_bank').val().length >= 1 && $('#eik_dds_bank').val().length >= 1 && $('#address_company_bank').val().length >= 1 && $('#fullname_company_bank').val().length >= 1){
			pass_vat_bank = true;
		}
	}
	if(typePayBank == 'LNC'){
		if($('#fullname_bank').val().length >= 1 && $('#address_bank').val().length >= 1 && $('#LNC_bank').val().length >= 1){
			pass_lnc_bank = true;
		}
	}
	checkValidation();
}
var checkSecondStep = setInterval(function() {
	if($('.secondStepPremium').is(":visible")){
		if(is_user != "" && is_user != undefined){
			var payment_btn = $('.paymentBtns');
			var checked_pay = false;
			for(var i=0;i<payment_btn.length;i++){
				console.log($(payment_btn[i]).attr('style'));
				if($(payment_btn[i]).attr('style') == "border: 1px solid rgb(0, 153, 153);"){
					checked_pay = true;
				}
			}

			if(checked_pay){
				$('.continuePlan').removeAttr('style');
				$('.continuePlan').addClass('plan_payments');
				clearInterval(checkSecondStep);
			}else{
				$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			}
		}else{
			var pass_second = false;
			if(is_reg){
				if(pass_mail){
					pass_second = true;
				}
			}else{
				if(pass_mail && pass_password){
					pass_second = true;
				}
			}
			var payment_btn = $('.paymentBtns');
			var checked_pay = false;
			for(var i=0;i<payment_btn.length;i++){
				if($(payment_btn[i]).css('border') == "1px solid rgb(0, 153, 153)"){
					checked_pay = true;
				}
			}
			if(checked_pay && (pass_second || mobile) ){
				$('.continuePlan').removeAttr('style');
				if(mobile){
					if($('.secondStepPremium .plan_popup:first-child').is(":visible")){
						$('.continuePlan').addClass('payment_checked_mobile');
					}
				}else{
					$('.continuePlan').addClass('plan_payments');
				}
			}else{
				$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			}
		}
	}
}, 1000);


var checkThirdStep = setInterval(function() {

}, 1000);
function checkValidation(){
	if($('.thirdStepPremium').is(":visible") && $('.paymentsCards').is(":visible")){
		if(typePay == 'EGN'){
			if(pass_names && pass_address ){
				$('.continuePlan').removeAttr('style');
			}else{
				$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			}
		}
		if(typePay == 'BULSTAT'){
			if(pass_names && pass_address &&pass_cname&& pass_bulstat){
				$('.continuePlan').removeAttr('style');
			}else{
				$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			}
		}
		if(typePay == 'VAT'){
			if(pass_names && pass_address &&pass_cname&& pass_bulstat && pass_eik){
				$('.continuePlan').removeAttr('style');
			}else{
				$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			}
		}
		if(typePay == 'LNC'){
			if(pass_names && pass_address &&pass_lnc){
				$('.continuePlan').removeAttr('style');
			}else{
				$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			}
		}
	}else if($('.thirdStepPremium').is(":visible") && $('.econtDataPayment').is(":visible")){
		if(typePayEcont == 'EGN'){
			if(pass_egn_econt){
				$('.continuePlan').removeAttr('style');
			}else{
				$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			}
		}
		if(typePayEcont == 'BULSTAT'){
			if(pass_bulstat_econt){
				$('.continuePlan').removeAttr('style');
			}else{
				$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			}
		}
		if(typePayEcont == 'VAT'){
			if(pass_vat_econt){
				$('.continuePlan').removeAttr('style');
			}else{
				$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			}
		}
		if(typePayEcont == 'LNC'){
			if(pass_lnc_econt){
				$('.continuePlan').removeAttr('style');
			}else{
				$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			}
		}

	} else if($('.thirdStepPremium').is(":visible") && $('#bankCashTransfer').is(":visible")){
		if(typePayEcont == 'EGN'){
			if(pass_egn_bank){
				$('.continuePlan').removeAttr('style');
			}else{
				$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			}
		}
		if(typePayEcont == 'BULSTAT'){
			if(pass_bulstat_bank){
				$('.continuePlan').removeAttr('style');
			}else{
				$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			}
		}
		if(typePayEcont == 'VAT'){
			if(pass_vat_bank){
				$('.continuePlan').removeAttr('style');
			}else{
				$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			}
		}
		if(typePayEcont == 'LNC'){
			if(pass_lnc_bank){
				$('.continuePlan').removeAttr('style');
			}else{
				$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			}
		}

	}
}
$('.continuePlan').click(function(){
	if(!$(this).hasClass('disabled')){
		if($(this).hasClass('premium_plan')){
			localStorage.setItem("type_account","premium");
			localStorage.setItem("step_payment","first");
			choosepaymentPlan();
			$('.payment_steps_global .step-1').removeClass('activeStep').addClass('finishedStep').find('.payment_step_number span').addClass('fa fa-check').html('');
			$('.payment_steps_global .step-2').addClass('activeStep');
		}else if($(this).hasClass('free_plan')){
			localStorage.setItem("type_account","free");
			localStorage.setItem("step_payment","first");
			$('.getPremium').addClass('inactive');
			$('.getFree').addClass('inactive');
			$('#modalStartTrial .secondStep').toggle('slow').removeClass('inactive');
			$(this).addClass('inactive');
			$('.firstStep').addClass('inactive');

			$('.choosePlan').addClass('inactive');

		}else if($(this).hasClass('payment_checked') && !$(this).hasClass('payment_checked_mobile')){
			localStorage.setItem('payment_step','lastStep');
			choosePaymentMethodAndRegisterUser(continuePaymentsData);
			$('.payment_steps_global .step-2').removeClass('activeStep').addClass('finishedStep').find('.payment_step_number span').addClass('fa fa-check').html('');
			$('.payment_steps_global .step-3').addClass('activeStep');
		}else if($(this).hasClass('payment_checked_mobile')){
			$('.secondStepPremium .plan_popup:first-child').hide();
			$('.secondStepPremium').find('.emailPopup').show();
			$(this).addClass('payment_checked');
			$(this).removeClass('payment_checked_mobile');
			$(this).attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			$('.termsPaymentPopup').show();

		}else if($(this).hasClass('s2_mobile')){
			if(payment_method == "payment_checkout" || payment_method == "payment_epay" ||  payment_method == "payment_epay_cards" ||
				payment_method == "payment_easy_pay_office" || payment_method == "payment_bank_transfer"){
				if(payment_method == "payment_bank_transfer"){
					$('#bankCashTransfer').find('.s1').hide();
					$('#bankCashTransfer').find('.s2').show();
					$(this).removeClass('s2_mobile').addClass('s3_mobile');
					$('.payment_steps_bank .step-1').removeClass('activeStep').addClass('finishedStep').find('.payment_step_number span').addClass('fa fa-check').html('');
					$('.payment_steps_bank .step-2').addClass('activeStep');
				}else{
					$('.paymentsCards').find('.s1').hide();
					$('.paymentsCards').find('.s2').show();
					$('.payment_steps_cards .step-1').removeClass('activeStep').addClass('finishedStep').find('.payment_step_number span').addClass('fa fa-check').html('');
					$('.payment_steps_cards .step-2').addClass('activeStep');
				}
			}else{
				$('.econtDataPayment').find('.s1').hide();
				$('.econtDataPayment').find('.s2').show();
				$(this).removeClass('s2_mobile').addClass('s3_mobile');
				$('.payment_steps_econt .step-1').removeClass('activeStep').addClass('finishedStep').find('.payment_step_number span').addClass('fa fa-check').html('');
				$('.payment_steps_econt .step-2').addClass('activeStep');
			}
		}else if($(this).hasClass('s3_mobile')){
			if(payment_method == "payment_bank_transfer"){
				$('#bankCashTransfer').find('.s2').hide();
				$('#bankCashTransfer').find('.s3').show();
				$(this).removeClass('s3_mobile');
				$('.payment_steps_bank .step-2').removeClass('activeStep').addClass('finishedStep').find('.payment_step_number span').addClass('fa fa-check').html('');
				$('.payment_steps_bank .step-3').addClass('activeStep');
			}else if(payment_method == "payment_econt"){
				$('.econtDataPayment').find('.s2').hide();
				$('.econtDataPayment').find('.s3').show();
				$('#modalStartTrial .econt_delivery').hide();
				$(this).removeClass('s3_mobile').addClass('s4_mobile');
				$('.continuePlan ').css({position:'absolute',bottom:'0'});
				$('.continuePlan').attr('style','position:absolute;bottom:0;left:50%;-ms-transform:translateX(-50%);-webkit-transform:translateX(-50%);transform:translateX(-50%)');
				/* $('#modalStartTrial').css({height:'100%'});*/
				$('.payment_steps_econt .step-2').removeClass('activeStep').addClass('finishedStep').find('.payment_step_number span').addClass('fa fa-check').html('');
				$('.payment_steps_econt .step-3').addClass('activeStep');
			}
		}else if($(this).hasClass('s4_mobile')){
			$('.econtDataPayment').find('.s3').hide();
			$('.econtDataPayment').find('.s4').show();
			$(this).removeClass('s4_mobile');
			$('.payment_steps_econt .step-3').removeClass('activeStep').addClass('finishedStep').find('.payment_step_number span').addClass('fa fa-check').html('');
			$('.payment_steps_econt .step-4').addClass('activeStep');
		}else if($(this).hasClass('s2_econt')){
			$('.econt-delivery').show();
			$('.econt-person-details').show();
			$('.econtRadioBtns').hide();
			$('.econtDataPayment').find('.s2').hide();
			$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
			$(this).removeClass('s2_econt');
		}else if($(this).hasClass('payment_middle')){
			if(($(this).hasClass('payment_easy_pay_office') || $(this).hasClass('payment_econt') || $(this).hasClass('payment_bank_transfer'))){
				//check email length && typeof $('#emailPayFromHome').val() != "undefined" && $('#emailPayFromHome').val().length > 3





				$('#modalStartTrial').css({position:'relative',borderRadius:'0'});
				$('#modalStartTrial').css({});
				$('#middleStepFeature').removeClass('inactive').attr('style','position:relative;top:auto;left:auto;transform:none;-webkit-transform:none;-ms-transform:none;');
				$('#modalStartTrial').attr('style','width:100%;height:100%;max-height:100%;top:0;left:0;-ms-transform:none;-webkit-transform:none;transform:none;border-radius:1px;z-index:16000002;background:url('+baseUrl+'/images/vclassroom.jpg);background-repeat:no-repeat;background-size:cover;');
				$('.special-offer-overlay').removeClass('inactive');
				$('.secondStepPremium').addClass('inactive');
				$('.termsPaymentPopup').hide();
				$('#global_steps_pay').hide();
				$(this).removeClass('payment_middle').removeClass('payment_checked').hide();
				$(this).attr('style','position:absolute;bottom:0;left:50%;-ms-transform:translateX(-50%);-webkit-transform:translateX(-50$);transform:translateX(-50%); cursor: auto;background: #999 !important;box-shadow: none;display:none;');
				$('.closeModalTrial').hide();
				document.body.style.overflow = 'hidden';


				if(is_user != ""){
					/*$(this).append('<span class="fa fa-spin6 animate-spin"></span>');*/

				}

			}
		}else if($(this).hasClass('payment_last_step')){

			//click function last Step
			if($(this).hasClass('payment_bank_transfer')){
				postCashBank();
			}else if($(this).hasClass('payment_econt')){
				// econt();
				postDataEcont();

			}else{
				postData();
			}
			$(this).append('<span style="" class="fa fa-spin6 animate-spin"></span>');
		}
	}


});
/**
 * Get econt offices and city
 * @returns {undefined}
 */
function getOffices(){
	$.get(baseUrl+"/econt/offices", function( data ) {
		if(!mobile){
			/* renderOfficesMobile(data);*/
			renderOffices(data);
		}else{
			renderOfficesMobile(data);
		}

	});
	console.log("officess")
}

function renderOffices(data){

	for(var key in data){
		$('#econt-towns').append('<option value="'+data[key][0]['city_name']+
			'" id="'+key+'" class="select_econt_town ">'+data[key][0]['city_name']+'</option>');
		$('#select-econt-office').append('<div id="city_'+key+'" class="econt-towns-offices inactive"></div>');
		for(var j in data[key]){
			$('#city_'+key).
			append('<label class="select-label-econt single-office-econt tileShadowPage tileShadowPageHover">'+
				'<div class="leftOfficeRadio">'+
				'<input class="econt-radio" type="radio" name="econt-offices" value="'+data[key][j]["name"]+"**"+data[key][j]["address"]+'" onchange="checkFillment()">'+
				'</div>'+
				'<div class="rightOfficeRadio">'+
				data[key][j]["name"]+" - "+data[key][j]["address"]+
				'</div>'+
				'</label>');
		}
	}

	$('#city_0').removeClass("inactive");
}
function renderOfficesMobile(data){
	$('#select-econt-office-mobile').removeClass('inactive');
	for(var key in data){
		$('#econt-towns').append('<option value="'+data[key][0]['city_name']+
			'" id="'+key+'" class="select_econt_town ">'+data[key][0]['city_name']+'</option>');
		/*$('#select-econt-office').append('<select><option id="city_'+key+'" class="econt-towns-offices inactive"></option></select>');*/
		$('#select-econt-office-mobile').append('<select id="city_'+key+'" class="econt-offices-select inactive"><option>Изберете офис</option></select>');
		for(var j in data[key]){
			/*$('#city_'+key).
                   append('<label class="select-label-econt single-office-econt tileShadowPage tileShadowPageHover">'+
                '<div class="leftOfficeRadio">'+
                '<input class="econt-radio" type="radio" name="econt-offices" value="'+data[key][j]["name"]+"**"+data[key][j]["address"]+'">'+
                '</div>'+
                '<div class="rightOfficeRadio">'+
                data[key][j]["name"]+" - "+data[key][j]["address"]+
                '</div>'+
            '</label>');*/
			$('#city_'+key).append('<option value="'+data[key][j]["name"]+"**"+data[key][j]["address"]+'">'+data[key][j]["name"]+" - "+data[key][j]["address"]+'</option>');

		}
	}

	$('#city_0').removeClass("inactive");
}

var count = 1;
var countPrev = 1;
var slideTime = 15000;
var firstGif = $('#headerSlideGif1').attr('src');
var secondGif = $('#headerSlideGif2').attr('src');
var thirdGif = $('#headerSlideGif3').attr('src');
var fourthGif = $('.home_devices-screen-new').attr('src');
$('#nextSlide').click(function(){
	nextSlide();
});
$('#prevSlide').click(function(){
	prevSlide();
});
function nextSlide(){
	$('.next_arrow').css({right:'5%'});
	$('.prev_arrow').css({left:'5%'});
	$('.overlay-headerStrip').addClass('inactive');
	var slide = $('.slide.activeSlide');
	var nextSlide = slide.next();
	if(count == $('.slide').length){
		nextSlide = $('.slide').first();
	}
	var gif = $(nextSlide).find('.img_slide');
	if(gif != undefined){
		var gif_url = gif.css('background-image');
		gif_url = gif_url.replace('url(','').replace(')','').replace(/\"/gi, "");
	}
	slide.animate({left:"-100%"},function(){
		slide.removeClass('activeSlide');
		slide.addClass('inactiveSlide');
		slide.find('img').not('.home_devices-screen-new').removeAttr('src');
		nextSlide.removeClass('inactiveSlide').animate({left:'0'}).addClass('activeSlide');
		gif.css('background-image','url('+gif_url+'?'+Math.random()+')');
	});



	var dot = $('.carousel-nav .dot.activeDot');
	var nextDot = dot.next();
	dot.removeClass('activeDot');
	nextDot.addClass('activeDot');
	if(count == $('.slide').length){
		$('.slide').removeClass('activeSlide').addClass('inactiveSlide').css({left:'0'});
		$('.slide').first().removeClass('inactiveSlide').addClass('activeSlide');
		/*$('.slide').first().next().find('img').attr('src',secondGif);
        $('.slide').first().next().next().find('img').attr('src',thirdGif);*/
		$('.carousel-nav .dot').removeClass('activeDot');
		$('.carousel-nav .dot').first().addClass('activeDot');
		count = 1;
		/*waitSlide(13000);*/
	}else{
		count++;
	}
	if(count == 2){
		resetFirstGif(2);
	}else if(count == 3){
		resetFirstGif(3);
	}/*else if(count == 4){
        waitSlide(15000);
    }*/
}
function prevSlide(){
	$('.overlay-headerStrip').addClass('inactive');
	var slide = $('.slide.activeSlide');

	var prevSlide = slide.prev();
	if(count == 1){
		prevSlide = $('.slide').last();

	}
	var gif = $(prevSlide).find('.img_slide');
	if(gif != undefined){
		var gif_url = gif.css('background-image');
		gif_url = gif_url.replace('url(','').replace(')','').replace(/\"/gi, "");
	}

	slide.animate({right:"-100%"},function(){
		slide.removeClass('activeSlide');
		slide.addClass('inactiveSlide');
		slide.find('img').not('.home_devices-screen-new').removeAttr('src');
		/*slide.removeAttr('style');*/
		prevSlide.removeClass('inactiveSlide').animate({left:'0'}).addClass('activeSlide');
		gif.css('background-image','url('+gif_url+'?'+Math.random()+')');
	});
	/*slide.removeClass('activeSlide').animate({left:"-100%"}).addClass('inactiveSlide').find('img').not('.home_devices-screen-new').removeAttr('src');
    prevSlide.removeClass('inactiveSlide').animate({left:'0'}).addClass('activeSlide');*/


	var dot = $('.carousel-nav .dot.activeDot');
	var prevDot = dot.prev();
	dot.removeClass('activeDot');
	prevDot.addClass('activeDot');
	if(count == 1){
		$('.slide').removeClass('activeSlide').addClass('inactiveSlide').css({left:'0'});
		$('.slide').last().removeClass('inactiveSlide').addClass('activeSlide');
		/*$('.slide').last().prev().find('img').attr('src',thirdGif);
        $('.slide').last().prev().prev().find('img').attr('src',secondGif);
        $('.slide').last().prev().prev().prev().find('img').attr('src',firstGif);*/
		$('.carousel-nav .dot').removeClass('activeDot');
		$('.carousel-nav .dot').last().addClass('activeDot');
		count = 4;
		/*waitSlide(13000);*/

	}else{
		resetFirstGif(1);
		count--;
		$('.slide').first().find('img').attr('src',firstGif);
		$('.slide').first().next().find('img').attr('src',secondGif);
		$('.slide').first().next().next().find('img').attr('src',thirdGif);
	}
	if(count == 2){
		resetFirstGif(2);
	}else if(count == 3){
		resetFirstGif(3);
	}/*else if(count == 4){
        waitSlide(15000);
    }*/
}
/*waitSlide(13000);
function waitSlide(time){
    setTimeout(function(){
        nextSlide();
    },time);
}*/
if(count == 1){
	resetFirstGif(1);
}
if(count == 2){

}
function resetFirstGif(gif){
	if(gif == 1){
		setTimeout(function(){
			$('.overlay-headerStrip').removeClass('inactive');
			$('.next_arrow').css({right:'40%',color:'#ffb000'});
			for(i=0;i<3;i++) {

				$('.next_arrow').fadeTo('slow', 0.1).fadeTo('slow', 1.0);
				if(i == 3){


				}

			}
			setTimeout(function(){
				$('.next_arrow').css({color:'black'});
			},3000);
			$('.prev_arrow').css({left:'40%'});
		},20000);
	}else if(gif == 2){
		setTimeout(function(){
			$('.overlay-headerStrip').removeClass('inactive');
			for(i=0;i<3;i++) {
				$('.next_arrow').css({right:'40%',color:'ffb000'});
				$('.next_arrow').fadeTo('slow', 0.1).fadeTo('slow', 1.0);
			}
			setTimeout(function(){
				$('.next_arrow').css({color:'black'});
			},3000);
			$('.prev_arrow').css({left:'40%'});
		},30000);
	}else if(gif == 3){
		setTimeout(function(){
			$('.overlay-headerStrip').removeClass('inactive');
			for(i=0;i<3;i++) {
				$('.next_arrow').css({right:'40%',color:'ffb000'});
				$('.next_arrow').fadeTo('slow', 0.1).fadeTo('slow', 1.0);
			}
			setTimeout(function(){
				$('.next_arrow').css({color:'black'});
			},3000);
			$('.prev_arrow').css({left:'40%'});
		},30000);
	}else if(gif == 4){
		setTimeout(function(){
			$('.overlay-headerStrip').removeClass('inactive');
			for(i=0;i<3;i++) {
				$('.next_arrow').css({right:'40%',color:'ffb000'});
				$('.next_arrow').fadeTo('slow', 0.1).fadeTo('slow', 1.0);
			}
			setTimeout(function(){
				$('.next_arrow').css({color:'black'});
			},3000);
			$('.prev_arrow').css({left:'40%'});
		},10000);
	}

}
function resetGif(slide){
	var gif = $(slide).find('.img_slide');
	if(gif != undefined){
		var gif_url = gif.css('background-image');
		gif_url = gif_url.replace('url(','').replace(')','').replace(/\"/gi, "");
	}
	slide.find('img').not('.home_devices-screen-new').removeAttr('src');
	gif.css('background-image','url('+gif_url+'?'+Math.random()+')');
}
$('.repeatGif').click(function(){
	if(count == 1){
		var src = $('#headerSlide1').css('background-image');
		src = src.replace('url(','').replace(')','').replace(/\"/gi, "");

		$('#headerSlide1').css('background-image', 'none');
		$('#headerSlide1').css('background-image', 'url('+src+'?'+Math.random()+')');
		$('.overlay-headerStrip').addClass('inactive');
		resetFirstGif(1);

	}else if(count == 2){
		var src = $('#headerSlide2').css('background-image');
		src = src.replace('url(','').replace(')','').replace(/\"/gi, "");

		$('#headerSlide2').css('background-image', 'none');
		$('#headerSlide2').css('background-image', 'url('+src+'?'+Math.random()+')');
		$('.overlay-headerStrip').addClass('inactive');
		resetFirstGif(2);

	}
});
$('.dot').click(function(){
	var id = $(this).attr('id');
	$('.slide').addClass('inactiveSlide');
	$('.slide').removeClass('activeSlide');
	$('.dot').removeClass('activeDot');
	$(this).addClass('activeDot');
	$('.slide').find('img').not('.home_devices-screen-new').removeAttr('src');
	if(id == 'sl1'){
		$('.overlay-headerStrip').addClass('inactive');
		resetGif( $('#headerSlide1').parent());
		resetFirstGif(1);
		$('#headerSlide1').parent().removeClass('inactiveSlide').addClass('activeSlide').css({left:'0'});

	}else if(id == 'sl2'){
		$('.overlay-headerStrip').addClass('inactive');
		resetGif($('#headerSlide2').parent());
		resetFirstGif(2);
		$('#headerSlide2').parent().removeClass('inactiveSlide').addClass('activeSlide').css({left:'0'});

	}else if(id == 'sl3'){
		$('.overlay-headerStrip').addClass('inactive');
		resetGif($('#headerSlideConsole').parent());
		resetFirstGif(3);
		$('#headerSlideConsole').parent().removeClass('inactiveSlide').addClass('activeSlide').css({left:'0'});

	}else if(id == 'sl4'){
		$('.overlay-headerStrip').addClass('inactive');
		$('#headerSlideDevices').addClass('activeSlide').removeClass('inactiveSlide').css({left:'0'});
		resetFirstGif(4);

		/*$('.home_devices-sreen-new').attr('src',fourthGif);*/
	}
});
function getFeaturedPlan(){
	if($('#additional_plan').val() != ""){
		return $('#additional_plan').val();
	}else if(sessionStorage.getItem("additional_plan") != null){
		var plan = sessionStorage.getItem("additional_plan");
		return plan;
	}
	return "";
}
function econt(person_name){
	var city = $('#econt-towns').find(':selected').val();
	var to_where = $('input[name=delivery_type]:checked').val();
	var office = $('input[name=econt-offices]:checked').val();
	var name = person_name;
	var city_address = $('#city_address').val();
	var phone = $('#phone').val();
	var address = $('#address_person').val();
	var half_plan = sessionStorage.getItem('select-half-plan');
	var only_feature = sessionStorage.getItem('select-feature-plan');

	/**
	 *
	 */
	var feature_plan= getFeaturedPlan();
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	$.post(baseUrl+"/econt/put/cash-delivery",
		{
			city: city,
			type: to_where,
			office: office,
			address: address,
			phone: phone,
			city_address: city_address,
			name: name,
			half_plan:half_plan,
			feature_plan:feature_plan,
			only_feature:only_feature
		} )
		.done(function( data ) {
			if(data == "OK"){
				localStorage.setItem("reminders_denied",true);
				localStorage.removeItem("true");
				/*$('.thirdStepPremium').children().hide();*/
				/*$('.continuePlan ').removeAttr("style").remove( "span" );
                $('.choosePlan').hide();
                $('.continuePlan').hide();
                $('.paymentsCards').addClass('inactive');
                $('#bankCashTransfer').addClass('inactive');
                $('.econtDataPayment').children().hide();*/
				$('.payment-third-step').addClass('inactive');
				$('#payment-third-step-econt').addClass('inactive');
				/*$('.thirdStepPremium').append("<h3>Получихме Вашата заявка, очаквайте куриер да се свържи с Вас, за да направите плащане!</h3>")*/
				/*$('.econtDataPayment').append("<h3>Получихме Вашата заявка, очаквайте куриер да се свържи с Вас, за да направите плащане!</h3>");*/

				window.location.href=baseUrl+"/bill/success-payment/econt";
			}else{
				$('.econt_delivery').after("<h2 style='color:red;'>"+data+"</h2>");
			}
		});

}
function postDataEcont(){
	var info = getEcontUserData();
	var person_name = info.person_name;
	var person_type_id = info.person_type_id;
	var person_id = info.person_id;
	var address = info.address;
	var company_name = info.company_name;
	var eik_dds = info.eik_dds;
	var city = info.city;
	console.log(getEcontUserData());
	$('.continuePlan ').attr("style","cursor: auto;background: #999 !important;box-shadow: none;");
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	$.post(baseUrl+"/bill/post/paymentinfo",
		{
			person_name: person_name,
			person_number: person_id,
			number_type: person_type_id,
			address: address,
			company_name: company_name,
			eik_dds: info.eik_dds,
			payment_method:payment_method,
			city:city
		} )
		.done(function( data ) {

			if(data.search("OK") >= 0 ){
				//showMethods();

				$.post(baseUrl+"/front/payment",{method:payment_method}, function( data ) {
					if(data == "econt"){
						econt(person_name);
					}else{
						$('body').append(data);
					}


				});

			}else{
				$('.err-msg').html(data);
			}

		});
}
function postData(){
	var info = getPersonInfo();
	var person_name = info.person_name;
	var person_type_id = info.person_type_id;
	var person_id = info.person_id;
	var address = info.address;
	var company_name = info.company_name;
	var eik_dds = info.eik_dds;
	var half_plan = sessionStorage.getItem('select-half-plan');
	var only_feature = sessionStorage.getItem('select-feature-plan');
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	$.post(baseUrl+"/bill/post/paymentinfo",
		{
			person_name: person_name,
			person_number: person_id,
			number_type: person_type_id,
			address: address,
			company_name: company_name,
			eik_dds: info.eik_dds,

		} )
		.done(function( data ) {

			if(data.search("OK") >= 0 ){
				localStorage.setItem("reminders_denied",true);
				localStorage.removeItem("true");
				$.post(baseUrl+"/front/payment",{method:payment_method,half_plan:half_plan,only_feature:only_feature}, function( data ) {
					if(data == "easypay"){
						epayIDN(half_plan);
					}else if(data == "card" || data == "account"){
						epay(data,half_plan);
					}else{
						$('body').append(data);
					}


				});

			}else{

				$('.err-msg').html(data);
			}

		});
}


function getData(){
	var person_name = $('#easy-pay-fullname').val();
	var person_type_id = $('#selectTypeData').val();
	var person_id = null;
	if(person_type_id == "VAT" || person_type_id == "BULSTAT"){
		person_id = $('#BULSTAT').val();
	}else{
		person_id = $('#'+person_type_id).val();
	}

	var adress = $('#address').val();
	var company_name = $('#company_name').val();
	return {
		person_name: $('#easy-pay-fullname').val(),
		person_type_id: $('#selectTypeData').val(),
		person_id: person_id,
		address: $('#address').val(),
		company_name: $('#company_name').val(),
		eik_dds: $('#eik_dds').val()
	};
}
var is_reg = true;
function choosePaymentMethodAndRegisterUser(callback){
	if(!is_user){
		/*if user is not register*/

		if($('#emailPayFromHome').val().length != 0){
			is_reg = true;
		}else if($('#emailLoginPayFromHome').val().length != 0 && $('#passwordPayFromHome').val().length != 0){
			is_reg = false;
		}
		if(is_reg){
			$('#user-email').prop("value", $('#emailPayFromHome').val());
			$('#user-method').prop("value", getPaymentMethod());
			$('#choosePaymentMethodAndRegisterUser').submit();
			$('.continuePlan').append('<span class="fa fa-spin6 animate-spin"></span>');
		}else{
			$('#login-email').prop("value", $('#emailLoginPayFromHome').val());
			$('#login-password').prop("value", $('#passwordPayFromHome').val());
			$('#login-method').prop("value", getPaymentMethod());
			$('#choosePaymentMethodAndLoginUser').submit();
			$('.continuePlan').append('<span class="fa fa-spin6 animate-spin"></span>');
		}
	}else{

		payment_method = getPaymentMethod();

		continuePaymentsData();
		$('.continuePlan').removeClass('payment_checked');

	}
}

function getPaymentMethod(){
	if($('.continuePlan ').hasClass('payment_epay_cards')){

		return "payment_epay_cards";
	}else if($('.continuePlan').hasClass('payment_checkout')){

		return "payment_checkout";
	}else if($('.continuePlan').hasClass('payment_epay')){

		return "payment_epay";
	}else if($('.continuePlan').hasClass('payment_easy_pay_office')){

		return "payment_easy_pay_office";
	}else if($('.continuePlan').hasClass('payment_bank_transfer')){

		return "payment_bank_transfer";
	}else if($('.continuePlan').hasClass('payment_econt')){
		getOffices();
		return "payment_econt";
	}
}
function epay(type,is_half_plan){
	$('#epayPaid').prop('action',baseUrl+"/bill/micro");
	if(typeof is_half_plan != "undefined" && is_half_plan == "true"){
		$('#is_half_plan').prop('value',true);
	}
	if(type == "account"){
		$('#epayPaid').prop('action',baseUrl+"/bill/micro");
		$('#epay-type').prop('value',type);
	}else if(type == "card"){
		$('#epay-type').prop('value',type);
	}
	if(sessionStorage.getItem('select-feature-plan') != null){
		if(sessionStorage.getItem('select-feature-plan') == 'select-feature-plan-2'){
			$('#epayPaid').find('#plan').prop('value', "abd62745-z211-11f6-bf79-062c03d004e1");
		}else if(sessionStorage.getItem('select-feature-plan') == 'select-feature-plan-1'){
			$('#epayPaid').find('#plan').prop('value', "abd62745-e211-11f6-bf79-062c03d014e1");
		}
	}else{
		$('#epayPaid').find('#plan').prop('value', "0573ac75-aa72-11e6-ae30-001e4ffa047a");
	}
	$('#epayPaid').submit();
}
function epayIDN(half_plan){
	if(typeof half_plan == "undefined"){
		half_plan = false;
	}
	/**
	 *todo
	 */
	var feature_plan = getFeaturedPlan();
	var only_feature = sessionStorage.getItem("select-feature-plan");
	var rate_id;
	if(only_feature == "select-feature-plan-1"){
		rate_id = 'abd62745-e211-11f6-bf79-062c03d014e1';
	}else if(only_feature == "select-feature-plan-2"){
		rate_id = 'abd62745-z211-11f6-bf79-062c03d004e1';
	}else{
		rate_id = '0573ac75-aa72-11e6-ae30-001e4ffa047a';
	}
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	$.post(baseUrl+"/bill/epay/invoice",
		{rate_id: rate_id,
			is_half_plan:half_plan,
			feature_plan:feature_plan
		} )
		.done(function( data ) {
			console.log(data);
			if(data.search("ERR:") == 0){
				//$('#easy-pay-form').find('h4').html(data.split("ERR:")[1]);

			}else{
				console.log(data);
				$('#epayPaid').prop('action',baseUrl+"/bill/easypay");
				$('#p-data').prop('value',data);
				$('#epayPaid').submit();
			}


		});
}
function setHalfPlan(){

	playNowTarrif();
	sessionStorage.setItem('select-half-plan',true);
}
$('.closeModalTrial').click(function(){
	closeModalTrial();
});
/*var boxStructureJunior = $(window).width() - $('.exerciseJuniorBox').offset().left;
console.log(boxStructureJunior);
var leftSpace = $('.juniorCourseTree .exerciseJuniorBox:nth-child(3n+4) .fa-angle-down').position().left;
var rightSpace = $('.juniorCourseTree .exerciseJuniorBox:nth-child(3n+3) .fa-angle-down').position().left + $('.juniorCourseTree .exerciseJuniorBox:nth-child(3n+3) .fa-angle-down').width();


if(mobile){

    $('.arrowExLong').css({width:($('.exBoxes').width()-leftSpace)-1*rightSpace});
}else{
    if($(window).width()==1024){
        $('.arrowExLong').css({width:($('.exBoxes').width()-leftSpace)-2*rightSpace});
    }
    $('.arrowExLong').css({width:($('.exBoxes').width()-leftSpace)-1.5*rightSpace});
}*/

function openPath(self){
	if($(self).next().is(':visible')){
		$('.sub_section').hide();
		//$(self).find('.subPathsAngle span').removeClass('fa-angle-down').addClass('fa-angle-up');
	}else{
		if($(self).find('.subPathsAngle span').hasClass('fa-angle-down')){
			//$(self).find('.subPathsAngle span').removeClass('fa-angle-up').addClass('fa-angle-down');
		}else{
			//$(self).find('.subPathsAngle span').removeClass('fa-angle-down').addClass('fa-angle-up');
		}
		$('.sub_section').hide();
		$(self).next().toggle();
	}
	if($(self).next().is(':visible')){
		$('.subPathsAngle span').addClass('fa-angle-down');
		$(self).find('.subPathsAngle span').removeClass('fa-angle-down').addClass('fa-angle-up');
	}else{
		$('.subPathsAngle span').addClass('fa-angle-up');
		$(self).find('.subPathsAngle span').removeClass('fa-angle-up').addClass('fa-angle-down');
	}
}
function openSubCourse(self){
	if($(self).next().is(':visible')){
		console.log('a');
		$(self).next().slideUp();

		$(self).find('.sub_courses_acc').removeClass('fa-minus').addClass('fa-plus');
		$(self).css({backgroundColor:'#fff'});

	}else{
		$(self).next().slideDown(function(){$(this).attr('style', 'display:flex;display:-ms-flexbox;display:-webkit-flex;border-left:1px solid #eee;border-right:1px solid #eee;border-bottom:1px solid #eee;padding-top:10px');});

		$(self).find('.sub_courses_acc').removeClass('fa-plus').addClass('fa-minus');
		$(self).css({backgroundColor:'#eee'});
	}
	$(this).find(".hidden").toggleClass('openCourses');
}
function toSubPath(self){
	var cur = $(self).children().text();
	var titles = $('.path_sub_title');
	for(var i=0;i<titles.length;i++){
		if($(titles[i]).text() === cur){
			scrollToElementPaths($(titles[i]));
			openPath($(titles[i]).parent().parent().parent());
		}
	}

}
function toEtaps(self){
	var cur = $(self).children().text().trim();
	var titles = $('.level_title');

	for(var i=0;i<titles.length;i++){
		if($(titles[i]).text().trim() === cur){
			scrollToElement($(titles[i]));

		}
	}
}

function scrollToElement(ele) {

	/*$(window).scrollTop(ele.offset().top - $('#subPathsNavWrapperCourses').offset().top);*/
	var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	/*$('#subPathsNavWrapperCourses').css({top:(scrollTop/10)/2+10 + '%'});*/
	$('.courseProfileList').not($(this).parent().parent().next()).slideUp();
	$(ele).parent().parent().next().slideDown();

	setTimeout(function(){
		$("html, body").animate({
			scrollTop:ele.offset().top - 250
		})

	},300);

}
function scrollToElementPaths(ele) {

	/*$(window).scrollTop(ele.offset().top - $('#subPathsNavWrapperCourses').offset().top);*/
	var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	/*$('#subPathsNavWrapperCourses').css({top:(scrollTop/10)/2+10 + '%'});*/
	$(ele).parent().parent().next().slideDown();

	setTimeout(function(){
		$(window).scrollTop(ele.offset().top - 250);
	},500);

}
function paymentsCheck(self){
	/*$('.paymentBtns .payments-check').css({background:'#fff'})
    $('.paymentBtns .payments-check').removeClass('fa-check');
    $(self).append('<span class="payments-check fa fa-check"></span>');*/
	$('.paymentBtns').css({border:'1px solid #ccc'});
	$(self).css({border:'1px solid #099'});
	$('.continuePlan').removeClass('payment_checkout payment_epay_cards payment_epay payment_easy_pay_office payment_econt payment_bank_transfer');
	var method = $(self).attr('id');
	$('.continuePlan').removeAttr('style');
	if(mobile){
		if(is_user != ""){
			$('.continuePlan').addClass('payment_checked payment_' + $(self).attr('id'));
		}else{
			$('.continuePlan').addClass('payment_checked_mobile payment_' + $(self).attr('id'));
		}
	}else{
		if(method == "easy_pay_office" || method == "econt" || method == "bank_transfer"){
			if(sessionStorage.getItem('select-feature-plan') == null){
				$('.continuePlan').removeClass('payment_checked').addClass('payment_checked payment_' + $(self).attr('id'));

			}else{
				$('.continuePlan').removeClass('payment_middle').addClass('payment_checked payment_' + $(self).attr('id'));
			}

		}else{
			$('.continuePlan').removeClass('payment_middle').addClass('payment_checked payment_' + $(self).attr('id'));
		}
	}

}
function continuePaymentsData(){

	setTimeout(function(){
		localStorage.setItem("payment_method",getPaymentMethod());
		localStorage.setItem("step_payment","last_step");
		localStorage.setItem("customer_service","payment");
	},1000);

	$('.termsPaymentPopup').addClass('inactive');
	$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;');
	$('.secondStepPremium').addClass('inactive');
	$('.thirdStepPremium').removeClass('inactive');
	$('.choosePlan').addClass('inactive');
	if($('.continuePlan').hasClass('payment_checkout') || $('.continuePlan').hasClass('payment_epay')||$('.continuePlan').hasClass('payment_epay_cards')||$('.continuePlan').hasClass('payment_easy_pay_office') ||$('.continuePlan').hasClass('payment_bank_transfer') ){
		$('.cardDataPayment').removeClass('inactive');
		$('#bankCashTransfer').addClass('inactive');
		$('.paymentsCards').addClass('inactive');
		$('.econtDataPayment').addClass('inactive');
		if($('.continuePlan').hasClass('payment_bank_transfer')){
			$('.choosePlan').addClass('inactive');
			$('#bankCashTransfer').removeClass('inactive');
		}else{
			$('.paymentsCards').removeClass('inactive');
		}
		if($('.continuePlan').hasClass('payment_checkout')){
			getTodayUsd();
		}
		/*$('.continuePlan').addClass('payment_last_step');*/

		$('.continuePlan').addClass('payment_last_step');
	}else if($('.continuePlan').hasClass('payment_econt')) {
		$('.econtDataPayment').removeClass('inactive');
		$('#payment-third-step').addClass('inactive');
		$('#payment-third-step-econt').removeClass('inactive');
		$('.continuePlan').addClass('payment_last_step');
		if($(window).width()<=1366){
			$('.continuePlan').removeAttr('style').addClass('s2_econt');
		}


	}

	if(mobile){
		if($('.continuePlan').hasClass('payment_checkout') || $('.continuePlan').hasClass('payment_epay')||$('.continuePlan').hasClass('payment_epay_cards')||$('.continuePlan').hasClass('payment_easy_pay_office') ||$('.continuePlan').hasClass('payment_bank_transfer') ){
			$('.cardDataPayment').removeClass('inactive');
			if($('.continuePlan').hasClass('payment_bank_transfer')){
				$('.continuePlan').removeAttr('style').addClass('s2_mobile');
				$('.choosePlan').addClass('inactive');
				$('#bankCashTransfer').removeClass('inactive');
				$('#bankCashTransfer').find('.s2').hide();
				$('#bankCashTransfer').find('.s3').hide();
			}else{
				$('.continuePlan').removeAttr('style').addClass('s2_mobile');
				$('.paymentsCards').removeClass('inactive');
				$('.paymentsCards').find('.s2').hide();
			}
			$('.continuePlan').addClass('payment_last_step');
		}else if($('.continuePlan').hasClass('payment_econt')) {
			$('.econtDataPayment').removeClass('inactive');
			$('#payment-third-step').addClass('inactive');
			$('#payment-third-step-econt').removeClass('inactive');
			$('.continuePlan').addClass('payment_last_step');
			$('.econtDataPayment').find('.s2').hide();
			$('.econtDataPayment').find('.s3').hide();
			$('.econtDataPayment').find('.s4').hide();
			$('.continuePlan').removeAttr('style').addClass('s2_mobile');
		}
	}

}
$("input[name=select-type]:radio").change(function () {
	var type = $('input[name=select-type]').filter(":checked").val();
	$('#selectTypeData').val(type);
});
$("input[name=select-type-econt]:radio").change(function () {
	var type = $('input[name=select-type-econt]').filter(":checked").val();
	$('#econt_selectTypeData').val(type);
});
$("input[name=select-type-bank]:radio").change(function () {
	var type = $('input[name=select-type-bank]').filter(":checked").val();
	$('#selectTypeDataBank').val(type);
});
function getEcontUserData(){
	var person_type_id = $('#econt_selectTypeData').val();
	var person_name = "";
	var person_id = "";
	var address = "";
	var company_name = "";
	var eik_dds = "";
	var city = $('#econt-towns').find(':selected').val();
	if(person_type_id == "EGN"){
		person_name = $('#econt_fullname').val();
		person_id = $('#econt_EGN').val();
	}else if(person_type_id == "LNC"){
		person_name = $('#econt_company_fullname').val();
		person_id = $('#econt_LNC').val();
	}else if(person_type_id == "BULSTAT"){
		person_name = $('#econt_company_fullname').val();
		person_id = $('#econt_BULSTAT').val();
	}else if(person_type_id == "VAT"){
		person_name = $('#econt_company_fullname').val();
		person_id = $('#econt_BULSTAT').val();
	}

	return {
		person_name: person_name,
		person_type_id: $('#econt_selectTypeData').val(),
		person_id: person_id,
		address: $('#address_person').val(),//$('#econt_company_address').val()
		company_name: $('#econt_company_name').val(),
		eik_dds: $('#eik_dds_econt').val(),
		city:city
	};
}
function selectPaymentMethod(){
	/*$('#select-type-payment').hide();*/
	$('#easy-pay-form').show();
	$('#back-type-btn').removeClass('inactive');

	/*select correct fields*/
	var type = $('#selectTypeData').val();
	/*var type = $('.select-type-payment').val();*/
	if(type == "EGN"){
		$('.company').addClass("inactive");
		$('.lnc').addClass("inactive");
		$('.person').removeClass("inactive");
	}else if(type == "LNC"){
		$('.company').addClass("inactive");
		$('.lnc').removeClass("inactive");
		$('.person').addClass("inactive");
		$('#eik_dds').hide();
		$('.dds_label').hide();
	}else if(type == "BULSTAT"){
		$('.company').removeClass("inactive");
		$('.lnc').addClass("inactive");
		$('.person').addClass("inactive");
		$('#eik_dds').hide();
		$('.dds_label').hide();

		$('#easy-pay-fullname').attr("placeholder","МОЛ");
		$('.name_label').text("МОЛ");
	}else if(type == "VAT"){
		$('.company').removeClass("inactive");
		$('.lnc').addClass("inactive");
		$('.person').addClass("inactive");
		$('#eik_dds').show();
		$('.dds_label').show();
		$('#easy-pay-fullname').attr("placeholder","МОЛ");
		$('.name_label').text("МОЛ");
	}
}

$('.select-type-payment').change(function(){
	var type = $('#selectTypeData').val();
	typePay = type;
	$('.companyPay').addClass('inactive');
	$('.lncPay').addClass('inactive');
	$('.personPay').addClass('inactive');
	$('#easy-pay-fullname').attr("placeholder","Въведете вашите имена");
	$('.name_label').text("Въведете вашите имена");
	$('#eik_dds').addClass('inactive');
	$('#eik_dds_econt').addClass('inactive');
	if(type == "EGN"){
		$('.companyPay').addClass('inactive');
		$('.lncPay').addClass('inactive');
		$('.personPay').removeClass('inactive');
		$('.personPay').addClass("inactive");
	}else if(type == "LNC"){
		$('.companyPay').addClass('inactive');
		$('.lncPay').removeClass('inactive');
		$('.personPay').addClass('inactive');
	}else if(type == "BULSTAT"){
		$('.companyPay').removeClass('inactive');
		$('.lncPay').addClass('inactive');
		$('.personPay').addClass('inactive');
		$('#eik_dds').addClass('inactive');
		$('#easy-pay-fullname').attr("placeholder","МОЛ");
		$('#fullname').attr("placeholder","МОЛ");
		$('.name_label').text("МОЛ");
		$('#eik_dds_econt').addClass('inactive');

	}else if(type == "VAT"){
		$('.companyPay').removeClass('inactive');
		$('.lncPay').addClass('inactive');
		$('.personPay').addClass('inactive');
		$('#easy-pay-fullname').attr("placeholder","МОЛ");
		$('#fullname').attr("placeholder","МОЛ");
		$('.name_label').text("МОЛ");
		$('#eik_dds').removeClass('inactive');
		$('#eik_dds_econt').removeClass('inactive');
	}
});
$('.select-type-econt').change(function(){
	$('#fullname').attr("placeholder","Въведете вашите имена");
	$('.name_label').text("Въведете вашите имена");
	console.log('asdas');
	var type = $('#econt_selectTypeData').val();
	typePayEcont = type;
	if(type == "EGN"){
		$('#company-econt').addClass('inactive');
		$('#personEcont').removeClass('inactive');
		$('.lncPay').addClass('inactive');
		$('#econt-egn').removeClass('inactive');
	}else if(type == "LNC"){
		$('#company-econt').addClass('inactive');
		$('#personEcont').removeClass('inactive');
		$('#econt-egn').addClass('inactive');
		$('.lncPay').removeClass('inactive');
	}else if(type == "BULSTAT"){
		$('#personEcont').addClass('inactive');
		$('#company-econt').removeClass('inactive');
		$('#fullname').attr("placeholder","МОЛ");
		$('.name_label').text("МОЛ");
	}else if(type == "VAT"){
		$('#personEcont').addClass('inactive');
		$('#company-econt').removeClass('inactive');
		$('#fullname').attr("placeholder","МОЛ");
		$('.name_label').text("МОЛ");
		$('#dds').removeClass('inactive');
	}
});
$('.select-type-bank').change(function(){
	$('#fullname_bank').attr("placeholder","Въведете вашите имена");
	$('.name_label').text("Въведете вашите имена");
	var type = $('#selectTypeDataBank').val();
	typePayBank = type;
	if(type == "EGN"){
		$('#company-bank').addClass('inactive');
		$('#personBank').removeClass('inactive');
		$('.lncPay').addClass('inactive');
		$('#bank-egn').addClass('inactive');
	}else if(type == "LNC"){
		$('#company-bank').addClass('inactive');
		$('#personBank').removeClass('inactive');
		$('#bank-egn').addClass('inactive');
		$('.lncPay').removeClass('inactive');
	}else if(type == "BULSTAT"){
		$('#personBank').addClass('inactive');
		$('#company-bank').removeClass('inactive');
		$('#fullname_company_bank').attr("placeholder","Материално отговорно лице");
		$('.name_label').text("МОЛ");
	}else if(type == "VAT"){
		$('#personBank').addClass('inactive');
		$('#company-bank').removeClass('inactive');
		$('#fullname_comapany_bank').attr("placeholder","Материално отговорно лице");
		$('.name_label').text("МОЛ");
		$('#bank_dds').removeClass('inactive');
	}
});
if($('input[name=delivery_type]:checked').val() == "address"){
	$('#econt_address').addClass('inactive');

}
$('.delivery_type').change(function(){
	var type = $(this).val();
	console.log(type);
	if(type == "office"){
		$('.office_delivery').removeClass('inactive');
		$('.address_delivery').addClass('inactive');
		$('#city_address').hide();
	}else if(type == "address"){
		$('.office_delivery').addClass('inactive');
		$('.address_delivery').removeClass('inactive');
		$('#city_address').show();
		$('#address').addClass('inactive');
	}
});

$('#econt-towns').change(function(){
	if(!mobile){
		$('input[name=econt-offices]').attr('checked', false);
		var selectValue = $(this).find(':selected').attr('id');
		console.log(selectValue);
		$('.econt-towns-offices').addClass('inactive');
		$('#city_'+selectValue).removeClass('inactive');
	}else{
		$('input[name=econt-offices]').attr('checked', false);
		var selectValue = $(this).find(':selected').attr('id');
		console.log(selectValue);
		$('.econt-offices-select').addClass('inactive');
		$('#city_'+selectValue).removeClass('inactive');
	}

});
var cash_bank_type = "cash";
$('.cash_bank_invoice').change(function(){
	cash_bank_type = $(this).val();
	checkCashBankTransfer();
});
function checkCashBankTransfer(){
	if(cash_bank_type == "cash"){
		$('#bank_cash_preview #platejno_narejdane').addClass('inactive');
		$('#bank_cash_preview #vnosna_belejka').removeClass('inactive');
		return "cash";
	}else if(cash_bank_type == "bank"){
		$('#bank_cash_preview #platejno_narejdane').removeClass('inactive');
		$('#bank_cash_preview #vnosna_belejka').addClass('inactive');
		return "bank";
	}
}
function getBankUserData(){
	var person_type_id = $('#selectTypeDataBank').val();
	var person_name = "";
	var person_id = "";
	var address = "";
	var company_name = "";
	var eik_dds = "";

	if(person_type_id == "EGN"){
		person_name = $('#fullname_bank').val();
		person_id = $('#egn_bank').val();
		address = $('#address_bank').val();
	}else if(person_type_id == "LNC"){
		person_name = $('#fullname_bank').val();
		person_id = $('#LNC_bank').val();
		address = $('#address_bank').val();
	}else if(person_type_id == "BULSTAT"){
		person_name = $('#fullname_company_bank').val();
		person_id = $('#BULSTAT_bank').val();
		address = $('#address_company_bank').val();
	}else if(person_type_id == "VAT"){
		person_name = $('#fullname_company_bank').val();
		person_id = $('#BULSTAT_bank').val();
		address = $('#address_company_bank').val();
	}
	return {
		person_name: person_name,
		person_type_id: $('#selectTypeDataBank').val(),
		person_id: person_id,
		address: address,
		company_name: $('#company_name_bank').val(),
		eik_dds: $('#eik_dds_bank').val()
	};
}
function postCashBank(){
	// var account_per_year = rates[7];
	var info = getBankUserData();
	var person_name = info.person_name;
	var person_type_id = info.person_type_id;
	var person_id = info.person_id;
	var address = info.address;
	var company_name = info.company_name;
	var eik_dds = info.eik_dds;
	var half_plan = sessionStorage.getItem('select-half-plan');

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	$.post(baseUrl+"/bill/post/paymentinfo",
		{
			person_name: person_name,
			person_number: person_id,
			number_type: person_type_id,
			address: address,
			company_name: company_name,
			eik_dds: eik_dds
		} )
		.done(function( data ) {

			if(data.search("OK") >= 0 ){
				//showMethods();
				/**
				 *todo
				 */
				sessionStorage.removeItem('proforma_get');
				var feature_plan = getFeaturedPlan();
				$('#feature_plan').val(feature_plan);
				$('#select-feature-plan').val(sessionStorage.getItem('select-feature-plan'));
				if(half_plan == "true"){

					$('#is_half_plan-cash').prop('value',true);
					localStorage.setItem("reminders_denied",true);
					localStorage.removeItem("true");
				}
				if(checkCashBankTransfer() == "cash"){
					$('#getPdf').prop('action',baseUrl+"/order/cash");


				}else if(checkCashBankTransfer() == "bank"){
					$('#getPdf').prop('action',baseUrl+"/order/bank");

				}
				/* $('.continuePlan').after("<span id='getpdf' class='button prColBG accColBGHoverSamecloseModalTrial'>Виж документа</span>")
                 $('.continuePlan').hide();
                 $('#getpdf').click(function(){
                      $('#getPdf').submit();
                      closeModalTrial();
                 })*/

				if(checkCashBankTransfer() == "cash"){
					window.location.href = baseUrl+"/bill/success-payment/bank-cash";
				}else if(checkCashBankTransfer() == "bank"){
					window.location.href = baseUrl+"/bill/success-payment/bank";
				}

				/*$('#startTrialOverlay').addClass("inactive");
                 $('#modalStartTrial').addClass("inactive");*/
				/*$.get(baseUrl+"/front/payment/"+payment_method, function( data ) {
                    $('body').append(data);
                });*/

			}else{
				$('.err-msg').html(data);
			}

		});

}
function showLeftMenu(self){

	if($(self).hasClass('close')){
		if($(this).hasClass('navDown')){
			$('.subPathsNavWrapper').animate({left:'0'},'slow').css({left:'-40%',background:'rgba(204, 204, 204, 0.83)'});

		}else{
			$('.subPathsNavWrapper').animate({left:'0'},'slow').css({left:'-40%',background:'rgba(204, 204, 204, 0.83)'});
		}
		$('.arrowMenu').removeClass('fa-sitemap').addClass('fa-angle-left');
		$(self).removeClass('close');

		/*footertotop = ($('#footer').position().top);
        scrolltop2 = $(document).scrollTop()+920;*/
	}else{
		if(!mobile){
			$('.subPathsNavWrapper').animate({left:'-40%'},'slow').css({left:'15px'});
		}else{
			$('.subPathsNavWrapper').animate({left:'-70%'},'slow').css({left:'15px'});
		}
		$('.arrowMenu').removeClass('fa-angle-left').addClass('fa-sitemap');
		$(self).addClass('close');
	}


	/*if(!$('.subPathsNavWrapper').is(":visible")){

    }else{

    }*/


}
$('#kid_level').click(function(){
	console.log("a");
	$('.levelConsolesCourses').hide();
	$('.levelSitesCourses').hide();
	$('.levelBuildGameCourses').hide();
	$('.levelKidCourses').show();
	$('.singleCourseLevel').removeClass('activeCourseLevel');
	$(this).addClass('activeCourseLevel');
});
$('#console_level').click(function(){
	$('.levelConsolesCourses').show();
	$('.levelSitesCourses').hide();
	$('.levelBuildGameCourses').hide();
	$('.levelKidCourses').hide();
	$('.singleCourseLevel').removeClass('activeCourseLevel');
	$(this).addClass('activeCourseLevel');
});
$('#apps_level').click(function(){
	$('.levelConsolesCourses').hide();
	$('.levelSitesCourses').show();
	$('.levelBuildGameCourses').hide();
	$('.levelKidCourses').hide();
	$('.singleCourseLevel').removeClass('activeCourseLevel');
	$(this).addClass('activeCourseLevel');
});

$('#buildgame_level').click(function(){
	$('.levelConsolesCourses').hide();
	$('.levelSitesCourses').hide();
	$('.levelBuildGameCourses').show();
	$('.levelKidCourses').hide();
	$('.singleCourseLevel').removeClass('activeCourseLevel');
	$(this).addClass('activeCourseLevel');
});
$('#kid_paths').click(function(){
	$('.kid_paths').removeClass('inactive');
	$('.console_path').addClass('inactive');
	$('.web_apps_db_path').addClass('inactive');
	$('.builder_path').addClass('inactive');
	$('.singleCourseLevel').removeClass('activeCourseLevel');
	$(this).addClass('activeCourseLevel');
	localStorage.setItem("level1",true);
});
$('#console_path').click(function(){
	$('.kid_paths').addClass('inactive');
	$('.console_path').removeClass('inactive');
	$('.web_apps_db_path').addClass('inactive');
	$('.builder_path').addClass('inactive');
	$('.singleCourseLevel').removeClass('activeCourseLevel');
	$(this).addClass('activeCourseLevel');
	localStorage.setItem("level2",true);
});
$('#builder_path').click(function(){
	$('.kid_paths').addClass('inactive');
	$('.console_path').addClass('inactive');
	$('.web_apps_db_path').addClass('inactive');
	$('.builder_path').removeClass('inactive');
	$('.singleCourseLevel').removeClass('activeCourseLevel');
	$(this).addClass('activeCourseLevel');
	localStorage.setItem("level3",true);
});
$('#web_apps_db_path').click(function(){
	$('.kid_paths').addClass('inactive');
	$('.console_path').addClass('inactive');
	$('.web_apps_db_path').removeClass('inactive');
	$('.builder_path').addClass('inactive');
	$('.singleCourseLevel').removeClass('activeCourseLevel');
	$(this).addClass('activeCourseLevel');
	localStorage.setItem("level4",true);
});
$('.playGif').click(function(){
	console.log("as");
	$(this).parent().css({
		backgroundImage:'url(images/levels/slide1CH.gif)',
		backgroundRepeat:'no-repeat',
		backgroundSize:'123%',
		backgroundPosition:'left center'
	});
	$(this).hide();
	$(this).next().css({display:'none'});
});
$('#playFGif').click(function(){
	console.log("a");

	$('#gif2').css({
		backgroundImage:'url(images/levels/slide2CH.gif)',
		backgroundRepeat:'no-repeat',
		backgroundSize:'157%',
		backgroundPosition:'center center'
	});
	$(this).hide();
	$(this).next().css({display:'none'});
});
$('.playSGif').click(function(){
	console.log("a");

	$(this).parent().css({
		backgroundImage:'url(images/levels/sGame.gif)',
		backgroundRepeat:'no-repeat',
		backgroundSize:'contain',
		backgroundPosition:'center center'
	});
	$(this).hide();
	$(this).next().css({display:'none'});
});
$('.playHTMLGif').click(function(){
	$(this).parent().css({
		backgroundImage:'url(images/levels/html-css.gif)',
		backgroundRepeat:'no-repeat',
		backgroundSize:'contain',
		backgroundPosition:'center center'
	});
	$(this).hide();
	$(this).next().css({display:'none'});
});
function toCoursesPath(id){
	window.location.href = baseUrl+"/courses?courses=all";
	/*if(id == "1"){

        localStorage.setItem("level1",true);
    }else if(id == "2"){
        window.location.href = baseUrl+"/courses";

        localStorage.setItem("level2",true);
    }else if(id == "3"){
        window.location.href = baseUrl+"/courses";
        localStorage.setItem("level3",true);
    }else if(id == "4"){
       window.location.href = baseUrl+"/courses";
        localStorage.setItem("level4",true);
    }*/
}

function onScrollMenu(event){
	/*var scrollPos = $(document).scrollTop();
    $('#subPathsNavWrapperCourses .sub_paths_nav').each(function(){
        var currLink = $(this);
        var refElement = $(currLink.attr("href"));
        if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
7
            $('.subPathsNavWrapperCourses .sub_paths_nav').removeClass("active");
8
            currLink.addClass("activeMenu");
9
        }else{
11
            currLink.removeClass("activeMenu");
12
        }


    });*/

}
/*$('#subPathsNavWrapperCourses a[href^="#"]').on('click', function (e) {
      e.preventDefault();
        $(document).off("scroll");
         $('a').each(function () {
            $(this).removeClass('activeMenu');
        });
        $(this).addClass('activeMenu');
         var target = this.hash,
         menu = target;
         $target = $(target);
       $('html, body').stop().animate({
            'scrollTop': $target.offset().top+2
        }, 600, 'swing', function () {
            window.location.hash = target;
            $(document).on("scroll", onScrollMenu);
        });
    });*/

$(document).on("scroll", onScrollMenu);

$(window).load(function(){
	var username = $('#username').find('span.textColor');
	var user = $(username).text().trim();
	if($('#username').find('.textColor').text().trim().length > 15){
		var substring = user.substr(0,15);
		$(username).html(substring);
	}else{
		var text = $(username).text();
		$(username).html(text);
	}
	if(localStorage.getItem('loader_after_chat') != null){
		/*$('.loader_chat').show();*/
		localStorage.removeItem('loader_after_chat');
	}
});
$(window).resize(function(){
	var username = $('#username').find('span.textColor');
	var user = $(username).text().trim();
	if($('#username').find('.textColor').text().trim().length > 15){
		var substring = user.substr(0,15);
		$(username).html(substring);
	}else{
		var text = $(username).text();
		$(username).html(text);
	}
	if(!mobile){
		/*$('.courseTitleInStructure').css({marginRight:$('.startCourse').outerWidth()});*/
	}

	$('.courseTitle').css({width:$('.percentageDone').width()});

});
$(window).scroll(function () {


// distance from top of footer to top of document
	if(current_page != "login" && current_page != "register"){

		footertotop = 0;
		/*footertotop = ($('#footer').position().top);*/
	}else{
		footertotop = 0;
	}
// distance user has scrolled from top, adjusted to take in height of sidebar (570 pixels inc. padding)
	scrolltop = $(document).scrollTop()+620;
// difference between the two
	difference = scrolltop-footertotop;

	scrolltop2 = $(document).scrollTop()+920;
	difference2 = scrolltop2-footertotop;
// if user has scrolled further than footer,
// pull sidebar up using a negative margin
	if(!mobile){
		if (scrolltop2 > footertotop) {
			/*$('.subPathsNavWrapper').css('margin-top',  0-difference2);
         $('#subPathsNavWrapperHome').css('margin-top',  0-difference2);*/
			if($('.showHideNavigationMenu').hasClass('navDown')){

				//$('#subPathsNavWrapperCourses').css('margin-top',0-difference2).addClass('menuDown');
				$('#subPathsNavWrapperCourses').css('top','48%');

				$('.showHideNavigationMenuHome ').css('margin-top',  0-difference2);
			}else{
				$('#subPathsNavWrapperCourses').css('top','40%');
			}
			if(current_page == "courses"){
				if($('#apps_level').hasClass('activeCourseLevel')){
					if($('#htmlCourses').children().length>0){
						console.log('b');
						$('.coursesLevels').css('margin-top',0-difference2);
					}else{
						$('.coursesLevels').css('margin-top',0-difference2+250);
					}
				}else if($('#buildgame_level').hasClass('activeCourseLevel')){
					if($('#coursesMvc').children().length>0){
						$('.coursesLevels').css('margin-top',0-difference2);
					}else{
						$('.coursesLevels').css('margin-top',0-difference2+300);
					}
				}else{
					$('.coursesLevels').css('margin-top',0-difference2);
				}
				/*$(".coursesLevels").css("top",Math.max(0,500-$(this).scrollTop()));*/
			}

		}else{
			$('#subPathsNavWrapperHome').css('margin-top',  0);
			$('.subPathsNavWrapper').css('margin-top', 0);
			$('.showHideNavigationMenuHome ').css('margin-top',  0);
			//$('#subPathsNavWrapperCourses').css('top','30%');
			if(current_page == "courses"){
				$('.coursesLevels').css('margin-top',0);
			}
		}
		if(current_page == "courses"){
			if(scrolltop>footertotop){
				$('.showHideNavigationMenu').css('margin-top',  0-difference).addClass('navDown');
			}else{
				$('.showHideNavigationMenu').css('margin-top',  0).removeClass('navDown');
			}
		}
		if(current_page != "home" && current_page != 'path' && current_page != 'courses'){
			if (scrolltop > footertotop) {
				$('#subPathsNavWrapperCourses').css('margin-top',0-difference);
				$('.subPathsNavWrapper').css('margin-top',  0-difference);
				$('.showHideNavigationMenu ').css('margin-top',  0-difference);
				$('.showHideNavigationMenuHome ').css('margin-top',  0-difference);

			}

			else  {

				$('.subPathsNavWrapper').css('margin-top', 0);
				$('.showHideNavigationMenu ').css('margin-top', 0);
				$('.showHideNavigationMenuHome ').css('margin-top',  0);
				$('#subPathsNavWrapperHome').css('margin-top',  0);
			}
		}
	}
});
/*function listCourses(element,container){
        var next = document.getElementById(element);
        var tile = document.getElementsByClassName(container);
        for(var i = 0;i<tile.length;i++){
            next.appendChild(tile[i]);
        }
    }*/

function clickPaths(){
	$('.juniorTitle').click(function(){
		if(!$('.juniorCourses').is(":visible")){
			$('.juniorCourses').slideDown();
			$('#juniorAngle span').removeClass("fa-angle-down").addClass("fa-angle-up");

		}else{
			$('.juniorCourses').slideUp();
			$('#juniorAngle span').removeClass("fa-angle-up").addClass("fa-angle-down");
		}
	});

	$('.consoleTitle').click(function(){
		if(!$('.consoleCourses').is(":visible")){
			$('.consoleCourses').slideDown();
			$('#consoleAngle span').removeClass("fa-angle-down").addClass("fa-angle-up");
		}else{
			$('.consoleCourses').slideUp();
			$('#consoleAngle span').removeClass("fa-angle-up").addClass("fa-angle-down");
		}
	});
	$('.mvcTitle').click(function(){
		if(!$('.mvcCourses').is(":visible")){
			$('.mvcCourses').slideDown();
			$('#mvcTitleAngle span').removeClass("fa-angle-down").addClass("fa-angle-up");
		}else{
			$('.mvcCourses').slideUp();
			$('#mvcTitleAngle span').removeClass("fa-angle-up").addClass("fa-angle-down");
		}
	});
	$('.internshipTitle').click(function(){
		if(!$('.internshipContent').is(":visible")){
			$('.internshipContent').slideDown();
			$('#internshipAngle span').removeClass("fa-angle-down").addClass("fa-angle-up");
		}else{
			$('.internshipContent').slideUp();
			$('#internshipAngle span').removeClass("fa-angle-up").addClass("fa-angle-down");
		}
	});
}
$('#fbLikeButaforen').mouseover(function(){
	$('#fbLikePlaceholder').hide();
	loadFb();
});
$("#schoolCourseTitle").parent().click(function() {

	if (!$("#school-course").is(":visible")) {
		console.log('aaa');
		$("#school-course").slideDown();
		$("#schoolAngle span").removeClass("fa-angle-down").addClass("fa-angle-up");
	} else {
		$("#school-course").slideUp();
		$("#schoolAngle span").removeClass("fa-angle-up").addClass("fa-angle-down");
	}
});
function closeGratisMassage(){
	$('#gratis_message').slideUp();
}


function showSecondSteppayment(){
	if(payment_method != "" && payment_method != null){
		$('.continuePlan').addClass(payment_method);
		continuePaymentsData();
		$('.continuePlan').removeClass('premium_plan');
	}
}
function detectUserBrowser(){
	var ba = ["Chrome","Firefox","Safari","Opera","MSIE","Trident","Edge"];
	var b, ua = navigator.userAgent;
	for(var i=0; i < ba.length; i++){
		if( ua.indexOf(ba[i]) > -1 ){
			b = ba[i];
			break;
		}
	}
	if(b == "MSIE" || b == "Trident" || b == "Edge"){
		b = "Internet Explorer";
	}
	return b;

}
$('#no-add-app').click(function(){
	$('.overlayAddApp').addClass('inactive');
	$('.mobileAskForAppIcon').addClass('inactive');
});
$('#yes-add-app').click(function(){
	/*$('.overlayAddApp').addClass('inactive');*/
	$('.mobileAskForAppIcon').addClass('inactive');
	appIconWorkFlow();
});
function appIconWorkFlow(){
	if(detectUserBrowser() == "Chrome"){
		$('.browser-menu-click').removeClass('inactive').css({top:'0',right:'0'});
	}else if(detectUserBrowser() == "Safari"){
		$('.browser-menu-click').removeClass('inactive').css({bottom:'0',right:'50%'});
		$('.menu-click-guide').html("Кликнете иконата:");
	}
}
function askForAppIcon(){
	$('.overlayAddApp').removeClass('inactive');
	$('.mobileAskForAppIcon').removeClass('inactive');
}

function leaveWithoutPayment(){
	$('.leavePage').removeClass('inactive');
	$('.customer_service_overlay').removeClass('inactive');
}
/*window.onbeforeunload = function(e){
    var dialogText = 'Dialog text here';
    e.returnValue = dialogText;
    return dialogText;
};*/
function confirmExit(){


}
/*$(document).mouseleave(function () {
    leaveWithoutPayment();
});*/
document.addEventListener("mouseleave", function(e){
	if( e.clientY < 0 )
	{
		if(localStorage.getItem("customer_service") != "payment"){
			/*leaveWithoutPayment();*/
		}
	}
}, false);

function onBlur(){
	/*alert("sadfewr");*/
	if(localStorage.getItem("customer_service") != "payment"){
		/*leaveWithoutPayment();*/
	}
}
function onFocus(){

}
if (/*@cc_on!@*/false) { // check for Internet Explorer
	document.onfocusin = onFocus;
	document.onfocusout = onBlur;
} else {
	window.onfocus = onFocus;
	window.onblur = onBlur;
}
function closeLeavePage(){
	$('.leavePage').addClass('inactive');
	$('.customer_service_overlay').addClass('inactive');
}
function reminderPayment(){

}
function closePaymentReminders(){

}
setTimeout(function(){
	/*payment_controls.reminderPayment();*/
},2000);
payment_controls = {
	_payment_method:null,
	closePaymentReminders:function(){
		$('.continuePaymentModal').hide();
		$('.customer_service_overlay').hide();
		localStorage.setItem("reminders_denied",true);
	},
	reminderPayment:function(){
		var payment_step = localStorage.getItem("step_payment");
		var is_closed = localStorage.getItem("payment_closed");
		var reminders_denied = localStorage.getItem('reminders_denied');
		sessionStorage.removeItem("select-half-plan");
		payment_controls.payment_method = localStorage.getItem('payment_method');
		if(!reminders_denied && is_closed && payment_step != "" && payment_step == "last_step"){
			setTimeout(function(){
				/*$('.continuePaymentModal').removeClass('inactive');
                $('.continuePaymentModal').find('h1').html(trans('langPage.billUnfinished')+payment_controls.paymentMethod() +"<br/>"+ trans('langPage.billProblem'));
                $('.customer_service_overlay').removeClass('inactive');*/
			},2000);

		}else if(!reminders_denied && is_closed && payment_step != "" && payment_step == "second_step"){
			$('.continuePaymentModal').removeClass('inactive');
		}
	},
	paymentMethod:function(){
		if(payment_controls.payment_method == "payment_checkout"){
			return "Checkout";
		}else if(payment_controls.payment_method == "payment_epay_cards"){
			return "Epay";
		}else if(payment_controls.payment_method == "payment_easy_pay_office"){
			return "Easy pay"
		}else if(payment_controls.payment_method == "payment_econt"){
			return "Еконт"
		}else if(payment_controls.payment_method == "payment_bank_transfer"){
			return "Банков превод"
		}
	},
	selectProblem:function(self){
		$('.check_circle').removeClass('problem_selected');
		$(self).find('.check_circle').addClass('problem_selected');
		localStorage.setItem("problem_payment",$(self).find('.problem_title').attr('id'));
	},
	selectedProblem:function(){
		var problem = localStorage.getItem("problem_payment");
		if(problem == 'problem_price'){
			$('.disocount-icon').removeClass('inactive');
			$('.cutomer_container').find('h1').html(trans('langPage.offerText'));
			$('.payment_problems').addClass('inactive');
			$('.problems_solution').removeClass('inactive');
			$('.problem_price').removeClass('inactive');
		}else if(problem == "problem_system"){
			$('.cutomer_container').find('h1').html(trans('langPage.showSystem'));
			$('.payment_problems').addClass('inactive');
			$('.problems_solution').removeClass('inactive');
			$('.problem_system').removeClass('inactive');
		}


	},
	continueWithPayment:function(){
		sessionStorage.setItem("select-half-plan",true);
		payment_method = localStorage.getItem("payment_method");
		$('.continuePaymentModal').addClass('inactive');
		$('.payment_steps_global .step-1').removeClass('activeStep').addClass('finishedStep').find('.payment_step_number span').addClass('fa fa-check').html('');
		$('.payment_steps_global .step-2').removeClass('activeStep').addClass('finishedStep').find('.payment_step_number span').addClass('fa fa-check').html('');
		$('.payment_steps_global .step-3').addClass('activeStep');
		$('.paymentsCards').addClass("inactive");
		$('.econtDataPayment ').addClass("inactive");
		$('#bankCashTransfer').addClass("inactive");
		$('#modalStartTrial').removeClass('inactive');
		$('.continuePlan').attr("class",
			"button prColBG accColBGHoverSame tileShadowPage continuePlan   payment_last_step ");
		$('.continuePlan').addClass(payment_controls.payment_method);

		continuePaymentsData();
		$('.continuePlan').removeClass('premium_plan');
		$('#startTrialOverlay').removeClass('inactive');
		$('.firstStep').addClass('inactive');
		getOffices();
		$('.customer_service_overlay').addClass('inactive');
	},
	exitPayment:function(){
		$('.cutomer_container').find('h1').html(trans('langPage.feedback'));
		$('.feedback').removeClass('inactive');
		$('.problems_solution').addClass('inactive');
	},
	play_video:function(){
		console.log("A");
		$('.videoFrame').css({backgroundImage:"url("+baseUrl+"/images/levels/game2.gif"+")"});
		$('.gifBeforePlayOverlay').hide();
		$('.play_gif').hide();
	}

}
feedback = {
	feedbackYes:function(){
		$('.cutomer_container').find('h1').html(trans('langPage.feedBackTitle'));
		$('.feedbackSure').addClass('inactive');
		$('.feedback_container').removeClass('inactive');
		localStorage.setItem("reminders_denied",true);
	},
	feedbackNo:function(){
		$('.continuePaymentModal').hide();
		$('.customer_service_overlay').hide();
		localStorage.setItem("reminders_denied",true);
	},
	sendFeedback:function(){
		$.ajaxSetup({
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		});
		$.post(baseUrl+"/feedback/send",
			{
				feedback_name:$('#feedback_name').val(),
				feedback_email:$("#feedback_email").val(),
				feedback_content:$('#feedback_content').val()
			} )
			.done(function( data ) {

				if(typeof data.error != "undefined"){
					$('.feedback_container').append(data.error);
				}else{
					localStorage.setItem("reminders_denied",true);
					/*$('.continuePaymentModal').addClass('inactive');
                  $('.customer_service_overlay').addClass('inactive');*/
					$('.feedback_container').prepend(trans('langPage.feedbackSuccess'));
				}

			});
	}
}
$('.start-btn-pop').click(function(){
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	$.post(baseUrl+"/subscribe",
		{
			email:$('#subscribeEmail').val()
		} )
		.done(function( data ) {

			if(typeof data.error != "undefined"){
				$('.error_home').html(data.error);
				$('.error_home').css('display','block').show();
			}else{
				window.location.href = baseUrl+"/profile/show?status=Important! Change your password. Your current password is send to email";
			}

		});
});
$('.subscribeButton').click(function(){
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	$.post(baseUrl+"/subscribe",
		{
			email:$('#subsctibeEmailFooter').val()
		} )
		.done(function( data ) {
			if(typeof data.error != "undefined"){
				$('.error-footer-subcribe').html(data.error);
			}else{
				if(data.status == "subscribe"){
					$('#subscribeFormFooter')
						.html("<h3 style='color:green'>Изпратихме ви и-мейл за потвърждение. "+
							" Моля кликнете върху връзката в него, за да потвърдите своето желание да "+
							"получавате от нас новини, промоции и известия. </h3>");
				}else if(data.status == "issubscribe"){
					$('#subscribeFormFooter').html("<h2 style='color:green'>Ти вече си абониран!</h2>");
				}else{
					window.location.href = baseUrl+
						"/profile/show?status=Important! Change your password. Your current password is send to email";
				}
			}

		});
});
function scrollToCourses(){
	$('html, body').animate({
		scrollTop: $("#free-courses").offset().top-200
	}, 2000);
}
function extendCourses(){
	$('#expand').hide().fadeIn();

	var all = $('.slideItems').next('ul');

	if ($("#expFa").hasClass("fa-angle-down")){
		/* CODE:
             * all.slideUp();
             */
		$('.modBox').animate({
			paddingBottom: '16px'
		});
		$('.modLi').animate({
			paddingTop: '24px',
			paddingBottom: '24px'
		});
		all.slideDown("slow");


		$(".expandCollapse").html(trans('langPage.hideAcord'));
		$("#expFa").removeClass("fa-angle-down").addClass("fa-angle-up");
		$('.BoxFa').removeClass('fa-plus').addClass('fa-minus');
	} else {
		$('.modBox').animate({
			paddingBottom: '0'
		});
		$('.modLi').animate({
			paddingTop: '16px',
			paddingBottom: '16px'
		});
		$(".expandCollapse").html(trans('langPage.showAcord'));
		all.slideUp("slow");
		$("#expFa").removeClass("fa-angle-up").addClass("fa-angle-down");
		$('.BoxFa').removeClass('fa-minus').addClass('fa-plus');
	}
	if($('#TFa').hasClass("fa-plus")){
		$('#TFa').removeClass('fa-plus').addClass('fa-minus');
	}else{
		$('#TFa').removeClass('fa-minus').addClass('fa-plus');
	}
}
function downloadApp(){
	$('.downloadApp').show();
	$('.downloadAppOverlay').show();
}
function closeApp(){
	$('.downloadApp').hide();
	$('.downloadAppOverlay').hide();
}
function toFreeReg(){
	localStorage.setItem("free_reg",true);
	window.location.href = baseUrl + "/auth/register";
	if(is_user != ""){
		showHomeProfile();
	}
}
function toDiscountTariff(){
	loadFb();
	$('.discount_window').show();
	$('.discountWindowOverlay').show();
}
$('.close-share').click(function(){
	$('.discount_window').hide();
	$('.discountWindowOverlay').hide();
});

function continueToPayment(){
	if(current_page == "bill"){
		$('body').append('<script src="https://checkout.stripe.com/checkout.js"></script>');
		playNow();

		var m_price = Math.round(amount_discount/12);

		$('#startModalTrial').find('.pay_price').css({textDesoration:'underline'}).append();
		var txtPrice = m_price+'лв.';
		var y_price = amount_discount+'лв.';
		if(locale != "bg"){
			txtPrice = "€"+m_price;
			y_price = "€"+amount_discount;
		}
		$('.m_price').css({textDecoration:'line-through'}).after(txtPrice);
		$('.y_price').css({textDecoration:'line-through'}).after(y_price);
		$('.continuePlan').removeClass('premium_plan');
	}
}
function showFullProfile(){
	$('.fullProfile').css({display:'inline-block'});
	$('.learn-coding').hide();
	$('.badgesProfile').hide();
	$('.progressProfile').hide();
	$('.promotionsProfile').hide();
	$('.statisticsProfile').hide();
	$('.settingsProfile').hide();
	$('.userAssignments').hide();
	localStorage.setItem("profile-page",4);
}
function showHomeProfile(){
	$('.fullProfile').hide();
	$('.learn-coding').css({display:'inline-block'});
	$('.badgesProfile').hide();
	$('.progressProfile').hide();
	$('.promotionsProfile').hide();
	$('.statisticsProfile').hide();
	$('.settingsProfile').hide();
	$('.userAssignments').hide();
	localStorage.setItem("profile-page",1);

}
function showBadgesProfile(){
	$('.fullProfile').hide();
	$('.learn-coding').hide();
	$('.badgesProfile').show();
	$('.badges_cont').attr('style','display: -webkit-flex;    display: flex;    display: -ms-flexbox;');
	$('.progressProfile').hide();
	$('.promotionsProfile').hide();
	$('.statisticsProfile').hide();
	$('.settingsProfile').hide();
	$('.userAssignments').hide();
	localStorage.setItem("profile-page",2);
}
function showHomePromotions(){
	$('.fullProfile').hide();
	$('.learn-coding').hide();
	$('.badgesProfile').hide();
	$('.progressProfile').hide();
	$('.promotionsProfile').show();
	$('.statisticsProfile').hide();
	$('.settingsProfile').hide();
	$('.userAssignments').hide();
	localStorage.setItem("profile-page",5);
}
function showProgressProfile(){
	$('.fullProfile').hide();
	$('.learn-coding').hide();
	$('.badgesProfile').hide();
	$('.progressProfile').show();
	$('.promotionsProfile').hide();
	$('.statisticsProfile').hide();
	$('.settingsProfile').hide();
	$('.userAssignments').hide();
	/*var el_id = $('.pathsActivitySlider').first().attr('id')
     pieRender('cnvs-proff_'+el_id, percent );*/
	try{
		var el = document.getElementsByClassName('pathsActivitySlider')[0];
		el.onclick();
	}catch(ex){
		console.log("Something went wrong" + ex);
	}

	localStorage.setItem("profile-page",3);
}
function showStatisticsProfile(){
	$('.fullProfile').hide();
	$('.learn-coding').hide();
	$('.badgesProfile').hide();
	$('.progressProfile').hide();
	$('.promotionsProfile').hide();
	$('.statisticsProfile').show();
	$('.settingsProfile').hide();
	$('.userAssignments').hide();
}
function showAssignments(){
	$('.fullProfile').hide();
	$('.learn-coding').hide();
	$('.badgesProfile').hide();
	$('.progressProfile').hide();
	$('.promotionsProfile').hide();
	$('.statisticsProfile').hide();
	$('.settingsProfile').hide();
	$('.userAssignments').show();
}
function showSettingsProfile(){
	$('.settingsProfile').show();
	$('.fullProfile').hide();
	$('.learn-coding').hide();
	$('.badgesProfile').hide();
	$('.progressProfile').hide();
	$('.promotionsProfile').hide();
	$('.statisticsProfile').hide();

	$('.userAssignments').hide();
}
function showPathProgress(self,el_id,percent){

	var paths = $('.singlePathProgress');
	$('.pathsActivitySlider').removeClass('activeActivitySlide');
	$(self).addClass('activeActivitySlide');
	var id = $(self).attr('id');
	$(paths).hide();
	for(var i = 0;i<paths.length;i++){
		if($(paths[i]).hasClass(id)){
			$(paths[i]).attr('style','display: -webkit-flex;    display: flex;    display: -ms-flexbox;');
			$(paths[i]).find('#cnvs-proff_'+el_id).remove();
			$(paths[i]).find('.pieHolder').append('<canvas id="cnvs-proff_'+el_id+'" width="200px" height="200px" style="width: 40%; cursor: default;" class="canvasView"></canvas>');
			pieRender('cnvs-proff_'+el_id, percent );
		}
	}
}
function nextBox(){
	var slides = $('.pathsActivitySlider:not(.inactiveActivitySlide) ');
	var all_slides = $('.pathsActivitySlider');
	if(!mobile){
		if(slides.length>=3){
			$(slides[0]).addClass('inactiveActivitySlide');
			$(slides[slides.length-1]).next().removeClass('inactiveActivitySlide');
			/*  $('.next-box').hide();*/
			$('.prev-box').removeClass('inactive');
		}else{
			$('.next-box').hide();
		}
	}else{
		if($(slides).attr('id') != $(all_slides).last().attr('id')){
			$(slides[0]).addClass('inactiveActivitySlide');
			$(slides[0]).next().removeClass('inactiveActivitySlide');
			var el = document.querySelector('.pathsActivitySlider:not(.inactiveActivitySlide)');
			el.onclick();
			console.log("asd");
		}

	}


}
function prevBox(){
	var slides = $('.pathsActivitySlider:not(.inactiveActivitySlide)');
	var all_slides = $('.pathsActivitySlider');
	if(!mobile){
		if(slides.length>=3 && $(all_slides).first().attr('id') != $(slides).first().attr('id')){
			$(slides[slides.length-1]).addClass('inactiveActivitySlide');
			$(slides[0]).prev().removeClass('inactiveActivitySlide');
			/*$('.next-box').show();*/
		}else{
			if((slides.length == 2 || slides.length == 1) && $(all_slides).last().attr('id') == $(slides).last().attr('id')){
				$('.next-box').show();
				if(slides.length == 2){
					$(slides[2]).addClass('inactiveActivitySlide');
					/*  $('.prev-box').addClass('inactive');*/
				}else if(slides.length == 1){
					$(slides[1]).addClass('inactiveActivitySlide');
					console.log("3");
				}
				$(slides[0]).prev().removeClass('inactiveActivitySlide');

			}else{
				$('.prev-box').removeClass('inactive');
			}
		}
		if($(all_slides).first().attr('id') == $(slides).first().attr('id')){
			$('.prev-box').addClass('inactive');
		}
	}else{
		if($(all_slides).first().attr('id') != $(slides).first().attr('id')){
			$(slides[slides.length-1]).addClass('inactiveActivitySlide');
			$(slides[0]).prev().removeClass('inactiveActivitySlide');
			var el = document.querySelector('.pathsActivitySlider:not(.inactiveActivitySlide)');
			el.onclick();
		}

	}

}
function addPromotion(){
	var m_price = Math.round(amount_discount/12);
	if(locale == "bg"){
		$('.p_month').html('<span style="text-decoration: line-through;">'+Math.round(amount/12)+'лв.</span> '+m_price+'лв. / МЕСЕЦ');
		$('.p_year').html('ПЛАЩА СЕ НА ГОДИШНА БАЗА <span style="text-decoration: line-through;">'+amount+'лв.</span> '+amount_discount+'лв.');
		$('.m_price').html(m_price+"лв");
		$('.y_price').html(amount_discount+"лв.");
		if(sessionStorage.getItem('select-half-plan') != null){
			$('.standrat-f-price').html(half_amount_discount+"лв.");
			$('.sum_price1').html((half_amount_discount+feature_plan_1)+"лв. ");
			$('.sum_price2').html((half_amount_discount+feature_plan_2)+"лв. ");
		}else{
			$('.standrat-f-price').html(amount_discount+"лв.");
			$('.sum_price1').html((amount_discount+feature_plan_1)+"лв. ");
			$('.sum_price2').html((amount_discount+feature_plan_2)+"лв. ");
		}


	}else{
		$('.p_month').html('<span style="text-decoration: line-through;">€'+Math.round(amount/12)+'</span> €'+m_price+' / МЕСЕЦ');
		$('.p_year').html('ПЛАЩА СЕ НА ГОДИШНА БАЗА <span style="text-decoration: line-through;">€'+amount+'</span> €'+amount_discount+'');
		$('.m_price').html(m_price+"Euro");
		$('.y_price').html(amount_discount+"Euro");
	}
}

function gotoExercise(id,self){

	/*$.get(baseUrl+'/checkExercise/'+id,function(data){

       if(typeof data.redirect != "undefined"){
          if(is_tester != 1){
           var top = $(self).offset().top+$(self).outerHeight()+10;
           $('.floatMenuWrapper').css({top:top,left:$(self).offset().left});


            $('.floatMenuNotFinishedLevel').show();
            $('.floatMenuWrapper').find('.wrnText').text(data.redirect);
          }else{
            window.location = baseUrl+"/exercise/view/"+id;
          }
       }else{
           window.location = baseUrl+"/exercise/view/"+id;
       }
    });*/
	window.location = baseUrl+"/exercise/view/"+id;
}
function showChat(){
	$zopim(function() {
		$zopim.livechat.window.show();
	});
	$('.closeChat').show();
}
function initChat(){
	window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=
		d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
	_.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute("charset","utf-8");
		$.src="https://v2.zopim.com/?4nuRwIFLedhlyDoq3XecakjQwcXLKRzt";z.t=+new Date;$.
			type="text/javascript";e.parentNode.insertBefore($,e)})(document,"script");
	init_chat  = true;
}
function closeChat(){
	if(typeof window.$zopim != "undefined"){
		$zopim(function() {
			$zopim.livechat.window.hide();
			location.reload();
			loadAfterChat = true;
			localStorage.setItem("loader_after_chat",true);
			$('.loader_chat_overlay').show();
			$('.loader_chat').show();
		});
	}
}



function downloadCertificate(){
	$('.download-overlay').show();
	$('.download-loader').show();

	var cert_id = $('#cert_id').val();

	var cert_url = baseUrl+"/certificate/get/"+cert_id;

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	$.get(cert_url,{
		fullname:$('#fullname').val(),

	}, function(data,textStatus,request){
		console.log("download");
	} );
}
function openFeedbacks(){
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	$.post(baseUrl+"/admin/show-feedbacks",
		{
			email:$('#subsctibeEmailFooter').val()
		} )
		.done(function( data ) {
			if(typeof data.error != "undefined"){
				$('.error-footer-subcribe').html(data.error);
			}else{
				if(data.status == "subscribe"){
					$('#subscribeFormFooter').html("<h2 style='color:green'>Моля, потвърдете абонамента си на емайл адреса си!</h2>");
				}else if(data.status == "issubscribe"){
					$('#subscribeFormFooter').html("<h2 style='color:green'>Ти вече си абониран!</h2>");
				}else{
					window.location.href = baseUrl+
						"/profile/show?status=Important! Change your password. Your current password is send to email";
				}
			}

		});
}


function copyIframe(){
	var copyText = document.querySelector('.iframeCopy');

	copyText.select();
	try{
		var successfull = document.execCommand('copy');
		var msg = successfull ? 'successful' : 'unsuccessful';
		console.log(msg);
	}catch(err){
		console.log('Oops, unable to copy');
	}
}
var edit = false;
function editLanding(){

	/*$.get(baseUrl+'/landing-page/get/html',function(data){

        $('.landing-modal').show();
        $('.landing-modal-container').html(data);
        $('.landing-overlay').show();
        $('.input_message').focus();
    });*/
	if(!edit){
		$('.form-msg').hide();
		$('.form-edit').show();
		edit = true;
	}else{
		$('.form-msg').show();
		$('.form-edit').hide();
		edit = false;
	}


}
$('.closeLanding').click(function(){
	$('.landing-modal').hide();
	$('.landing-overlay').hide();
});
function shareLinkMail(){

}
$('.hideLinkShare').click(function(){
	if($('.extraContent').is(":visible")){
		$('.extraContent').slideUp();
		$(this).find('.fa').removeClass('fa-angle-up').addClass('fa-angle-down');
	} else{
		$('.extraContent').slideDown();
		$(this).find('.fa').removeClass('fa-angle-down').addClass('fa-angle-up');
	}
});

function closeModalTrial(){
	$('#getpdf').remove();
	$('#additional_plan').val("")

	sessionStorage.removeItem("additional_plan");
	if($('.thirdStepPremium').is(":visible")){
		/*localStorage.setItem("payment_closed",true);
        paymentClosed = true;*/
	}

	$('#modalStartTrial').addClass('inactive');
	$('.overlayStartTrial').addClass('inactive');
	$('#modalStartTrial .firstStep').removeClass('inactive');
	$('#modalStartTrial .secondStep').addClass('inactive').hide();
	$('#modalStartTrial .thirdStepPremium').addClass('inactive');
	$('#modalStartTrial .secondStepPremium').addClass('inactive');
	document.getElementsByClassName('continuePlan').class='';
	document.getElementsByClassName('continuePlan').class='button prColBG accColBGHoverSame tileShadowPage continuePlan';
	/*$('.continuePlan').addClass('button prColBG accColBGHoverSame tileShadowPage continuePlan');*/
	$('.continuePlan').attr('style','cursor: auto;background: #999 !important;box-shadow: none;').removeClass('payment_last_step premium_plan payment_checked');
	$('#modalStartTrial .termsPaymentPopup').addClass('inactive');
	$('.getFree').removeClass('inactive planBG');
	$('.getFree').addClass('inactiveButton');
	$('.getPremium').removeClass('inactive planBG');
	$('.getPremium').addClass('inactiveButton');
	$('.choosePlan').removeClass('inactive');
	/*var payment_step = localStorage.getItem('payment_step');
    if(payment_step == 'firstStepFree'){
        $('.continuePlan').addClass('inactive');
    }
    */
	$('.paymentsCards').addClass('inactive');
	$('.econtDataPayment').addClass('inactive');
	$('#bankCashTransfer').addClass('inactive');
	$('.continuePlan').removeClass('inactive');
	$('.payments-check').removeClass('fa-check');
	$('#modalStartTrial .secondStep').addClass('inactive');
	$('#modalStartTrial').find('input[type=text]').val("");
	$('#modalStartTrial .secondStepPremium .payments-check').css({background:'#fff'});
	if(is_user != ""){
		$('.continuePlan').removeAttr('style');
		$('.getPremium').removeClass('inactive inactiveButton');
		$('.getPremium').addClass('planBG');

	}
	$('.continuePlan').find('span').remove();
}
function showEnterCodeModal(){
	if(typeof has_invite_only_code != "undefined" && has_invite_only_code == false){
		var myParam = location.search.split('invite_code')[1];
		if(myParam == "=missing"){
			$('.inviteOnlyModal').show();
			$('.inviteOnlyOverlay').show();
		}
	}
}
function toPromotionPage(){

	window.location.href = "profile";
	localStorage.setItem("profile-page",5);
}
function scrollToDesc(){
	$('html, body').animate({
		scrollTop: $(".descDiv").offset().top
	}, 2000);
}
function getTodayUsd(){
	$.ajaxSetup({
		headers: {

		}
	});
	$.get("https://api.fixer.io/latest?base=USD",
		{
		} )
		.done(function( data ) {
			console.log(data);
			usd = data["rates"].BGN;
			$('.currencyCalculate').show();

			var bgn = $('#bgn-price').text();
			console.log(bgn);
			var price = (amount/(usd + usd*(5.276367/100))).toFixed(2);
			$('#usd-price').html(price);
		});

}
var navigationDrawer = {
	open:function(){
		$('.navigation-courses').hide();
		if($('#navigationDrawer').is(":visible")){

			$('#pageWrap').animate({marginLeft:'0'})
			if(!mobile){
				$('#navigationDrawer').css({left:'0%'}).animate({left:'-15%'},function(){
					$(this).hide();
					$('.navigation-courses').show().css({left:'5px'});
				});
			}else{
				$('#navigationDrawer').css({left:'0%'}).animate({left:'-90%'},function(){
					$(this).hide();
				});
			}
			$('.playNowFixed').show();

			$('.navigation-courses').find('span').removeClass('fa-close').addClass('fa-bars');

			/*$('body > .zopim').show();*/
		}else{
			/*$('#navigationDrawer').animate({left:'20%'});*/
			if(!mobile){
				$('#pageWrap').animate({marginLeft:'15%'})
				$('#navigationDrawer').show().css({left:'-15%'}).animate({left:'0'},function(){
					$('.navigation-courses').show().css({left:'15%'});
				});
			}else{
				$('#pageWrap').animate({marginLeft:'90%'})
				$('#navigationDrawer').show().css({left:'-90%'}).animate({left:'0'},function(){

				});
			}
			$('.playNowFixed').hide();
			$('body >.zopim').hide();
			$('.promotionBtnFixed').hide();
			$('.navigation-courses').find('span').removeClass('fa-bars').addClass('fa-close');

		}
	},
	close:function(){
		$('.navigation-courses').hide();
		$('#pageWrap').animate({marginLeft:'0'})
		if(!mobile){
			$('#navigationDrawer').css({left:'0%'}).animate({left:'-15%'},function(){
				$(this).hide();
				$('.navigation-courses').show().css({left:'5px'});
			});
		}else{
			$('#navigationDrawer').css({left:'0%'}).animate({left:'-90%'},function(){
				$(this).hide();
			});
		}
		$('.navigation-courses').find('span').removeClass('fa-close').addClass('fa-bars');
		$('.playNowFixed').show();
	},
	scrollPath:function(self){
		var id = $(self).attr('class');
		$('#navigationDrawer li').removeClass('activeNavigationLink');
		$(self).addClass('activeNavigationLink');
		$('html, body').animate({
			scrollTop: $("#"+id).offset().top-100
		}, 2000);
	},
	openPath:function(self){
		if($(self).find('span').hasClass('fa-angle-down')){
			$(self).find('span').removeClass('fa-angle-down').addClass('fa-angle-up');
			$(self).next().slideDown();
		}else{
			$(self).find('span').removeClass('fa-angle-up').addClass('fa-angle-down');
			$(self).next().slideUp();

		}
	}
}
var scroll_pos = 0;
$(window).on("scroll",function(){
	scroll_pos = $(this).scrollTop();
	var courses = $('.coursePathList');
	for(var i=0;i<courses.length;i++){
		/* $('#navigationDrawer li').removeClass('activeNavigationLink');*/
		if(scroll_pos >= $(courses[i]).offset().top-150) {
			var cl = $(courses[i]).attr('id');
			$('#navigationDrawer li').removeClass('activeNavigationLink');
			$('.'+cl).addClass('activeNavigationLink');
		} else {

		}
	}


});
function activateScratchAccount(self){
	$(self).text("Проверяване на кода...");
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	$.post(baseUrl+"/activate-scratch-user",
		{
			code:$('#activationScratchCode').val()
		} )
		.done(function( data ) {
			if(typeof data.error == "undefined"){
				$('.activationStatus').html("<span class='fa fa-check'></span> Успещно активиране!").addClass('successActivationStatus');
				$('#activationScratchCode').removeClass('wrongInput');
				$(self).text("Активиране");
				$('.activeScratchAccount').fadeOut();
				$('.activeScratchAccountOverlay').fadeOut();
			}else{
				$('.activationStatus').html("<span class='fa fa-close'></span> Грешка при активиране! Невалиден код! ").addClass('erroActivationStatus');
				$('#activationScratchCode').addClass('wrongInput');
				$(self).text("Активиране");
			}

		});
}
function activateScratchCodeAccount(self){
	$(self).text("Проверяване на кода...");
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	$.post(baseUrl+"/activate-scratch-multiplayer-user",
		{
			code:$('#activationScratchCodeMultiplayer').val()
		} )
		.done(function( data ) {
			if(typeof data.error == "undefined"){
				$('.activationStatus').html("<span class='fa fa-check'></span> Успещно активиране!").addClass('successActivationStatus');
				$('#activationScratchCode').removeClass('wrongInput');
				$(self).text("Активиране");
				$('.activeScratchCodeAccount').fadeOut();
				$('.activeScratchAccountOverlay').fadeOut();
			}else{
				$('.activationStatus').html("<span class='fa fa-close'></span> Грешка при активиране! Невалиден код! ").addClass('erroActivationStatus');
				$('#activationScratchCode').addClass('wrongInput');
				$(self).text("Активиране");
			}

		});
}
function closeScratchCodeAccount(){
	$('.activeScratchCodeAccount').fadeOut();
	$('.activeScratchAccountOverlay').fadeOut();

}
function closeScratchAccount(){
	$('.activeScratchAccount').fadeOut();
	$('.activeScratchAccountOverlay').fadeOut();

}
function ajaxPostRequester(url,data,success){
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	$.post(baseUrl+"/"+url,data )
		.done(function( data ) {
			success(data);
		});
}
function getDaysInMonth(month, year) {
	// Since no month has fewer than 28 days
	var date = new Date(year, month, 1);
	var days = [];
	while (date.getMonth() === month) {
		days.push( new Date(date).getDate());
		date.setDate(date.getDate() + 1);
	}
	return days;
}
var rendered_months = false;
var rendered_week = false;
var rendered_days = false;
var days = ["Monday"];
function showMonthStats(){
	$('.stats').addClass('inactive');
	$('.monthStats').removeClass('inactive');
	var date = new Date();
	//var a = [987,98,56,54,546,546,546,78,76,45,345,456,567,456,345,234,546,67,345,345,234,546,765,564,478,1321,564,4654,564,564,654,789];
	var a =  Object.keys(count_day_of_month).map(function(k) { return count_day_of_month[k] });
	var days = getDaysInMonth(date.getMonth(),date.getFullYear() );
	if(checkObjectIsNull(a)){
		$('.no-stats').removeClass('inactive');
		$('.stats').addClass('inactive');
	}
	/*if(a != ""){

    }*/
	if(!rendered_months){
		chartRender('chart1',days,a);
		/*chartRender('chartEx',days,a);*/
		rendered_months = true;
	}
	$('.statsBtn').removeClass('activeStatBtn');
	$('#statsMonth').addClass('activeStatBtn');
}
function showWeekStats(){
	$('.stats').addClass('inactive');
	$('.weekStats').removeClass('inactive');
	var days = ["Неделя","Понеделник","Вторник","Сряда","Четвъртък","Петък","Събота"];
	var data = Object.keys(count_day_of_week).map(function(k) { return count_day_of_week[k] });
	if(checkObjectIsNull(data)){
		$('.no-stats').removeClass('inactive');
		$('.stats').addClass('inactive');
	}
	if(!rendered_week){
		chartRender('chartWeek',days,data);

		/*chartRender('chartExWeek',days,data);*/
		rendered_week = true;
	}
	$('.statsBtn').removeClass('activeStatBtn');
	$('#statsWeek').addClass('activeStatBtn');
}
function showDaysStats(){
	$('.stats').addClass('inactive');
	$('.dayStats').removeClass('inactive');
	var d = new Date();
	var days_week = ["Неделя","Понеделник","Вторник","Сряда","Четвъртък","Петък","Събота"];

	var days = [new Date(d.setDate(d.getDate())).getDate(),new Date(d.setDate(d.getDate() - 1)).getDate(),new Date(d.setDate(d.getDate() - 2)).getDate()];

	/*console.log(days);*/

	var data = Object.keys(count_treedays_of_exercise).map(function(k) { return count_treedays_of_exercise[k] });

	var three_days = [];
	for(var da in count_treedays_of_exercise){
		three_days.push(da);
	}
	if(checkObjectIsNull(data)){
		$('.no-stats').removeClass('inactive');
		$('.stats').addClass('inactive');
	}
	if(!rendered_days){
		chartRender('chartDays',three_days,data);
		rendered_days = true;
	}
	$('.statsBtn').removeClass('activeStatBtn');
	$('#statsDays').addClass('activeStatBtn');
}
function checkObjectIsNull(obj){
	for (var key in obj) {
		if (obj[key] !== null && obj[key] != "")
			return false;
	}
	return true;
}
function closeAskingTelephone(){
	ajaxPostRequester("user/put/telephoneDenied",{},function(data){});
	$('.askingForTelephone').addClass('inactive');
	$('.askingForTelephoneOverlay').addClass('inactive');
}
/****** WIDGET GAME BUILDER ***************/
var widget_width = null;
var widget_height = null;
function setFixedWidth(self){
	var size = $(self).val();
	widget_width = size + "px";
	/*$('#iframePreview').attr('style','border:none;width:'+size+'px;');*/

	setIframeSize(widget_width, widget_height);
}
function setFixedHeight(self){
	var size = $(self).val();
	if(size >= 400){
		size = size + "px";
	}else{
		size = 400+ "px";
	}
	/*$('#iframePreview').attr('style','border:none;height:'+size+'px;');*/
	console.log(size);
	setIframeSize(widget_width, size);
}

$('#fixedWidth').click(function(){
	var checked  = $(this).is(":checked");
	if($('#width100').is(":checked")){
		$('#width100').attr('checked',false);
		widget_width = null;
	}
	if(checked){
		/*$('.iframeCopy').html('<iframe src="http://localhost/azprogramiram-scale/public/embed_game/game" allowfullscreen="true" style="width:100%; border:none; height:300px"></iframe>');*/
		$('#fixedWidthInput').removeClass('inactive');
	}else{
		widget_width = null;
		$('#fixedWidthInput').addClass('inactive');
	}
	setIframeSize(widget_width, widget_height);

});
$('#fixedHeight').click(function(){
	var checked  = $(this).is(":checked");
	if($('#heightAuto').is(":checked")){
		$('#heightAuto').attr('checked',false);
		widget_height = null;
	}
	if(checked){
		/*$('.iframeCopy').html('<iframe src="http://localhost/azprogramiram-scale/public/embed_game/game" allowfullscreen="true" style="width:100%; border:none; height:300px"></iframe>');*/
		$('#fixedHeightInput').removeClass('inactive');
	}else{
		widget_height = null;
		$('#fixedHeightInput').addClass('inactive');
	}
	setIframeSize(widget_width, widget_height);
});
$('#width100').click(function(){
	var checked  = $(this).is(":checked");
	if($('#fixedWidth').is(":checked")){
		$('#fixedWidth').attr('checked', false);
		$('#fixedWidthInput').addClass('inactive');
		widget_width = null;
	}
	if(checked){
		/*$('#iframePreview').attr('style','border:none;width:100%;');*/
		widget_width = "100%";
	}else{
		widget_width = null;
		/*$('#iframePreview').attr('style','border:none;');*/
	}
	setIframeSize(widget_width, widget_height);

});
$('#heightAuto').click(function(){
	var checked  = $(this).is(":checked");
	if($('#fixedHeight').is(":checked")){
		$('#fixedHeight').attr('checked', false);
		$('#fixedHeightInput').addClass('inactive');
		widget_height = null;
	}
	if(checked){
		widget_height = "auto";
		/*$('#iframePreview').attr('style','border:none;height:100%;');*/
	}else{
		widget_height = null;
		/*$('#iframePreview').attr('style','border:none;');*/
	}
	setIframeSize(widget_width, widget_height);

});
function setIframeSize(width, height){
	/*if(width != null && height == null){
        $('#iframePreview').attr('style','border:none;width:'+width+';');
        $('.iframeCopy').html('<iframe src="http://localhost/azprogramiram-scale/public/embed_game/game" allowfullscreen="true" style="width:'+width+'; border:none"></iframe>');
    }else if(height != null && width == null){
         $('#iframePreview').attr('style','border:none;height:'+height+';');
         $('.iframeCopy').html('<iframe src="http://localhost/azprogramiram-scale/public/embed_game/game" allowfullscreen="true" style="height:'+height+'; border:none"></iframe>');
    }else if(width != null && height != null){
        $('#iframePreview').attr('style','border:none;height:'+height+';width:'+width+';');
        $('.iframeCopy').html('<iframe src="http://localhost/azprogramiram-scale/public/embed_game/game" allowfullscreen="true" style="height:'+height+';width:'+width+' border:none"></iframe>');
    }*/
	$('#gameWidget').attr('style','border:none;height:'+height+';width:100%;');
	$('.iframeCopy').html('<iframe src="http://localhost/azprogramiram-scale/public/embed_game/game" allowfullscreen="true" style="height:'+height+';width:100%; border:none"></iframe>');
}
function getCookie(cname) {
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for(var i = 0; i <ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}
function initYouTubeVideo() {
	var vidDefer = document.getElementsByTagName('iframe');
	for (var i=0; i<vidDefer.length; i++) {
		if(vidDefer[i].getAttribute('data-src')) {
			vidDefer[i].setAttribute('src',vidDefer[i].getAttribute('data-src'));
		} }
	setTimeout(function (){ },500);
}

function initBadges(){
	var vidDefer = document.getElementsByClassName('badge_in_course_tile');
	for (var i=0; i<vidDefer.length; i++) {
		if(vidDefer[i].getAttribute('data-src')) {
			vidDefer[i].setAttribute('src',vidDefer[i].getAttribute('data-src'));
		} }
	setTimeout(function (){ },500);
}

/*window.onload = initBadges;*/
function initCourseImages(){
	var vidDefer = document.querySelectorAll("img.courseLogo");
	for (var i=0; i<vidDefer.length; i++) {
		if(vidDefer[i].getAttribute('data-src')) {
			vidDefer[i].setAttribute('src',vidDefer[i].getAttribute('data-src'));
		} }
	setTimeout(function (){ },500);
}

window.onload = function(){
	initYouTubeVideo();
	initBadges();
	initCourseImages();
};


/***** COMMENT USER ASSIGNMENTS ***/
$('.viewUserCode').click(function(){
	var id = $(this).attr('id');
	$('.code'+id).show();
	$('.assignmentOverlay').show();
});
$('.viewUserPreview').click(function(){
	var id = $(this).attr('id');
	$('.preview'+id).show();
	$('.assignmentOverlay').show();
});
$('.closeCodeModal').click(function(){
	$('.assignmentOverlay').hide();
	$('.userCodeModal').hide();
});
$('.closePreviewModal').click(function(){
	$('.assignmentOverlay').hide();
	$('.userPreviewModal').hide();
});
$('.writeComment').click(function(){
	var id = $(this).attr('id');
	$('.comments'+id).show();
	$('.assignmentOverlay').show();
});
$('.closeCommentsModal').click(function(){
	$('.userComments').hide();
	$('.assignmentOverlay').hide();
});

/***** ECONT SORTING BTNS **********/
$('#econt_today').click(function(){
	$('.econt_btn').removeClass('active_econt_btn');
	$(this).addClass('active_econt_btn');
	var token = $('meta[name="csrf-token"]').attr('content');
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	$.get(baseUrl+"/econt/today",
		{

		} )
		.done(function( data ) {
			$('.econt_users').html("");
			for(var d in  data){
				var info = data[d];
				$('.econt_users').append(
					'<div style="padding:20px 15px;border-bottom:1px dashed #000; margin:0; background-color:'+color+' ">'+
					'<form action="'+baseUrl+'/bill/activate-econt-users" method="post" style="margin:0;">'+
					' <input type="hidden" name="_token" value="'+token+'"/>'+
					'<input type="hidden" name="invoice" value="'+info.invoice+'">'+
					'<input type="hidden" name="method" value="econt"/>'+
					'<input type="hidden" name="user_email" value="'+info.email+'"/>'+
					'<p style="padding:20px 15px;border-bottom:1px dashed #000; margin:0;"><strong>Име:</strong> '+info.name+' | <strong>Адрес:</strong> '+info.address+' | <strong>Град:</strong> '+info.city+' |<strong> Телефон:</strong> '+info.phone+' | <strong>Имейл:</strong>'+info.email+' | <strong>Дата:</strong> '+info.time+' <strong>Цена:</strong> '+info.amount+'<button type="submit" style="float:right;" class="'+btn_class+'">Активирай</button></p>'+
					'</form>'+
					' <form action="'+baseUrl+'/bill/duplicate_proform" method="post">'+
					'<input type="hidden" name="_token" value="'+token+'"/>'+
					'<input type="hidden" name="invoice" value="'+info.invoice+'"/>'+
					'<button type="submit">Направи Дупликат</button>'+
					' </form> '+
					'</div>'
				);
			}
		});
});

$('#econt_yesterday').click(function(){
	$('.econt_btn').removeClass('active_econt_btn');
	$(this).addClass('active_econt_btn');
	var token = $('meta[name="csrf-token"]').attr('content');
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	$.get(baseUrl+"/econt/yesterday",
		{

		} )
		.done(function( data ) {
			$('.econt_users').html("");
			for(var d in  data){
				var info = data[d];
				var color = "";
				var btn_class = "inactive";
				if(info.active == 1 && info.duplicate == 0){
					color = "#9afe9a";
				}else if(info.duplicate == 1){
					color = "#fac1c1";
				}else{
					color = "#ccc";
				}
				if(info.active == 0 && info.duplicate == 0){
					btn_class = "";
				}
				$('.econt_users').append(
					'<div style="padding:20px 15px;border-bottom:1px dashed #000; margin:0; background-color:'+color+' ">'+
					'<form action="'+baseUrl+'/bill/activate-econt-users" method="post" style="margin:0;">'+
					' <input type="hidden" name="_token" value="'+token+'"/>'+
					'<input type="hidden" name="invoice" value="'+info.invoice+'">'+
					'<input type="hidden" name="method" value="econt"/>'+
					'<input type="hidden" name="user_email" value="'+info.email+'"/>'+
					'<p style="padding:20px 15px;border-bottom:1px dashed #000; margin:0;"><strong>Име:</strong> '+info.name+' | <strong>Адрес:</strong> '+info.address+' | <strong>Град:</strong> '+info.city+' |<strong> Телефон:</strong> '+info.phone+' | <strong>Имейл:</strong>'+info.email+' | <strong>Дата:</strong> '+info.time+' <strong>Цена:</strong> '+info.amount+'<button type="submit" style="float:right;" class="'+btn_class+'">Активирай</button></p>'+
					'</form>'+
					' <form action="'+baseUrl+'/bill/duplicate_proform" method="post">'+
					'<input type="hidden" name="_token" value="'+token+'"/>'+
					'<input type="hidden" name="invoice" value="'+info.invoice+'"/>'+
					'<button type="submit">Направи Дупликат</button>'+
					' </form> '+
					'</div>'
				);
			}
		});
});

$('#econt_all').click(function(){
	$('.econt_btn').removeClass('active_econt_btn');
	$(this).addClass('active_econt_btn');
	var token = $('meta[name="csrf-token"]').attr('content');
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	$.get(baseUrl+"/econt/all",
		{

		} )
		.done(function( data ) {
			$('.econt_users').html("");
			for(var d in  data){
				var info = data[d];
				var color = "";
				var btn_class = "inactive";
				if(info.active == 1 && info.duplicate == 0){
					color = "#9afe9a";
				}else if(info.duplicate == 1){
					color = "#fac1c1";
				}else{
					color = "#ccc";
				}
				if(info.active == 0 && info.duplicate == 0){
					btn_class = "";
				}
				$('.econt_users').append(
					'<div style="padding:20px 15px;border-bottom:1px dashed #000; margin:0; background-color:'+color+' ">'+
					'<form action="'+baseUrl+'/bill/activate-econt-users" method="post" style="margin:0;">'+
					' <input type="hidden" name="_token" value="'+token+'"/>'+
					'<input type="hidden" name="invoice" value="'+info.invoice+'">'+
					'<input type="hidden" name="method" value="econt"/>'+
					'<input type="hidden" name="user_email" value="'+info.email+'"/>'+
					'<strong>Име:</strong> '+info.name+' | <strong>Адрес:</strong> '+info.address+' | <strong>Град:</strong> '+info.city+' |<strong> Телефон:</strong> '+info.phone+' | <strong>Имейл:</strong>'+info.email+' | <strong>Дата:</strong> '+info.time+' <strong>Цена:</strong> '+info.amount+'<button type="submit" style="float:right;" class="'+btn_class+'">Активирай</button></p>'+
					'</form>'+
					' <form action="'+baseUrl+'/bill/duplicate_proform" method="post">'+
					'<input type="hidden" name="_token" value="'+token+'"/>'+
					'<input type="hidden" name="invoice" value="'+info.invoice+'"/>'+
					'<button type="submit">Направи Дупликат</button>'+
					' </form> '+
					'</div>'
				);
			}
		});
});
/****** END ECONT SORTING BTNS **********/


/******* USER MESSAGES **************/

function showUserMsg(user){
	if($('#msgBody'+user).is(":visible")){
		$('#msgBody'+user).slideUp();
	}else{
		$('#msgBody'+user).slideDown();
		changeMessageStatus(user);
	}

	console.log(user);

}
function setPaymentCheck(){
	if($('#paymentChecked').is(":visible")){
		console.log("hello from payment check");
	}
}