var player;
$(document).ready(function(){
	var colors = ['#9C27B0','#3F51B5','#2196F3','#009688','#FF9800'];

	$('#loginAlert').click(function(){
		$('.warning-alert').fadeIn();
		$('.warningAlertText').html("Трябва да влезете в системата за да продължите");
		setTimeout(function(){
			$('.warning-alert').fadeOut();
		},5000);
	});

	$('#playVideo').click(function(){
		player.playVideo();
		$(this).addClass('inactiveBtn').removeClass('activeBtn');
		$('#pauseVideo').addClass('activeBtn').removeClass('inactiveBtn');
		$('.headerContent').hide();
		$('#stopVideo').removeClass('inactiveBtn').addClass('activeBtn');
		$('.playerControls').addClass('playerControlsAbsolute');
		$('#headerVideo').css({zIndex:1});
		$('.overlayHeaderHome').css({background:'rgba(255, 191, 0, 0.3)'})
	});

	$('#stopVideo').click(function(){
		player.stopVideo();	
		$('.headerContent').show();
		$('#playVideo').addClass('activeBtn').removeClass('inactiveBtn');
		$('.playerControls').removeClass('playerControlsAbsolute');
		$(this).addClass('inactiveBtn').removeClass('activeBtn');
		$('#pauseVideo').addClass('inactiveBtn').removeClass('activeBtn');
		$('#headerVideo').css({zIndex:0});
		$('.overlayHeaderHome').css({background:'rgba(255, 191, 0, 0.8)'})
	});

	$('#pauseVideo').click(function(){
		$('#playVideo').addClass('');
		player.pauseVideo();
		$(this).addClass('inactiveBtn').removeClass('activeBtn');
		$('#playVideo').addClass('activeBtn').removeClass('inactiveBtn');
	});
});
// 2. This code loads the IFrame Player API code asynchronously.
var tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

// 3. This function creates an <iframe> (and YouTube player)
//    after the API code downloads.

function onYouTubeIframeAPIReady() {
player = new YT.Player('headerVideo', {
  height: '390',
  width: '640',
  videoId: '4MOGO2CPqHM',
  events: {
    'onReady': onPlayerReady,
    'onStateChange': onPlayerStateChange
  },
  playerVars: { 
        'autoplay': 0,
        'showinfo': 0,
        'autohide': 1,
        'controls': 0, 
        'rel' : 0,
        'fs' : 0,
   }
});
}

// 4. The API will call this function when the video player is ready.
function onPlayerReady(event) {
	//event.target.playVideo();
	$('.home_header').addClass('new');
	//$('#headerVideo').css('padding-top', '150px');
}

// 5. The API calls this function when the player's state changes.
//    The function indicates that when playing a video (state=1),
//    the player should play for six seconds and then stop.
var done = false;
function onPlayerStateChange(event) {

}
function stopVideo() {
player.stopVideo();
}