var page = {};
var people_likes_post = {};
var people_shared_post = {};
var page_fan = 0;
function initFB(){
    $.ajaxSetup({ cache: true });
        $.getScript('//connect.facebook.net/en_US/sdk.js', function(){
          FB.init({
            appId: facebookKey,
            version: 'v2.8'
          });

            //getAccessToken();




        });
}
/*
appId: 1361437290599684*/
function initShare(){
    $.getScript('//connect.facebook.net/en_US/sdk.js', function(){
          FB.init({

            appId:1812609379024017,
            version: 'v2.8'
          });

            //getAccessToken();




        });
}
$(document).ready(function() {
    if(user_role == "admin"){
        $.ajaxSetup({ cache: true });
        $.getScript('//connect.facebook.net/en_US/sdk.js', function(){
          FB.init({
            appId: facebookKey,
            version: 'v2.8'
          });

            getAccessToken();




        });
   }
});

function getAccessToken(){
    FB.api(
  '/'+faceBookGroup,
  'GET',
  {"fields":"access_token"},
  function(response) {
     request(response.access_token);
     getUserSharedPostRequest();
  }
);
}
//
function request(token){
        FB.api(
        '/'+faceBookGroup,
        'GET',
        {
            "fields":"app_id,posts{likes,sharedposts{name}},username,fan_count",
            access_token: facebookKey+"|B0E-_PFau5LY1FPxn8DiTFo3Xr0"
        },
        function(response) {

            $('#fans').prop("value", response.fan_count);
            page_fan = response.fan_count;
            for(var i = 0; i<response.posts.data.length;i++){
                var post =  response.posts.data[i];
                if("likes" in post){
                   for(var people = 0;people<post.likes.data.length;people++){

                            people_likes_post[post.likes.data[people].id] = post.likes.data[people].name;
                   }
                }


            }
        }
      );
}

function getReport(){

   $('#data').prop("value",JSON.stringify(people_likes_post));
   $('#data-shared').prop("value",JSON.stringify(people_shared_post));
   $('#fb-report').submit();
}
function getUserReport(){
   $('#user-data').prop("value",JSON.stringify(people_likes_post));
   $('#user-data-shared').prop("value",JSON.stringify(people_shared_post));
   $('#user-fb-report').submit();
}

function getUserSharedPostRequest(){
    FB.api(
    '/'+faceBookGroup,
    'GET',
    {"fields":"posts{sharedposts{name,story,from}}",access_token: "1482870138442303|B0E-_PFau5LY1FPxn8DiTFo3Xr0"},
    function(response) {
        getUserSharedPost(response);
      }
  );

}

function getUserSharedPost(response){

    var data = response.posts.data;
    for(var i =0;i<data.length;i++){
        if("sharedposts" in data[i]){
            var sharedpost = data[i].sharedposts.data;
            for(var j=0;j<sharedpost.length;j++){
                people_shared_post[j] = {name: sharedpost[j].name, post:sharedpost[j].id, from:sharedpost[j].from.id};
            }
        }
    }
}

function putUserShareUrl(method){
     $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
        });
    $.post(baseUrl+"/admin/fb/url/share",{method:method})
    .done(function( data ) {
        console.log(data);
        if(data == "promotion"){
            $('#promotion').removeClass("inactive");
            $('#no-promotion').addClass("inactive");
        }

    });
}

function shareURL(callback){
    initShare();

     setTimeout(function(){
         FB.ui({
        method: 'share_open_graph',
        action_type: 'og.shares',
        action_properties: JSON.stringify({
            object : {
               'og:url': baseUrl,
               'og:title': "ProgramiramDotCom",
               'og:description': "Online курсове по програмиране за деца и юноши",

               'og:image':"https://programiram.com/images/levels/level1.png"
            }
        })
    },
    function(response) {
      if (typeof response != "undefined") {
        putUserShareUrl("url");
        successUrlShare();
        $('#promotion_overlay').remove();
        $('#promotion_modal').remove();
        $("#nextCourse").show();
      } else {
        //alert('Post was not published.');
      }
    })
    },500);
}
function shareBadge(badge){
    initFB();
     setTimeout(function(){
    FB.ui({
        method: 'share_open_graph',
        action_type: 'og.shares',
        action_properties: JSON.stringify({
            object : {
               'og:url': baseUrl,
               'og:title': "Спечелих значка от Programiram.com!",
               'og:description': "Тук се уча да програмирам докато играя. Пробвай и ти. Натисни Старт, за да започнеш!",

               'og:image':"https://single-laravelcode.rhcloud.com/production/images/badges/bg/"+badge
            }
        })
    },
    function(response) {
      if (typeof response != "undefined") {
        putUserShareUrl("url");
        successUrlShare();
        $('#promotion_overlay').remove();
        $('#promotion_modal').remove();
      } else {
        //alert('Post was not published.');
      }
    }
    )},500);
}

function shareCertificate(cert){
    initShare();
    var image_url = baseUrl+'/images/temp_cert_images/' + cert;
    console.log(image_url);
    setTimeout(function(){
        FB.ui({
        method: 'share_open_graph',
        action_type: 'og.shares',
        action_properties: JSON.stringify({
            object : {
               'og:url': baseUrl,
               'og:title': "Спечелих сертификат от Programiram.com!",
               'og:description': "Тук се уча да програмирам докато играя. Пробвай и ти. Натисни Старт, за да започнеш!",
               'og:image':image_url
            }
        })},
        function(response){
            if (typeof response != "undefined") {
                /*putUserShareUrl("url");
                successUrlShare();
                $('#promotion_overlay').remove();
                $('#promotion_modal').remove();*/
              } else {
                //alert('Post was not published.');
              }
        }
    )},500);
}
function shareFb(name,url,image){

    initFB();
    setTimeout(function(){
        FB.ui(
    {
      method: 'feed',
      name: name,
      link: url,
      picture: image/*baseUrl+'/images/social_images/programiram.png'*/,
      caption: url,
      description: 'Обучение по програмиране за деца и юноши. Игрови, Интерактивни и Адаптивни Online курсове'
    },
    function(response) {
     /* successPageShare();*/
    }
    );
    },500);

}

function pageShare(){
  initShare();
  setTimeout(function(){
FB.ui(
{
  method: 'feed',
  name: 'Programiram.com',
  link: 'https://www.facebook.com/'+faceBookGroup,
  picture: 'http://fbrell.com/f8.jpg',
  caption: 'Reference Documentation',
  description: 'Dialogs provide a simple, consistent interface for applications to interface with users.'
},
function(response) {
  if (typeof response != "undefined") {
    putUserShareUrl("page");
    successPageShare();
  } else {
    //alert('Post was not published.');
  }
}
);
  },500);
}

function successUrlShare(){
    $('.share-left').find('.checkmark-circle').css({display:'inline-block'});
    if($('.share-right').find('.checkmark-circle').is(":visible")){
        $('.discount_window').hide();
        $('.discountWindowOverlay').hide();
        continueToPayment();
    }
}
function successPageShare(){
    $('.share-right').find('.checkmark-circle').css({display:'inline-block'});
    if($('.share-left').find('.checkmark-circle').is(":visible")){
        $('.discount_window').hide();
        $('.discountWindowOverlay').hide();
        continueToPayment();
    }
}
function shareLinkFB(url){
  initShare();
  console.log(url);
  setTimeout(function(){
      FB.ui({
        method: 'share_open_graph',
        action_type: 'og.shares',
        action_properties: JSON.stringify({
            object : {
               'og:url': baseUrl+"/"+url,
               'og:title': "ProgramiramDotCom",
               'og:description': "Online курсове по програмиране за деца и юноши",

               'og:image':"https://programiram.com/images/levels/level1.png"
            }
        })
    },
    function(response) {
      if (typeof response != "undefined") {
        putUserShareUrl("url");
        successUrlShare();
        $('#promotion_overlay').remove();
        $('#promotion_modal').remove();
      } else {
        //alert('Post was not published.');
      }
    })
     },500);
}

function shareCourse(name,description,id,link){
  initShare();
  console.log(baseUrl+"/"+link);
  setTimeout(function(){
      FB.ui({
        method: 'share_open_graph',
        action_type: 'og.shares',
        action_properties: JSON.stringify({
            object : {
               'og:url': baseUrl+"/course/"+id,
               'og:title': name,
               'og:description': description+' ',
               'og:image':"http://www.programiram.com/"+link,


            },

        })
    },
    function(response) {

    })
     },500);
}

function shareCourseWithVideo(name,description,id,link){
  initShare();
  console.log(baseUrl+"/"+link);
  setTimeout(function(){
      FB.ui({
        method: 'share_open_graph',
        action_type: 'og.shares',
        action_properties: JSON.stringify({
            object : {
               'og:url': 'https://www.youtube.com/watch?v=Y1LxtBgNtOM',

               'og:title': 'title video',
               'og:description': description,
               'og:video':'https://www.youtube.com/embed/Y1LxtBgNtOM',

            }
        })
    },
    function(response) {

    })
     },500);
}
function shareRegCode(){
    initShare();
    setTimeout(function(){
        FB.ui({
        method: 'share_open_graph',
        message:"sadsadasd",
        action_type: 'og.shares',
        action_properties: JSON.stringify({
            object : {
               'og:url': 'https://programiram.com',
               'og:title': "",
               'og:description': "",
            }
        })},
        function(response){
            if (typeof response != "undefined") {
                /*putUserShareUrl("url");
                successUrlShare();
                $('#promotion_overlay').remove();
                $('#promotion_modal').remove();*/
              } else {
                //alert('Post was not published.');
              }
        }
    )},500);

}
